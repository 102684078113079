define('services/caccounts/pcrSetup/prepWellLite',[
    'entities/ns',
    'module',
    'entities/caccounts/pcrSetup/prepWellLite'
], function (
    app,
    module
) {
    'use strict';

    module.exports = {
        create: function (params) {
            return new app.PrepWellLite(params);
        }
    };
});
