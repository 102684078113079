
define('template!assayResultTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<span class="col-w-2 mdi mdi-delete delete-row js-icon clickable"></span>\n\n<input type="number" data-field-name="sequence" min="1" value="' +
((__t = ( sequence )) == null ? '' : __t) +
'"\n       class="col-w-3 js-info-input form-control inputBackground js-sequence"/>\n\n<div class="col-w-9 js-select-mbana-region"></div>\n<input class="col-w-1 js-info-input targetGridIcRowText inputBackground js-radio"\n       value="' +
((__t = ( secId )) == null ? '' : __t) +
'"\n       id="ic-' +
((__t = ( secId )) == null ? '' : __t) +
'"\n       name="ic-' +
((__t = ( code )) == null ? '' : __t) +
'"\n       data-field-name="ic" type="radio" ' +
((__t = ( ic ? 'checked="checked"' : '' )) == null ? '' : __t) +
' />\n\n<div class="col-w-45 js-algoResult css-algoRes-column-color"></div>\n<div class="col-w-1"></div>\n\n<div class="col-w-15 js-algoValidation"></div>\n\n<div class="col-w-1"></div>\n\n<div class="col-w-15 js-algoExport"></div>';

}
return __p
};});

