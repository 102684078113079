/**
 * Created by RKL on 21/08/2015.
 */
define('ordersController',[
    'module',
    'underscore',
    'orderCreateEditLayoutView'
], function (
    module,
    _,
    CreateEditLayoutView
) {
    'use strict';

    module.exports = {
        /**
         *
         * @param param
         * @param {Object} param.model
         * @param {Object} param.service
         * @param {Function} param.callBackOnClose
         * @throws {Error}
         */
        showDetails: function (param) {
            if (!param.model) {
                alert('Can\'t create a new model without entity, need to define empty model or existing model.');
                throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
            }
            param.model.fetch().done(_.bind(function () {
                var createEditView = new CreateEditLayoutView({
                    model: param.model
                });
                var OrderService = require('services/caccounts/orders');
                createEditView.show({
                    title: _.i18n(OrderService.getName() + '.new'),
                    className: 'baseTableEditAddPopup orderBaseTable',
                    service: param.service
                }, _.bind(function () {
                    if (param.callBackOnClose) {
                        param.callBackOnClose();
                    }
                }, param.callBackOnClose ? param.callBackOnClose.context : undefined));
            }, this));
        }
    };
});

