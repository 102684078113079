define('wellPcrFilter',[
    'module',
    'backbone',
    'underscore',
    'jquery',
    'dialogFormView',
    'autocompleteView',
    'services/caccounts/assay',
    'services/caccounts/assayVersion',
    'services/caccounts/assayconfiguration',
    'services/caccounts/mbanares',
    'commonSelect',
    'template!wellPcrFilter',
    'dynamicCreateEditDate',
    'jqgridView',
    'settings',
    'jqGridFormatter',
    'services/caccounts/pcrwells',
    'services/caccounts/pcrwellresults',
    'services/caccounts/displayModes',
    'pcrWellResultController',
    'moment',
    'wellUtils',
    'backboneRelational'
], function (
    module,
    Backbone,
    _,
    $,
    DialogFormView,
    AutocompleteView,
    AssayService,
    AssayVersionService,
    AssayConfigurationService,
    MbAnaResService,
    CommonSelect,
    Tpl,
    DynamicCreateEditDate,
    JqGridView,
    Settings,
    JqGridFormatter,
    PcrWellService,
    PcrWellResultService,
    DisplayModeService,
    PcrWellResultController,
    moment,
    WellUtils
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        regions: {
            assayRegion: '.js-assay-region',
            assayConfigurationRegion: '.js-assayConfiguration-region',
            gridRegion: '.js-grid-region'
        },

        className: 'wellPcrFilter',

        ui: {
            filter: '.js-filter-button',
            clear: '.js-clear-button',
            configuration: '.js-configuration'
        },

        events: {
            'click @ui.filter': 'onFilter',
            'click @ui.clear': 'onClear'
        },

        modelEvents: {
            'change:assay': 'onAssayChange'
        },

        attributes: {
            style: 'background-color: white; padding: 10px;'
        },

        initialize: function () {
            var M = Backbone.RelationalModel.extend({
                relations: [{
                    type: Backbone.HasOne,
                    key: 'assay',
                    relatedModel: 'Assay'
                }, {
                    type: Backbone.HasOne,
                    key: 'assayConfiguration',
                    relatedModel: 'AssayConfiguration'
                }]
            });
            this.model = new M();
            this.filtersName = 'wellPcrListFilters';
            var storedFilters = Settings.getFromMemory(this.filtersName);
            if (!storedFilters || !storedFilters.sidx) {
                storedFilters = {
                    sidx: 'creatDate',
                    sord: 'desc',
                    'type': 'U',
                    'fromDate': moment().subtract(3, 'months').format('DD/MM/YYYY'),
                    'toDate': moment().format('DD/MM/YYYY')
                };
                Settings.setToMemory(this.filtersName, storedFilters);
            }
            this.model.set('type', storedFilters.type);
        },

        onRender: function () {
            this.showChildView('assayRegion', new AutocompleteView(
                this._getAutocompleteOptionObject(AssayService.getAutocompleteParam({
                    modelProperty: 'assay'
                }))));
            this.ui.configuration.hide();
            if (this.model.get('assay')) {
                this.ui.configuration.show();
            }
        },

        onClick: function (obj) {
            var data = {};
            data[PcrWellService.getNamespace().model.prototype.idAttribute] = obj.rowId;
            var model = PcrWellService.getModel(data);
            PcrWellService.showDetails({model: model, service: PcrWellService});
        },

        gridOptions: function () {
            var result = this.model.get('assayConfiguration').get('displayConfigurations').chain()
                .filter(function (displayConfiguration) {
                    return displayConfiguration.get('type') === 'OVAR';
                })
                .map(function (displayConfiguration) {
                    return displayConfiguration.get('results').models;
                })
                .flatten()
                .first()
                .value();
            var target = result.get('assayConfigurationResult').get('target');
            var refMbAnaResGr = target.get('refMbAnaResGr');
            var mbAnaRes = ':' + _.i18n('common.any') + ';' + refMbAnaResGr.get('mbAnaRes').map(function (mbAnaRes) {
                return mbAnaRes.get('code') + ':' + mbAnaRes.get('code');
            }).join(';');

            var types = ['U', 'D', 'PC', 'NC', 'RC', 'OC', 'P', 'C'];

            var typeSearch = ':' + _.i18n('common.any') + ';' + types.map(function (type) {
                return type + ':' + type;
            }).join(';');

            var options = {
                colModel: [{
                    label: '',
                    name: 'secId',
                    key: true,
                    hidden: true
                }, {
                    label: _.i18n('run'),
                    name: 'run.code',
                    classes: '',
                    formatter: JqGridFormatter.defaultFormatter,
                    search: true,
                    sortable: true,
                    searchoptions: {sopt: ['cn'], clearSearch: false},
                    index: 'refPcrRun.code',
                    fixed: true,
                    width: 150
                }, {
                    label: _.i18n('well.pos'),
                    name: 'pos',
                    classes: 'well-list-pos-label displayPopover',
                    formatter: JqGridFormatter.defaultFormatter,
                    index: 'pos',
                    search: true,
                    searchoptions: {sopt: ['cn'], clearSearch: false},
                    sortable: true,
                    fixed: true,
                    width: 40
                }, {
                    label: _.i18n('well.sample'),
                    name: 'smpId.code',
                    classes: 'displayPopover displayPopover',
                    formatter: JqGridFormatter.defaultFormatter,
                    index: 'smpId.code',
                    searchoptions: {sopt: ['cn'], clearSearch: false},
                    search: true,
                    sortable: true,
                    fixed: true,
                    width: 150
                }, {
                    label: _.i18n('run.refCyclerPublic'),
                    name: 'run.refCyclerPublic.code',
                    classes: 'displayPopover',
                    formatter: JqGridFormatter.defaultFormatter,
                    search: true,
                    sortable: true,
                    index: 'refPcrRun.refCyclerPublic.code',
                    searchoptions: {sopt: ['cn'], clearSearch: false},
                    fixed: true,
                    width: 100
                }, {
                    label: _.i18n('run.refCycler'),
                    name: 'run.refCycler.code',
                    classes: 'displayPopover',
                    formatter: JqGridFormatter.defaultFormatter,
                    search: true,
                    sortable: true,
                    index: 'refPcrRun.refCycler.code',
                    searchoptions: {sopt: ['cn'], clearSearch: false},
                    fixed: true,
                    width: 100
                }, {
                    label: _.i18n('run.refLmbCycler'),
                    name: 'run.refLmbCycler.code',
                    classes: 'displayPopover',
                    formatter: JqGridFormatter.defaultFormatter,
                    search: true,
                    sortable: true,
                    index: 'refPcrRun.refLmbCycler.code',
                    searchoptions: {sopt: ['cn'], clearSearch: false},
                    fixed: true,
                    width: 100
                }, {
                    label: _.i18n('smpType'),
                    name: 'smpType',
                    classes: '',
                    formatter: this.smptypeFormater,
                    search: true,
                    index: 'smpType',
                    sortable: true,
                    stype: 'select',
                    searchrules: {select: true},
                    searchoptions: {sopt: ['eq'], value: typeSearch, clearSearch: false},
                    fixed: true,
                    width: 30
                }, {
                    label: _.i18n('sample.bioGroup'),
                    name: 'sample.bioGroup.code',
                    formatter: JqGridFormatter.codeNameFormatter,
                    search: true,
                    index: 'sample.bioGroup.code',
                    searchoptions: {sopt: ['cn'], clearSearch: false},
                    fixed: true,
                    width: 40
                }, {
                    label: _.i18n('test'),
                    name: 'test',
                    classes: '',
                    formatter: JqGridFormatter.booleanFormatterNoCheckBox,
                    search: true,
                    index: 'test',
                    sortable: false,
                    stype: 'select',
                    searchrules: {select: true},
                    searchoptions: {sopt: ['eq'], value: ':' + _.i18n('common.any') + ';true:' + _.i18n('tested') + ';false:' + _.i18n('untested'), clearSearch: false},
                    fixed: true,
                    width: 30
                }
                ],
                sortname: this.sortOn ? this.sortOn : 'creatDate',
                sortorder: this.sortOrder ? this.sortOrder : 'desc'
            };

            var results = this.model.get('assay').get('configurations').chain()
                .filter(_.bind(function (config) {
                    if (this.model.get('assay').get('configurations').length === 1) {
                        return true;
                    }
                    return config.get('cyclers').contains(this.model.get('cycler'));
                }, this))
                .map(function (config) {
                    return config.get('displayConfigurations').models;
                })
                .flatten()
                .filter(function (displayConfiguration) {
                    return displayConfiguration.get('type') === 'PLATE';
                })
                .map(function (displayConfiguration) {
                    return displayConfiguration.get('results').models;
                })
                .flatten()
                .value();
            var max = results.length;
            var width = 20;
            var location = this.displayMode.findParameterForLocation('Well');
            var paramSplitted = location.get('parameter') && location.get('parameter').param ? location.get('parameter').param.split(';') : [];
            var isHorizontal = location.get('parameter') && location.get('parameter').type === 'HISTOGRAM' && paramSplitted.length > 11 && paramSplitted[11] === 'HORIZONTAL';
            if (!location.get('parameter') || location.get('parameter').type === 'DOT') {
                width = 24;
            } else {
                if (isHorizontal) {
                    if (max < 8) {
                        width = 60;
                    } else {
                        width = 40;
                    }
                }
            }

            options.colModel.push({
                label: _.i18n('ovar'),
                name: 'ovar',
                labelClasses: 'rotated-text',
                classes: '',
                formatter: JqGridFormatter.ovarResultFormatter,
                search: true,
                sortable: false,
                index: 'target-' + target.id,
                stype: 'select',
                width: width + 10,
                fixed: true,
                searchrules: {select: true},
                searchoptions: {sopt: ['eq'], value: mbAnaRes, clearSearch: false}
            });

            this.groups = [];
            _.each(results, _.bind(function (result, j) {
                var target = result.get('assayConfigurationResult').get('target');
                var refMbAnaResGr = target.get('refMbAnaResGr');
                var mbAnaResValues = refMbAnaResGr.get('mbAnaRes').map(function (mbAnaRes) {
                    return mbAnaRes.get('code') + ':' + mbAnaRes.get('code');
                });

                // Prépare la valeur des options avec une valeur "any" au début
                var mbAnaRes = ':' + _.i18n('common.any') + ';' + mbAnaResValues.join(';');
                var displayGroup = {};
                if (!result.get('assayConfigurationResult').get('linkedAssayConfigurationResults').isEmpty()) {
                    displayGroup = {};
                    displayGroup.name = 'group_' + j + '_' + result.get('assayConfigurationResult').get('linkedAssayConfigurationResults').first().get('target').get('code');
                    displayGroup.size = result.get('assayConfigurationResult').get('linkedAssayConfigurationResults').length;
                }

                // Ajout d'une colonne avec des options spécifiques
                options.colModel.push({
                    label: target.get('name'),
                    name: target.get('code'),
                    labelClasses: 'rotated-text',
                    classes: '',
                    formatter: JqGridFormatter.defaultHtmlFormatter,
                    search: true,
                    sortable: !result.get('assayConfigurationResult').get('linkedAssayConfigurationResults').isEmpty(),
                    width: width,
                    fixed: true,
                    index: 'target-' + target.id,
                    stype: 'select',
                    searchrules: {select: true},
                    searchoptions: {
                        sopt: ['eq'],
                        value: mbAnaRes, // Valeur spécifique à cette colonne
                        clearSearch: false
                    },
                    displayGroup: displayGroup
                });
                if (!result.get('assayConfigurationResult').get('linkedAssayConfigurationResults').isEmpty()) {
                    var group = {parent: options.colModel[options.colModel.length - 1].name, results: []};
                    result.get('assayConfigurationResult').get('linkedAssayConfigurationResults').each(_.bind(function (res) {
                        var target = result.get('assayConfigurationResult').get('target');
                        var refMbAnaResGr = target.get('refMbAnaResGr');
                        var mbAnaResValues = refMbAnaResGr.get('mbAnaRes').map(function (mbAnaRes) {
                            return mbAnaRes.get('code') + ':' + mbAnaRes.get('code');
                        });

                        // Prépare la valeur des options avec une valeur "any" au début
                        var mbAnaRes = ':' + _.i18n('common.any') + ';' + mbAnaResValues.join(';');

                        options.colModel.push({
                            label: res.get('target').get('code'),
                            name: 'group_' + j + '_' + res.get('target').get('code'),
                            formatter: JqGridFormatter.defaultHtmlFormatter,
                            labelClasses: 'rotated-text extend',
                            search: true,
                            sortable: false,
                            width: width,
                            fixed: true,
                            frozen: true,
                            resizable: false,
                            index: 'target-' + res.get('target').id,
                            linkedTo: result.get('assayConfigurationResult').get('target').get('code'),
                            hidden: true,
                            stype: 'select',
                            searchrules: {select: true},
                            searchoptions: {
                                sopt: ['eq'],
                                value: mbAnaRes, // Valeur spécifique à cette colonne
                                clearSearch: false
                            }
                        });
                        group.results.push('group_' + j + '_' + res.get('target').get('code'));
                    }, this));
                    this.groups.push(group);
                }
            }, this));

            return options;
        },

        jqGridViewSetGroupHeaders: function () {
            if (_.isEmpty(this.groups)) {
                return {};
            }
            var ret = {groupHeaders: []};
            _.each(this.groups, function (group) {
                ret.groupHeaders.push({
                    startColumnName: group.results[0],
                    numberOfColumns: group.results.length,
                    titleText: group.parent,
                    className: 'extend'
                });
            });
            return ret;
        },

        onFilter: function () {
            if (!this.model.get('assay')) {
                alert('Please select an assay');
                return;
            }
            if (!this.model.get('assayConfiguration')) {
                alert('No configuration found');
                return;
            }
            var targets = this.model.get('assayConfiguration').get('results').pluck('target');
            var defers = [];
            defers.push(DisplayModeService.findByAssay(this.model.get('assay')));
            _.each(targets, function (target) {
                defers.push(target.fetch());
            });
            $.when.apply($, defers).done(_.bind(function (displayMode) {
                this.displayMode = displayMode;
                defers = [];
                _.chain(targets).map(function (target) {
                    return target.get('refMbAnaResGr');
                })
                    .uniq()
                    .each(function (refMbAnaResGr) {
                        defers.push(refMbAnaResGr.fetch());
                    });
                $.when.apply($, defers).done(_.bind(function () {
                    var storedFilters = Settings.getFromMemory(this.filtersName);
                    storedFilters.type = this.model.get('type');
                    storedFilters.fromDate = this.model.get('fromDate');
                    storedFilters.toDate = this.model.get('toDate');
                    storedFilters['refAssay.id'] = this.model.get('assay').id;
                    storedFilters['refCycler.id'] = this.model.get('cycler') && this.model.get('cycler').get ? this.model.get('cycler').id : null;
                    storedFilters['bioGroup.id'] = this.model.get('bioGroup') && this.model.get('bioGroup').get ? this.model.get('bioGroup').id : null;
                    storedFilters.type = this.model.get('type');
                    storedFilters['sample.biogroup.id'] = this.model.get('bioGroup') ? this.model.get('bioGroup').id : null;
                    storedFilters.details = true;
                    Settings.setToMemory(this.filtersName, storedFilters);

                    var data = {
                        url: PcrWellService.getCollectionUrl(),
                        rowCountUrl: PcrWellService.getCollectionCountUrl(),
                        getJqGridColumnConfig: PcrWellService.getJqGridColumnConfig()
                    };

                    this.jqGridView = new JqGridView({
                        data: data,
                        pager: true,
                        filtersName: this.filtersName,
                        gridOptions: _.bind(this.gridOptions, this),
                        paginationObject: this.paginationObject,
                        extend: [{'setGroupHeaders': _.bind(this.jqGridViewSetGroupHeaders, this)}]
                    });
                    this.listenTo(this.jqGridView, 'click', _.bind(this.onClick, this));
                    this.listenTo(this.jqGridView, 'loadComplete', _.bind(this.onLoadComplete, this));
                    this.showChildView('gridRegion', this.jqGridView);
                }, this));
            }, this));
        },

        onLoadComplete: function (gridView, datas) {
            $('.js-global-loader').show();
            var grid = gridView.getGrid();
            var collection = PcrWellService.getCollection(datas);
            var defers = [];
            defers.push(PcrWellResultService.findResultByWells(collection));
            var assayVersions = collection.chain().map(function (well) {
                return well.get('assayVersion');
            }).uniq().value();
            _.each(assayVersions, function (assayVersion) {
                defers.push(assayVersion.fetch());
                defers.push(DisplayModeService.findByAssayVersion(assayVersion));
            });
            $.when.apply($, defers)
                .done(_.bind(function () {
                    var ids = grid.jqGrid('getDataIDs');
                    ids.forEach(function (id) {
                        var well = collection.chain().filter(function (well) {
                            return well.id === parseInt(id, 10);
                        })
                            .first()
                            .value();
                        var resultOvars = well.getDisplay('OVAR');
                        var template = resultOvars.map(function (result) {
                            return PcrWellResultController.generateContent(result, 'Val');
                        });
                        // get colModel
                        var colModel = grid.jqGrid('getGridParam', 'colModel');
                        // find col with "targetId" equal to "target.id"
                        var colTarget = colModel.filter(function (col) {
                            return col.index && col.index.startsWith('target-') && parseInt(col.index.replace('target-', ''), 10) === resultOvars.first().get('refAssayResult').get('target').id;
                        });
                        _.each(colTarget, function (col) {
                            grid.jqGrid('setCell', id, col.name, template.join(', '));
                        });
                        // reject colTarget from colModel
                        colModel = _.reject(colModel, function (col) {
                            return col.index && col.index.startsWith('target-') && parseInt(col.index.replace('target-', ''), 10) === resultOvars.first().get('refAssayResult').get('target').id;
                        });
                        var results = well.getResults();
                        _.each(colModel, function (col) {
                            if (col.index && col.index.startsWith('target-')) {
                                // get the result from results
                                var targetId = parseInt(col.index.replace('target-', ''), 10);
                                var result = results.find(function (result) {
                                    return result.get('refAssayResult').get('target').id === targetId;
                                });
                                if (result) {
                                    grid.jqGrid('setCell', id, col.name, PcrWellResultController.generateContent(result, 'Val'));
                                }
                            }
                        });
                    });
                    this.setWellListPopover(gridView, datas);
                }, this))
                .always(function () {
                    $('.js-global-loader').hide();
                });
        },

        onClear: function () {
            this.model.reset();
            this.render();
        },

        onAssayChange: function () {
            var configuration = null;
            if (this.model.get('assay') && this.model.get('assay').get('configurations').length === 1) {
                configuration = this.model.get('assay').get('configurations').first();
            }
            this.model.set('assayConfiguration', configuration);
            this.showChildView('assayConfigurationRegion', new AutocompleteView(
                this._getAutocompleteOptionObject(AssayConfigurationService.getAutocompleteParam({
                    modelProperty: 'assayConfiguration',
                    values: {
                        assaySecId: this.model.get('assay') ? this.model.get('assay').id : null
                    }
                }))));
            if (this.model.get('assay') && !this.model.get('assayConfiguration')) {
                this.ui.configuration.show();
            }
        },

        setWellListPopover: function (jqGridView, datas) {
            var wellSecIds = _.pluck(datas, 'secId');
            var wells = PcrWellService.getCollection(wellSecIds);
            wells.fetch().done(_.bind(function () {
                _.each(datas, _.bind(function (data) {
                    var well = wells.get(data.secId);
                    if (!well) {
                        return;
                    }
                    var popovers = jqGridView.$el.find('tr#' + data.secId + ' .displayPopover');
                    if (popovers.length < 1) {
                        return;
                    }

                    var params = {
                        model: well
                    };

                    params.targets = popovers;

                    // if ((well.get('wellRes') && well.get('wellRes').get('result') && well.get('wellRes').get('result').get('code')) &&
                    //     (well.get('wellRes') && well.get('wellRes').get('quantificationFormatted'))) {
                    //     params.targets.push($('.ovrerallResultPopover-' + well.get('pos')));
                    // }
                    var placement = 'right';
                    var index = _.indexOf(datas, data);
                    if (index < 3) {
                        placement = 'bottom';
                    } else if (index > datas.length - 4) {
                        placement = 'top';
                    }
                    params.placement = placement;
                    WellUtils.showPcrWellPopover(params);
                }, this));
            }, this));
        }
    });
});
