define('entities/caccounts/mbanares',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.MbAnaRes = Backbone.RelationalModel.extend({
        service: 'services/caccounts/mbanares',
        fetch: function () {
            this.url = Settings.url('rest/v2/mbanares/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'resText': '',
            'color': null,
            'lisCode': '',
            'lisText': '',
            'lisVariant': '',
            'errorParameter': '',
            'refMbAnaResGr': null
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'refMbAnaResGr',
            relatedModel: 'MbAnaResGr',
            includeInJSON: ['secId', 'code', 'name']
        }]
    });

    app.MbAnaRes.role = RolesMixin.MBANARES;

    app.MbAnaResCollection = Backbone.Collection.extend({
        model: app.MbAnaRes
    });

});

