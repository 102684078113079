define('runView',[
    'module',
    'backbone.marionette',
    'backbone',
    'underscore',
    'template!runView',
    'runHeaderView',
    'runTabCollectionView',
    'runContentView',
    'emptyView'
], function (
    module,
    Marionette,
    Backbone,
    _,
    Tpl,
    HeaderView,
    TabCollectionView,
    ContentView,
    EmptyView
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,
        className: function () {
            return 'run ' + this.model.getType();
        },
        regions: {
            'header': '.header-region',
            'tabs': '.tabs-region',
            'content': '.content-region'
        },
        serializeData: function () {
            return {};
        },
        initialize: function () {
            this.tabs = new Backbone.Collection(this.options.configuration.tabs);
            this.tabs.listenTo(this.tabs, 'current', _.bind(this.onChangeCurrent, this));
        },
        onRender: function () {
            this.showChildView('header', new HeaderView({
                model: this.model,
                collection: this.tabs,
                configuration: this.options.configuration.header
            }));

            this.showChildView('tabs', new TabCollectionView({
                collection: this.tabs,
                model: this.model,
                configuration: this.options.configuration.tabs
            }));
            this.tabs.trigger('current', this.tabs.first());
        },
        onChangeCurrent: function (tab) {
            if (this.isDestroyed()) {
                return;
            }
            this.showChildView('content', new EmptyView());
            clearTimeout(this.timerRenderContent);
            this.timerRenderContent = setTimeout(_.bind(function () {
                this.showChildView('content', new ContentView({
                    model: this.model,
                    view: tab.get('content'),
                    configuration: tab.get('options'),
                    className: tab.get('className')
                }));
                this.tabs.trigger('refresh');
            }, this), 1);
        }
    });
});

