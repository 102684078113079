define('dynamicCreateEditCustomMbAnaClassifiedValue',[
    'module',
    'dialogFormView',
    'backbone',
    'jquery',
    'underscore',
    'template!dynamicCreateEditCustomMbAnaClassifiedValue',
    'autocompleteView'
], function (
    module,
    DialogFormView,
    Backbone,
    $,
    _,
    tpl,
    AutocompleteView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: tpl,

        ui: {
            input: '.js-info-input'
        },

        events: {
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange',
            'change .num1': 'onChangeNum1',
            'change .num2': 'onChangeNum2',
            'change .num3': 'onChangeNum3',
            'change .num4': 'onChangeNum4',
            'change .num5': 'onChangeNum5'
        },

        regions: {
            mbAnaRes1: '.js-mbAnaRes-region-1',
            mbAnaRes2: '.js-mbAnaRes-region-2',
            mbAnaRes3: '.js-mbAnaRes-region-3',
            mbAnaRes4: '.js-mbAnaRes-region-4',
            mbAnaRes5: '.js-mbAnaRes-region-5',
            mbAnaRes6: '.js-mbAnaRes-region-6'
        },

        modelEvents: {
            'change:num1': 'onChangeNum1',
            'change:num2': 'onChangeNum2',
            'change:num3': 'onChangeNum3',
            'change:num4': 'onChangeNum4',
            'change:num5': 'onChangeNum5'
        },

        className: 'rounded mbAnaClassifiedValue',

        initialize: function () {
            this.model = new Backbone.Model({
                num1: null,
                num2: null,
                num3: null,
                num4: null,
                num5: null,
                num6: null,
                mbAnaRes1: null,
                mbAnaRes2: null,
                mbAnaRes3: null,
                mbAnaRes4: null,
                mbAnaRes5: null,
                mbAnaRes6: null
            });
            this.options.model.get('classifiedValues').each(_.bind(function (classifiedValue, index) {
                this.model.set('num' + (index + 1), classifiedValue.get('untilValue'));
                this.model.set('mbAnaRes' + (index + 1), classifiedValue.get('mbAnaRes').get('code'));
            }, this));
        },

        onRender: function () {
            var MbAnaResService = require('services/caccounts/mbanares');
            this.showChildView('mbAnaRes1', new AutocompleteView(
                this._getAutocompleteOptionObject(MbAnaResService.getAutocompleteParam({
                    modelProperty: 'mbAnaRes1',
                    values: {
                        mbAnaResGrSecId: this.options.model.get('refMbAnaResGr') ? this.options.model.get('refMbAnaResGr').get('secId') : null
                    },
                    callBackOnChange: _.bind(this._onMbAnaResChange, this)
                }))
            ));
            this.showChildView('mbAnaRes2', new AutocompleteView(
                this._getAutocompleteOptionObject(MbAnaResService.getAutocompleteParam({
                    modelProperty: 'mbAnaRes2',
                    values: {
                        mbAnaResGrSecId: this.options.model.get('refMbAnaResGr') ? this.options.model.get('refMbAnaResGr').get('secId') : null
                    },
                    callBackOnChange: _.bind(this._onMbAnaResChange, this)
                }))
            ));
            this.showChildView('mbAnaRes3', new AutocompleteView(
                this._getAutocompleteOptionObject(MbAnaResService.getAutocompleteParam({
                    modelProperty: 'mbAnaRes3',
                    values: {
                        mbAnaResGrSecId: this.options.model.get('refMbAnaResGr') ? this.options.model.get('refMbAnaResGr').get('secId') : null
                    },
                    callBackOnChange: _.bind(this._onMbAnaResChange, this)
                }))
            ));
            this.showChildView('mbAnaRes4', new AutocompleteView(
                this._getAutocompleteOptionObject(MbAnaResService.getAutocompleteParam({
                    modelProperty: 'mbAnaRes4',
                    values: {
                    mbAnaResGrSecId: this.options.model.get('refMbAnaResGr') ? this.options.model.get('refMbAnaResGr').get('secId') : null
                    },
                    callBackOnChange: _.bind(this._onMbAnaResChange, this)
                }))
            ));
            this.showChildView('mbAnaRes5', new AutocompleteView(
                this._getAutocompleteOptionObject(MbAnaResService.getAutocompleteParam({
                    modelProperty: 'mbAnaRes5',
                    values: {
                        mbAnaResGrSecId: this.options.model.get('refMbAnaResGr') ? this.options.model.get('refMbAnaResGr').get('secId') : null
                    },
                    callBackOnChange: _.bind(this._onMbAnaResChange, this)
                }))
            ));
            this.showChildView('mbAnaRes6', new AutocompleteView(
                this._getAutocompleteOptionObject(MbAnaResService.getAutocompleteParam({
                    modelProperty: 'mbAnaRes6',
                    values: {
                        mbAnaResGrSecId: this.options.model.get('refMbAnaResGr') ? this.options.model.get('refMbAnaResGr').get('secId') : null
                    },
                    callBackOnChange: _.bind(this._onMbAnaResChange, this)
                }))
            ));
        },
        _onMbAnaResChange: function (fieldName, model) {
            // get index from fieldName
            var index = parseInt(fieldName.substring(fieldName.length - 1), 10);
            // found the model resultLimit in classifiedValues array in this.options.model, if not, create until it
            if (this.options.model.get('classifiedValues').length < index) {
                // create missing resultLimit until index
                for (var i = this.options.model.get('classifiedValues').length; i < index; i++) {
                    this.options.model.get('classifiedValues').push({
                        untilValue: null,
                        mbAnaRes: null
                    });
                }
            }
            // set the new value
            this.options.model.get('classifiedValues').at(index - 1).set('mbAnaRes', model ? model.toJSON() : null);
        },
        _onChangeNum: function (index, value) {
            if (this.options.model.get('classifiedValues').length < index) {
                // create missing resultLimit until index
                for (var i = this.options.model.get('classifiedValues').length; i < index; i++) {
                    this.options.model.get('classifiedValues').push({
                        untilValue: null,
                        mbAnaRes: null
                    });
                }
            }
            this.options.model.get('classifiedValues').at(index - 1).set('untilValue', parseFloat(value, 10));
        },
        onChangeNum1: function (e) {
            this._onChangeNum(1, e.currentTarget.value);
        },
        onChangeNum2: function (e) {
            this._onChangeNum(2, e.currentTarget.value);
        },
        onChangeNum3: function (e) {
            this._onChangeNum(3, e.currentTarget.value);
        },
        onChangeNum4: function (e) {
            this._onChangeNum(4, e.currentTarget.value);
        },
        onChangeNum5: function (e) {
            this._onChangeNum(5, e.currentTarget.value);
        }
    });
});
