define('commonGenericSelectItemView',[
    'module',
    'backbone.marionette',
    'underscore'
], function (
    module,
    Marionette,
    _
) {
    'use strict';

    module.exports = Marionette.View.extend({
        tagName: 'option',
        template: _.template('<%- name %>'),
        events: {},
        attributes: function () {
            return {
                value: this.model.get('value')
            };
        },
        serializeData: function () {
            return {name: this.model.get('name')};
        }
    });
});
