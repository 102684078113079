define('entities/caccounts/role',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/privilege'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.Role = Backbone.RelationalModel.extend({
        service: 'services/caccounts/roles',
        fetch: function () {
            this.url = Settings.url('rest/v2/roles/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            privileges: []
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'privileges',
            relatedModel: 'Privilege',
            collectionType: 'PrivilegeCollection'
        }],

        jsonObjectName: 'RoleJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'Role',

        importable: true
    });

    app.Role.role = RolesMixin.AUTHORITYGROUPROLE;

    app.RoleCollection = Backbone.Collection.extend({
        model: app.Role,
        comparator: 'code'
    });
});

