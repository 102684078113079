define('services/caccounts/mbanas',[
    'entities/ns',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/mbanas'
], function (
    app,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.MbAna,
                collection: app.MbAnaCollection
            };
        },
        getName: function () {
            return 'mbana';
        },
        getPrefixRequestHandler: function () {
            return 'mbana';
        },
        getUrl: function () {
            return 'rest/v2/mbanas/';
        },

        getDynamicJson: function () {
            return 'MbAnaJson';
        },

        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'mbana-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 50
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'mbana-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 15},
                width: 100
            }, {
                label: _.i18n('mbana.refMbAnaResGr'),
                name: 'refMbAnaResGr',
                classes: 'mbana-link',
                formatter: viewDynamic.mbAnaResGrFormatter,
                search: true,
                index: 'refMbAnaResGr.code',
                width: 50
            }, {
                label: _.i18n('mbana.lisCode'),
                name: 'lisCode',
                classes: 'mbana-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'lisCode',
                width: 50
            }, {
                label: _.i18n('mbana.classifiedValue'),
                name: 'classifiedValues',
                classes: 'mbana-link',
                formatter: _.bind(this.classifiedValuesFormatter, this),
                sortable: false,
                width: 100,
                search: false
            }, {
                label: _.i18n('common.assay'),
                name: 'assays',
                formatter: viewDynamic.codeNameListFormatter,
                sortable: false,
                search: false,
                index: 'assayCode',
                width: 100
            }, {
                label: _.i18n('common.lisana'),
                name: 'lisanas',
                formatter: viewDynamic.lisAnaFormatter,
                sortable: false,
                search: false,
                index: 'lisanaCode',
                width: 40
            }, {
                label: _.i18n('common.criterias'),
                name: 'criterias',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: true,
                index: 'criterias',
                width: 40
            }, {
                label: _.i18n('common.tags'),
                name: 'tags',
                formatter: viewDynamic.defaultListFormatter,
                sortable: false,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.comment'),
                name: 'comment',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'comment'
            });
            return columns;
        },
        getCollection: function (modelJson) {
            if (!modelJson) {
                modelJson = [];
            }
            return new app.MbAnaCollection(modelJson);
        },

        getFieldsToDisplay: function () {
            return {'fieldsWidth': 300, 'fields': ['name']};
        },

        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push({field: 'refMbAnaResGr', param: {display: {'class': 'col-xs-2 p-r-0'}}});
            custom.push({
                field: 'lisVariant',
                param: {type: 'TYPEPARAM', codeListCode: 'ALGO-LISVAR'}
            });
            custom.push({
                'field': 'assays',
                'param': {
                    'type': 'LIST2ENTITY'
                }
            });
            custom.push({
                'field': 'lisCode',
                'param': {
                    'type': 'STRING',
                    'display': {
                        'class': 'col-xs-2 p-r-0'
                    }
                }
            });
            custom.push({
                'field': 'assayGroups',
                'param': {
                    'type': 'LIST2ENTITY'
                }
            });
            custom.push({
                'field': 'lisanas',
                'param': {
                    'type': 'LIST2ENTITY'
                }
            });
            custom.push({
                'field': 'classifiedValues',
                'param': {
                    'type': 'MBANA_CLASSIFIEDVALUES'
                }
            });
            custom.push({
                'field': 'classifiedValueType',
                'param': {
                    'type': 'CODELIST',
                    'code': 'CLASSIFIED_VALUE_TYPE',
                    'display': {'class': 'col-xs-3'}
                }
            });
            return custom;
        },
        getListConfiguration: function () {
            var defer = $.Deferred();
            var configuration = Dynamic.getListConfiguration.call(this);
            configuration.done(function (resp) {
                var UserCodeListService = require('services/caccounts/userCodelist');
                resp.push({
                    fieldName: 'criterias',
                    values: UserCodeListService.findItemsByCode('FCRIT_TARGET'),
                    codeListCode: 'FCRIT_TARGET'
                });
                defer.resolve(resp);
            });
            return defer.promise();
        },
        classifiedValuesFormatter: function (cellvalue, options, rowObject) {
            if (!rowObject.classifiedValueType) {
                return '';
            }
            return rowObject.classifiedValueType + ' : ' + (_.map(rowObject.classifiedValues, function (classifiedValue) {
                return '[' + classifiedValue.mbAnaRes.code + (classifiedValue.untilValue ? ' - ' + classifiedValue.untilValue : '') + ']';
            }).join(', '));
        },
        getCreateEditFieldValidate: function () {
            var array = Dynamic.getCreateEditFieldValidate();
            array.push({name: 'refMbAnaResGr', type: 'required'});
            return array;
        }
    }, Dynamic);
});

