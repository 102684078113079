define('services/caccounts/setting',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/setting'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.Setting,
                collection: app.SettingCollection
            };
        },
        findByCode: function (code) {
            var url = Settings.url('rest/v2/settings/byCode/' + code),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (item) {
                    defer.resolve(item);
                }
            });

            return defer.promise();
        },
        getAllSettingFull: function (params) {
            if (!params) {
                params = {'rows': 100};
            }
            return this.findAll(params);
        },
        getName: function () {
            return 'setting';
        },
        getUrl: function () {
            return 'rest/v2/settings/';
        },
        getDynamicJson: function () {
            return 'SettingJson';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig.call(this);
            config.archivable = false;
            config.selectArchivable = false;
            config.duplicable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('setting.params'),
                name: 'params',
                classes: 'setting-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                searchoptions: {clearSearch: false, size: 50},
                index: 'params',
                width: 150
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'setting-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                searchoptions: {clearSearch: false, size: 50},
                index: 'description'
            }, {
                label: _.i18n('common.action'),
                name: 'pathSetting',
                classes: 'setting-link',
                formatter: _.bind(this.fileExplorerFormatter, this),
                sortable: false,
                search: false,
                width: 30
            });
            return columns;
        },

        fileExplorerFormatter: function (cellValue, options) {
            if (!cellValue) {
                return '';
            }
            return '<div><span data-navigate="admin/settings/' + options.rowId + '/files" class="mdi mdi-file-find clickable"></span></div>';
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['description']};
        },
        getCreateEditFieldValidate: function () {
            return [
                {name: 'code', type: 'required'}
            ];
        },
        getListConfiguration: function () {
            var defer = $.Deferred();
            var configuration = Dynamic.getListConfiguration.call(this);
            configuration.done(function (resp) {
                var UserCodeListService = require('services/caccounts/userCodelist');
                resp.push({fieldName: 'code', values: UserCodeListService.findItemsByCode('SETTINGS'), codeListCode: 'SETTINGS'});
                defer.resolve(resp);
            });
            return defer.promise();
        },
        getCreateEditFieldIgnore: function () {
            var ignored = Dynamic.getCreateEditFieldIgnore.call(this);
            ignored.push('pathSetting');
            return ignored;
        },
        getCreateEditFieldCustom: function (model) {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push(
                {
                    'field': 'code',
                    'param': {'type': 'CODELIST', 'code': 'SETTINGS', 'display': {'class': 'col-xs-3'}}
                },
                {
                    'field': 'params',
                    'param': {
                        'type': 'ARRAYLIST', 'arrayCode': function (model) {
                            return model.get('code');
                        }
                    },
                    'dependsOn': {
                        'model': model,
                        'field': 'code'
                    }
                },
                {
                    'field': 'defaultParams', 'param': {
                        'type': 'ARRAYLIST', 'arrayCode': model.get('code'),
                        display: {
                            'readOnly': true
                        }
                    }
                }
            );
            return custom;
        }
    }, Dynamic);
});

