define('runContentView',[
    'module',
    'backbone.marionette',
    'underscore'
], function (
    module,
    Marionette,
    _
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<div class="js-content-region" style="flex: 1; height: 100%;"></div>'),
        attributes: {
            'style': 'flex: 1; height: 100%;'
        },
        regions: {
            'content': '.js-content-region'
        },
        className: function () {
            return this.options.className || '';
        },
        onRender: function () {
            var view = new this.options.view({
                model: this.model,
                configuration: this.options.configuration
            });
            this.showChildView('content', view);
        }
    });
});
