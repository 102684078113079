/**
 * Created by OBL on 14/01/2016.
 */
define('runController',[
    'module',
    'underscore',
    'jquery',
    'runAssignAssayView',
    'runRemoveAssayView',
    'runReplaceAssayView',
    'runGenerateSampleIdView',
    'runClearSampleIdView',
    'runTestWellView',
    'runUntestWellView',
    'runClearWellView',
    'runPlateLayoutEditView',
    'runControlSampleView',
    'runPlateLayoutExportView',
    'runSampleListExportView',
    'runCopyFromRunView',
    'runView',
    'entities/ns',
    'runPrepControllerConfiguration',
    'runPcrControllerConfiguration',
    'app',
    'runCreateFromPlateLayoutView',
    'runCreateFromSampleListView',
    'runSampleListImportView',
    'runPlateLayoutImportView'
], function (
    module,
    _,
    $,
    RunAssignAssayView,
    RunRemoveAssayView,
    RunReplaceAssayView,
    RunGenerateSampleIdView,
    RunClearSampleIdView,
    RunTestWellView,
    RunUntestWellView,
    RunClearWellView,
    RunPlateLayoutEditView,
    RunControlSampleView,
    RunPlateLayoutExportView,
    RunSampleListExportView,
    RunCopyFromRunView,
    RunView,
    app,
    RunPrepControllerConfiguration,
    RunPcrControllerConfiguration,
    App,
    RunCreateFromPlateLayoutView,
    RunCreateFromSampleListView,
    RunSampleListImportView,
    RunPlateLayoutImportView
) {
    'use strict';

    module.exports = {
        assignAssay: function (model, start, to) {
            new RunAssignAssayView({
                model: model,
                start: start,
                to: to
            }).show({
                title: _.i18n('run.platelayout.assign.assay')
            });
        },
        removeAssay: function (model, start, to) {
            new RunRemoveAssayView({
                model: model,
                start: start,
                to: to
            }).show({
                title: _.i18n('run.platelayout.remove.assay')
            });
        },
        replaceAssay: function (model) {
            new RunReplaceAssayView({
                model: model
            }).show({
                title: _.i18n('run.platelayout.replace.assay')
            });
        },
        generateSampleId: function (model) {
            new RunGenerateSampleIdView({
                model: model
            }).show({
                title: _.i18n('run.generateSampleId.title')
            });
        },
        removeSampleId: function (model) {
            new RunClearSampleIdView({
                model: model
            }).show({
                title: _.i18n('run.platelayout.remove.smpId')
            });
        },
        defineTestWell: function (model) {
            new RunTestWellView({
                model: model
            }).show({
                title: _.i18n('run.platelayout.add.test')
            });
        },
        removeTestWell: function (model) {
            new RunUntestWellView({
                model: model
            }).show({
                title: _.i18n('run.platelayout.remove.test')
            });
        },
        clearWell: function (model) {
            new RunClearWellView({
                model: model
            }).show({
                title: _.i18n('run.platelayout.clear.wells')
            });
        },
        editPlateLayout: function (model) {
            var SettingService = require('services/caccounts/setting');
            SettingService.getAllSettingFull().done(function (settings) {
                model.get('wells').fetch({run: model}).done(function () {
                    var view = new RunPlateLayoutEditView({
                        model: model,
                        settings: settings,
                        mode: 'edit'
                    });
                    view.show({
                        title: _.i18n('run.platelayout.edit'),
                        className: 'plateLayout'
                    });
                });
            });
        },
        scanPlateLayout: function (model) {
            var SettingService = require('services/caccounts/setting');
            SettingService.getAllSettingFull().done(function (settings) {
                var view = new RunPlateLayoutEditView({
                    model: model,
                    settings: settings,
                    mode: 'scan'
                });
                view.show({
                    title: _.i18n('run.platelayout.edit'),
                    className: 'plateLayout'
                });
            });
        },
        controlSample: function (model) {
            new RunControlSampleView({
                model: model
            }).show({
                title: _.i18n('run.controlSample.title')
            });
        },
        importPlateLayout: function (model, settingName) {
            var SettingService = require('services/caccounts/setting');
            SettingService.findByCode(settingName).done(_.bind(function (setting) {
                var view = new RunPlateLayoutImportView({
                    model: model,
                    settingImport: setting,
                    callback: function () {
                        $('.js-global-loader').show();
                        model.fetch().always(function () {
                            $('.js-global-loader').hide();
                        });
                    }
                });
                view.show({
                    title: _.i18n('run.platelayout.import')
                });
            }, this));
        },
        importSampleList: function (model, settingName) {
            var SettingService = require('services/caccounts/setting');
            SettingService.findByCode(settingName).done(_.bind(function (setting) {
                var view = new RunSampleListImportView({
                    model: model,
                    settingImport: setting,
                    callback: function () {
                        $('.js-global-loader').show();
                        model.fetch().always(function () {
                            $('.js-global-loader').hide();
                        });
                    }
                });
                view.show({
                    title: _.i18n('run.importSampleList.title')
                });
            }, this));
        },
        exportPlateLayout: function (model) {
            var SettingService = require('services/caccounts/setting');
            SettingService.findByCode('EXPORT-PLATELAYOUT').done(_.bind(function (setting) {
                var view = new RunPlateLayoutExportView({
                    model: model,
                    settingImport: setting
                });
                view.show({
                    title: _.i18n('run.platelayout.export')
                });
            }, this));
        },
        exportSampleList: function (model) {
            var SettingService = require('services/caccounts/setting');
            SettingService.findByCode('EXPORT-SAMPLELIST').done(_.bind(function (setting) {
                var view = new RunSampleListExportView({
                    model: model,
                    settingImport: setting
                });
                view.show({
                    title: _.i18n('run.exportSampleList.title')
                });
            }, this));
        },
        copyFromRun: function (service, model) {
            new RunCopyFromRunView({
                model: model,
                service: service
            }).show({
                title: _.i18n('run.copyFromRun.title')
            });
        },
        createFromPlateLayoutFile: function (settingName, runService, url) {
            var SettingService = require('services/caccounts/setting');
            var defers = [];
            defers.push(SettingService.findByCode('LAB-SETTINGS'));
            defers.push(SettingService.findByCode(settingName));
            $.when.apply($, defers).done(_.bind(function (setting, settingImport) {
                new RunCreateFromPlateLayoutView({
                    service: runService,
                    importSetting: setting,
                    settingImport: settingImport,
                    callback: function () {
                        if (arguments.length === 1) {
                            App.navigate(url + '/' + arguments[0][0].secId, {trigger: true});
                        }
                    }
                }).show({
                    title: _.i18n('run.createFromPlateLayoutFile.title')
                });
            }, this));
        },
        createFromSampleListFile: function (settingName, runService, url) {
            var SettingService = require('services/caccounts/setting');
            var defers = [];
            defers.push(SettingService.findByCode('LAB-SETTINGS'));
            defers.push(SettingService.findByCode(settingName));
            $.when.apply($, defers).done(_.bind(function (setting, settingImport) {
                new RunCreateFromSampleListView({
                    service: runService,
                    settings: setting,
                    settingImport: settingImport,
                    callback: function () {
                        if (arguments.length === 1) {
                            App.navigate(url + '/' + arguments[0][0].secId, {trigger: true});
                        }
                    }
                }).show({
                    title: _.i18n('run.createFromSampleListFile.title')
                });
            }, this));
        },
        show: function (region, model) {
            var displayConfiguration;
            if (model instanceof app.PrepRun) {
                displayConfiguration = RunPrepControllerConfiguration.getConfiguration();
            } else if (model instanceof app.PcrRun) {
                displayConfiguration = RunPcrControllerConfiguration.getConfiguration();
            } else {
                throw new Error('Unknown run type');
            }
            region.show(new RunView({
                configuration: displayConfiguration,
                model: model,
                displayConfiguration: displayConfiguration
            }));
        }

    };
});
