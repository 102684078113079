define('entities/caccounts/samples',[
    'entities/ns',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/orders',
    'entities/caccounts/species',
    'entities/caccounts/biogroups',
    'entities/caccounts/lis',
    'entities/caccounts/lisana',
    'entities/caccounts/mbanas',
    'entities/caccounts/prepwells',
    'entities/caccounts/pcrwells',
    'entities/caccounts/assaygroup',
    'entities/caccounts/sampleTracing',
    'entities/caccounts/sample_routingactionroutinggroup',
    'entities/caccounts/sampleResults'
], function (
    app,
    Backbone,
    Settings,
    $,
    _,
    RolesMixin
) {
    'use strict';

    app.Sample = Backbone.RelationalModel.extend({
        service: 'services/caccounts/samples',
        fetch: function () {
            this.url = Settings.url('rest/v2/samples/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },

        defaults: {
            'creatDate': null,
            'modDate': null,
            'lisAnas': [],
            'assayGroups': [],
            'targets': [],
            'refLIS': null,
            'comment': '',
            'lisComment': '',
            'bioGroup': null,
            'specie': null,
            'prepRuns': [],
            'prepWells': [],
            'pcrRuns': [],
            'pcrWells': [],
            'refSampleClass': null,
            'order': null,
            'lastRoutingGroup': null,
            'routingGroupHistories': [],
            'results': [],
            'lisCode': ''
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'bioGroup',
            relatedModel: 'BioGroup'
        }, {
            type: Backbone.HasOne,
            key: 'specie',
            relatedModel: 'Specie'
        }, {
            type: Backbone.HasOne,
            key: 'refLIS',
            relatedModel: 'Lis'
        }, {
            type: Backbone.HasOne,
            key: 'refSampleClass',
            relatedModel: 'SampleClass'
        }, {
            type: Backbone.HasOne,
            key: 'lastRoutingGroup',
            relatedModel: 'SampleRoutingActionRoutingGroup'
        }, {
            type: Backbone.HasMany,
            key: 'routingGroupHistories',
            relatedModel: 'SampleRoutingActionRoutingGroup',
            collectionType: 'SampleRoutingActionRoutingGroupCollection'
        }, {
            type: Backbone.HasMany,
            key: 'assayGroups',
            relatedModel: 'AssayGroup',
            collectionType: 'AssayGroupCollection'
        }, {
            type: Backbone.HasMany,
            key: 'targets',
            relatedModel: 'MbAna',
            collectionType: 'MbAnaCollection'
        }, {
            type: Backbone.HasMany,
            key: 'lisAnas',
            relatedModel: 'LisAna',
            collectionType: 'LisAnaCollection'
        }, {
            type: Backbone.HasMany,
            key: 'prepRuns',
            relatedModel: 'PrepRun',
            collectionType: 'PrepRunCollection'
        }, {
            type: Backbone.HasMany,
            key: 'prepWells',
            relatedModel: 'PrepWell',
            collectionType: 'PrepWellCollection'
        }, {
            type: Backbone.HasMany,
            key: 'pcrRuns',
            relatedModel: 'PcrRun',
            collectionType: 'PcrRunCollection'
        }, {
            type: Backbone.HasMany,
            key: 'pcrWells',
            relatedModel: 'PcrWell',
            collectionType: 'PcrWellCollection'
        }, {
            type: Backbone.HasMany,
            key: 'tracing',
            relatedModel: 'SampleTracing',
            collectionType: 'SampleTracingCollection'
        }, {
            type: Backbone.HasMany,
            key: 'results',
            relatedModel: 'SampleResult',
            collectionType: 'SampleResultCollection',
            reverseRelation: {
                key: 'sampleParent',
                includeInJSON: false
            }
        }, {
            type: Backbone.HasOne,
            key: 'order',
            relatedModel: 'Order',
            includeInJSON: false,
            reverseRelation: {
                key: 'order',
                includeInJSON: false
            }
        }],


        postUrl: function () {
            return Settings.url('rest/v2/samples/');
        },

        save: function () {
            this.url = this.postUrl();

            return Backbone.Model.prototype.save.call(this);
        },
        getPDF: function () {
            return Settings.getDocUrl('SampleJson.pdf');
        },

        recalcSampleClass: function () {
            var defer = $.Deferred();
            var sampleClasseService = require('services/caccounts/sampleClass');
            sampleClasseService.findBySampleId(this.get('code')).done(_.bind(function (sampleClass) {
                if (sampleClass !== null) {
                    this.set('refSampleClass', sampleClass.toJSON());
                } else {
                    this.set('refSampleClass', null);
                }
                this.set('validSampleClass', true);
                defer.resolve();
            }, this))
                .fail(function (response) {
                    defer.reject(response);
                });
            return defer.promise();
        },
        refreshValidation: function () {
            var url = Settings.url('rest/v2/samples/' + this.get('secId') + '/refreshValidation'),
                defer = $.Deferred();
            $.ajax({
                method: 'GET',
                url: url,
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },
        findAnalyzes: function (codes) {
            var url = Settings.url('rest/v2/samples/' + this.get('secId') + '/findAnalyzes', {codes: codes}),
                defer = $.Deferred();
            $.ajax({
                method: 'GET',
                url: url,
                contentType: 'application/json',
                success: function (response) {
                    defer.resolve(response);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },
        updateComment: function (comment) {
            var url = Settings.url('rest/v2/samples/' + this.get('secId') + '/comment'),
                defer = $.Deferred();
            $.ajax({
                method: 'PUT',
                url: url,
                contentType: 'application/json',
                data: comment,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        }
    });

    app.Sample.role = RolesMixin.SAMPLES;

    app.SampleCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/samples/');
        },
        model: app.Sample
    });
});

