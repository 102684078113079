define('services/caccounts/standardCurve/standardCurves',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'jquery',
    'entities/caccounts/standardCurve/standardCurves'
], function (
    app,
    _,
    module,
    Dynamic,
    $
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.StandardCurve,
                collection: app.StandardCurveCollection
            };
        },
        getName: function () {
            return 'standardcurve';
        },
        getUrl: function () {
            return 'rest/v2/standardCurves/';
        },
        getDynamicJson: function () {
            return 'StandardCurveJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('standardCurve.bioGroups'),
                name: 'bioGroups',
                formatter: viewDynamic.codeNameListFormatter,
                sortable: false,
                search: true,
                index: 'bioGroup.code',
                width: 40
            }, {
                label: _.i18n('common.tag'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        },
        getCreateEditLine: function (model) {
            return [
                {
                    'field': 'dilution', 'param': {
                        'type': 'TEXT', 'display': {
                            'class': 'col-xs-1 t-a-r',
                            readOnly: model.get('results').any(function (result) {
                                return result.get('values').length > 0;
                            })
                        }
                    }
                }
            ];
        },
        showDetails: function (param) {
            require(['standardCurveController'], function (controller) {
                controller.showDetails(param);
            });
        },
        getList: function (fieldName) {
            var defer = $.Deferred();
            switch (fieldName) {
                case 'bioGroups':
                    var BioGroupService = require('services/caccounts/biogroups');
                    BioGroupService.findAll().done(function (items) {
                        defer.resolve({items: items});
                    });
                    break;
            }
            return defer.promise();
        }
    }, Dynamic);
});

