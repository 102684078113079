define('entities/caccounts/sampleTracing',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/userpids'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.SampleTracing = Backbone.RelationalModel.extend({
        fetch: function () {
            this.url = Settings.url('rest/v2/samples/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },

        defaults: {
            'creatDate': null,
            'userpid': {},
            type: undefined,
            comment: undefined
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'userpid',
            relatedModel: 'UserPid'
        }],


        getPDF: function () {
            return Settings.getDocUrl('SampleTracingJson.pdf');
        }
    });

    app.SampleTracing.role = RolesMixin.SAMPLES;

    app.SampleTracingCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/samples/');
        },
        comparator: function (model) {
            return -model.get('code');
        },
        model: app.SampleTracing
    });
});

