define('runPrepControllerConfiguration',[
        'module',
        'underscore',
        'runPrepHeaderDetailsView',
        'runPrepContentPlateView',
        'runPrepContentWellListView',
        'prepWellView',
        'runPrepHeaderPlateLeftView',
        'runPrepHeaderPlateRightView'
    ],
    function (
        module,
        _,
        RunPrepHeaderDetailsView,
        RunPrepContentPlateView,
        RunPrepContentWellListView,
        PrepWellView,
        RunPrepHeaderPlateLeftView,
        RunPrepHeaderPlateRightView
    ) {
        'use strict';

        module.exports = {
            getConfiguration: function () {
                return {
                    header: RunPrepHeaderDetailsView,
                    tabs: [{
                        item: {
                            title: _.i18n('runs.table.tabTitle'),
                            mdi: 'mdi-grid'
                        },
                        content: RunPrepContentPlateView,
                        className: 'plate',
                        type: ['PLATE', 'OVAR'],
                        options: {
                            childView: PrepWellView
                        },
                        header: {
                            left: RunPrepHeaderPlateLeftView,
                            right: RunPrepHeaderPlateRightView
                        }
                    }, {
                        item: {
                            title: _.i18n('runs.details.tabTitle'),
                            mdi: 'mdi-view-list'
                        },
                        content: RunPrepContentWellListView,
                        className: 'well-list',
                        type: 'WELL_LIST'
                    }]
                };
            }
        };
    }
);

