define('entities/caccounts/orders',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/species',
    'entities/caccounts/samples'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.Order = Backbone.RelationalModel.extend({
        service: 'services/caccounts/orders',
        fetch: function () {
            this.url = Settings.url('rest/v2/orders/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },

        defaults: {
            'creatDate': undefined,
            'expirationDate': undefined,
            'specie': null,
            'samples': []
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'specie',
            relatedModel: 'Specie'
        }, {
            type: Backbone.HasMany,
            key: 'samples',
            relatedModel: 'Sample',
            collectionType: 'SampleCollection'
        }],


        postUrl: function () {
            return Settings.url('rest/v2/orders/');
        },
        save: function () {
            this.url = this.postUrl();

            return Backbone.Model.prototype.save.call(this);
        },
        getPDF: function () {
            return Settings.getDocUrl('OrderJson.pdf');
        }
    });

    app.Order.role = RolesMixin.ORDERS;

    app.OrderCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/orders/');
        },
        model: app.Order
    });
});

