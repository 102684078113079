define('entities/caccounts/sample_routingactionroutinggroup',[
    'entities/ns',
    'backbone',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/routingaction_routinggroup',
    'entities/caccounts/samples',
    'entities/caccounts/userpids'
], function (
    app,
    Backbone,
    RolesMixin
) {
    'use strict';

    app.SampleRoutingActionRoutingGroup = Backbone.RelationalModel.extend({
        defaults: {
            sample: null,
            routingActionRoutingGroup: null,
            creatBy: null
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'creatBy',
            relatedModel: 'UserPid'
        }, {
            type: Backbone.HasOne,
            key: 'sample',
            relatedModel: 'Sample'
        }, {
            type: Backbone.HasOne,
            key: 'routingActionRoutingGroup',
            relatedModel: 'RoutingActionRoutingGroup'
        }]
    });

    app.SampleRoutingActionRoutingGroup.role = [RolesMixin.SAMPLES, RolesMixin.ROUTINGACTION];

    app.SampleRoutingActionRoutingGroupCollection = Backbone.Collection.extend({
        model: app.SampleRoutingActionRoutingGroup
    });
});

