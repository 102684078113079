define('services/global/routes',[
    'entities/ns',
    'jquery',
    'underscore',
    'settings',
    'module',
    'dynamic',
    'backboneRelational',
    'entities/global/routes'
], function (
    app,
    $,
    _,
    Settings,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.SystemRoute,
                collection: app.SystemRouteCollection
            };
        },
        getName: function () {
            return 'routes';
        },
        getUrl: function () {
            return 'rest/global/routes/';
        },
        getDynamicJson: function () {
            return 'RouteJson';
        },
        getTypeDynamicJson: function () {
            return 'GLOBAL';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig.call(this);
            config.exportable = false;
            config.archivable = false;
            config.selectArchivable = false;
            config.importable = false;
            config.attachmentable = false;
            config.selectable = false;
            config.deletable = false;
            config.duplicable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = [];
            columns.push({
                label: 'prot',
                name: 'protocol',
                index: 'protocol',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                width: 40,
                stype: 'select',
                searchoptions: {
                    value: ':;GET:GET;POST:POST;PUT:PUT;DELETE:DELETE'
                }
            }, {
                label: 'url',
                name: 'urlPattern',
                index: 'urlPattern',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                width: 200
            }, {
                label: 'roles',
                name: 'roles',
                index: 'role',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultListFormatter,
                search: true,
                width: 100
            }, {
                label: 'privileges',
                name: 'privileges',
                index: 'privilege',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultListFormatter,
                search: true,
                width: 100
            }, {
                label: 'bean',
                name: 'bean',
                index: 'bean',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                width: 250
            }, {
                label: 'method',
                name: 'method',
                index: 'method',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                width: 100
            }, {
                label: 'superUser',
                name: 'superUser',
                index: 'superUser',
                classes: 'dynamic-link',
                formatter: viewDynamic.simpleBooleanFormatter,
                search: true,
                width: 50,
                stype: 'select',
                searchoptions: {
                    sopt: ['eq'],
                    value: ':;true:' + _.i18n('common.checked') + ';false:' + _.i18n('common.unchecked')
                }
            });
            return columns;
        },
        getJqGridSidx: function () {
            return 'urlPattern';
        },
        getJqGridSord: function () {
            return 'asc';
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': []};
        },
        canAddOrEdit: function () {
            return Settings.get('currentUserModel') && Settings.get('currentUserModel').hasRole(this.getNamespace().model.role);
        }
    }, Dynamic);
});

