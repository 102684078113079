define('services/admin/detectionFormat',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'rolesMixin',
    'entities/admin/detectionFormat'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic,
    RolesMixin
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.DetectionFormat,
                collection: app.DetectionFormatCollection
            };
        },
        getName: function () {
            return 'detectionFormat';
        },
        getUrl: function () {
            return 'rest/public/detectionFormats/';
        },
        getDynamicJson: function () {
            return 'DetectionFormatJson';
        },
        getTypeDynamicJson: function () {
            return 'ADMIN';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig.call(this);
            config.exportable = false;
            config.archivable = false;
            config.selectArchivable = false;
            config.importable = false;
            config.duplicable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('detectionFormat.dye'),
                name: 'dyes',
                classes: 'dynamic-link',
                formatter: this.dyeFormatter,
                search: true,
                index: 'dye',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.comment'),
                name: 'comment',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'comment'
            });
            return columns;
        },
        canAddOrEdit: function () {
            return Settings.get('currentUserModel').hasRole(RolesMixin.SUPER_USER);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        },
        dyeFormatter: function (cellValue, options) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = [];
            }

            var values = [];
            _.each(cellValue, function (val) {
                values.push(val.name + '(' + val.channel + ')');
            });
            cellValue = values.join(', ');
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + cellValue + '</span>';
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push(
                {'field': 'cyclers', 'param': {'type': 'LIST2ENTITY'}}
            );
            return custom;
        }
    }, Dynamic);
});

