define('dynamic',[
    'module',
    'settings',
    'underscore',
    'bootbox',
    'jquery',
    'backbone',
    'privileges'
], function (
    module,
    Settings,
    _,
    BootBox,
    $,
    Backbone,
    Privileges
) {
    'use strict';

    module.exports = {
        getNamespace: function () {
            throw new Error('Name not implemented');
        },
        getName: function () {
            throw new Error('Name not implemented');
        },
        getUrl: function () {
            throw new Error('Url not implemented');
        },
        getCollectionUrl: function (params) {
            return Settings.url(this.getUrl() + 'find', params);
        },
        getCollectionCountUrl: function (params) {
            return this.getUrl() + 'find/rowCount?' + Settings.generateParamUrl(params);
        },
        getPrefixRequestHandler: function () {
            throw new Error('PrefixRequestHandler not implemented');
        },
        getDynamicJson: function () {
            throw new Error('DynamicJson not implemented');
        },
        getTypeDynamicJson: function () {
            return 'CACCOUNT';
        },
        getJqGridFilterName: function () {
            return this.getName() + 'FilterName';
        },
        getJqGridColumnConfig: function () {
            return {
                selectable: true,
                exportable: true,
                deletable: true,
                attachmentable: true,
                archivable: true,
                importable: true,
                creatable: true,
                selectArchivable: true,
                duplicable: true
            };
        },
        getJqGridColumn: function (viewDynamic, config) {
            if (!config) {
                config = this.getJqGridColumnConfig();
            }
            var columns = [];
            columns.push({
                label: '',
                name: 'secId',
                key: true,
                hidden: true
            });
            if (!config || config.exportable === undefined || config.exportable) {
                columns.push({
                    label: ' ',
                    name: 'export',
                    sortable: false,
                    search: false,
                    classes: 'export-link-action ignore-row-click',
                    formatter: viewDynamic.exportActionIconFormatter,
                    fixed: true,
                    width: 25
                });
            }
            if (this.canAddOrEdit() && (!config || config.deletable === undefined || config.deletable)) {
                columns.push({
                    label: ' ',
                    name: 'delete',
                    sortable: false,
                    search: false,
                    classes: 'delete-link-action ignore-row-click',
                    formatter: viewDynamic.deleteActionIconFormatter,
                    fixed: true,
                    width: 25
                });
            }
            if (this.isArchivable() && (!config || config.archivable === undefined || config.archivable)) {
                columns.push({
                    label: ' ',
                    name: 'archiveRestore',
                    sortable: false,
                    search: false,
                    classes: 'archiveRestore-link-action ignore-row-click',
                    formatter: viewDynamic.archiveRestoreActionIconFormatter,
                    fixed: true,
                    width: 25
                });
            }
            if (!config || config.attachmentable === undefined || config.attachmentable) {
                columns.push({
                    label: ' ',
                    name: 'attachment',
                    sortable: false,
                    search: false,
                    classes: 'attachment-link-action ignore-row-click',
                    formatter: viewDynamic.attachmentActionIconFormatter,
                    fixed: true,
                    width: 25
                });
            }
            if (this.isArchivable() && (!config || config.selectArchivable === undefined || config.selectArchivable)) {
                columns.push({
                    label: '',
                    name: 'displayArchived',
                    index: 'displayArchived',
                    search: true,
                    hidden: true
                });
            }
            if (this.canAddOrEdit() && (!config || config.duplicable === undefined || config.duplicable)) {
                columns.push({
                    label: ' ',
                    name: 'duplicate',
                    sortable: false,
                    search: false,
                    classes: 'duplicate-link-action ignore-row-click',
                    formatter: viewDynamic.duplicateActionIconFormatter,
                    fixed: true,
                    width: 25
                });
            }
            columns.push({
                label: ' ',
                name: 'entityError',
                sortable: false,
                search: false,
                classes: 'alert-link-action ignore-row-click',
                formatter: viewDynamic.errorIconFormatter,
                fixed: true,
                width: 25
            });
            return columns;
        },
        getJqGridSidx: function () {
            return 'code';
        },
        getJqGridSord: function () {
            return 'asc';
        },
        getCreateEditFieldCustom: function () {
            return [
                {field: 'tags', param: {type: 'LISTTOLIST'}},
                {field: 'criterias', param: {type: 'LISTTOLIST'}}
            ];
        },
        getCreateEditLine: function () {
            throw new Error('getCreateEditLine not implemented for ' + this.getName());
        },
        canAddNewModel: function () {
            return true;
        },
        getConfigCreateEditLine: function () {
            return {
                editButton: true
            };
        },
        getCreateEditFieldIgnore: function () {
            return ['creatDate', 'modDate', 'archived', 'archivedDate', 'entityError', 'creatBy', 'modBy', 'jsonType'];
        },
        getCreateEditFieldValidate: function () {
            return [
                {name: 'code', type: 'required'},
                {name: 'name', type: 'required'}
            ];
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 450, 'fields': ['code', 'name']};
        },
        getAutocompleteParam: function (config, context) {
            if (!context) {
                context = this;
            }
            var configuration = {
                modelProperty: config.modelProperty,
                valueProperty: config.valueProperty ? config.valueProperty : 'code',
                queryProperty: config.queryProperty ? config.queryProperty : 'code',
                urlPath: (config.url ? config.url : context.getCollectionUrl(config.paramUrl)),
                placeHolderLabel: config.placeHolderLabel ? config.placeHolderLabel : 'common.empty.placeholder',
                valueKey: config.valueKey ? config.valueKey : 'code',
                callback: config.callBackOnChange,
                childTemplate: config.childTemplate,
                fieldsToDisplay: config.fieldsToDisplay ? config.fieldsToDisplay : context.getFieldsToDisplay(),
                acceptDirectInput: config.acceptDirectInput,
                dropdownWidth: config.dropdownWidth,
                data: config.data,
                value: config.value,
                service: context,
                readOnly: config.readOnly,
                keys: config.keys,
                values: config.values
            };
            configuration.canAddOrEdit = config.canAddOrEdit === undefined ? context.canAddOrEdit() : config.canAddOrEdit;
            configuration.callbackAdd = config.canAddOrEdit ?
                (config.callBackAdd ?
                    config.callBackAdd : configuration.callback ?
                        _.bind(configuration.callback, context, config.modelProperty) : undefined) : undefined;
            configuration.callbackEdit = config.canAddOrEdit ?
                (config.callBackEdit ?
                    config.callBackEdit : configuration.callback ?
                        _.bind(configuration.callback, context, config.modelProperty) : undefined) : undefined;
            return configuration;
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return this.getNamespace().model.findOrCreate(modelJson);
        },

        getCollection: function (modelJson) {
            if (!modelJson) {
                modelJson = [];
            }

            var namespace = this.getNamespace();
            var CollectionConstructor = namespace.collection; // Obtenez le constructeur de la collection
            var collection = new CollectionConstructor(modelJson); // Créez une nouvelle instance de la collection
            collection.model = namespace.model; // Assignez le modèle à la collection
            return collection;

        },

        onEdit: function (callBack, secId) {
            var model = this.getModel({secId: secId});
            model.fetch().done(_.bind(function () {
                this.showDetails({model: model, callBackRefresh: callBack});
            }, this));
        },

        onAdd: function (callBack) {
            var model = this.getModel(null);
            this.showDetails({model: model, callBackRefresh: callBack});
        },

        importEntity: function (fileInfo, type) {
            var url = Settings.url(this.getUrl() + 'import', {type: type}),
                defer = $.Deferred();

            $('.js-global-loader').show();
            var formdata = new FormData();
            formdata.append('file', fileInfo);

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: function (response) {
                    defer.resolve(response);
                },
                complete: function (response) {
                    $('.js-global-loader').hide();
                    response = response.responseJSON;
                    if (response.error) {
                        BootBox.alert({
                            title: 'Error in importing data',
                            message: 'Message : ' + response.errorMessage
                        });
                    } else {
                        if (Array.isArray(response.imported)) {
                            BootBox.alert({
                                title: 'Import',
                                message: 'Count of entities imported (created or updated) : ' + response.imported.length +
                                    (response.invalidLines.length > 0 ? ('<br/><b>Invalid lines</b> : <span>' + response.invalidLines.join(', ')) + '</span>' : '')
                            });
                        } else {
                            BootBox.alert({
                                title: 'Import',
                                message: 'Entity updated'
                            });
                        }
                    }
                }
            });

            return defer.promise();
        },
        importIntoEntity: function (model, fileInfo) {
            var url = Settings.url(this.getUrl() + model.get('secId') + '/import'),
                defer = $.Deferred();

            var formdata = new FormData();
            formdata.append('file', fileInfo);

            $('.js-global-loader').show();
            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: function () {
                    model.fetch().done(function () {
                        defer.resolve();
                    });
                },
                complete: function () {
                    $('.js-global-loader').hide();
                }
            });

            return defer.promise();
        },
        exportEntity: function (secId) {
            var loader = this._needLoader();
            var url = Settings.url(this.getUrl() + secId + '/export'),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (data) {
                    defer.resolve(data);
                },
                error: function (err) {
                    defer.reject(err);
                },
                complete: function () {
                    if (loader) {
                        loader.hide();
                    }
                }
            });

            return defer.promise();
        },
        duplicateEntity: function (secId) {
            var loader = this._needLoader();
            var url = Settings.url(this.getUrl() + secId + '/duplicate'),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (data) {
                    defer.resolve(data);
                },
                error: function (err) {
                    defer.reject(err);
                },
                complete: function () {
                    if (loader) {
                        loader.hide();
                    }
                }
            });

            return defer.promise();
        },
        deleteEntity: function (secId) {
            var loader = this._needLoader();
            var url = Settings.url(this.getUrl() + secId),
                defer = $.Deferred();

            $.ajax({
                type: 'DELETE',
                url: url,
                success: function (data) {
                    defer.resolve(data);
                },
                error: _.bind(function (data) {
                    var model = this.getModel({secId: secId});
                    BootBox.alert(_.i18n('entity.delete.error', null, {
                        name: this.getName() + ' (' + (model.get('code') ? model.get('code') : secId) + ')',
                        message: data
                    }));
                    defer.reject(data);
                }, this),
                complete: function () {
                    if (loader) {
                        loader.hide();
                    }
                }
            });

            return defer.promise();
        },
        archiveEntity: function (secId) {
            var loader = this._needLoader();
            var url = Settings.url(this.getUrl() + secId + '/archive'),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (err) {
                    defer.reject(err);
                },
                complete: function () {
                    if (loader) {
                        loader.hide();
                    }
                }
            });
            return defer.promise();
        },
        unarchiveEntity: function (secId) {
            var loader = this._needLoader();
            var url = Settings.url(this.getUrl() + secId + '/unarchive'),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (err) {
                    defer.reject(err);
                },
                complete: function () {
                    if (loader) {
                        loader.hide();
                    }
                }
            });
            return defer.promise();
        },
        exportEntities: function (secIds, type) {
            var loader = this._needLoader();
            var params = {
                secIds: secIds,
                type: type
            };

            var url = Settings.url(this.getUrl() + 'export', params),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (err) {
                    defer.reject(err);
                },
                complete: function () {
                    if (loader) {
                        loader.hide();
                    }
                }
            });
            return defer.promise();
        },
        archiveEntities: function (secIds) {
            var loader = this._needLoader();
            var params = {
                secIds: secIds
            };

            var url = Settings.url(this.getUrl() + 'archive', params),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (err) {
                    defer.reject(err);
                },
                complete: function () {
                    if (loader) {
                        loader.hide();
                    }
                }
            });
            return defer.promise();
        },
        unarchiveEntities: function (secIds) {
            var loader = this._needLoader();
            var params = {
                secIds: secIds
            };

            var url = Settings.url(this.getUrl() + 'unarchive', params),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (err) {
                    defer.reject(err);
                },
                complete: function () {
                    if (loader) {
                        loader.hide();
                    }
                }
            });
            return defer.promise();
        },
        deleteEntities: function (secIds) {
            var loader = this._needLoader();
            var url = Settings.url(this.getUrl() + 'delete'),
                defer = $.Deferred();

            $.ajax({
                type: 'POST',
                url: url,
                data: JSON.stringify(secIds),
                contentType: 'application/json',
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (err) {
                    defer.reject(err);
                },
                complete: function () {
                    if (loader) {
                        loader.hide();
                    }
                }
            });
            return defer.promise();
        },
        getDynamicConfiguration: function () {
            var defer = $.Deferred();
            var url = Settings.url('rest/public/dynamic/find', {
                'classNameJson': this.getDynamicJson(),
                'context': this.getTypeDynamicJson()
            });

            $.ajax({
                type: 'GET',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },
        parameterForLink: function (model, service) {
            return {
                secId: model.get('secId'),
                code: model.get('code'),
                name: model.get('name'),
                service: service
            };
        },
        /**
         *
         * @param param - The parameter object.
         * @param {Object} param.model - The model whose details are to be shown.
         * @param {Object} param.controller - The controller object.
         * @param {Object} param.service - The service object.
         */
        showDetails: function (param) {
            if (!param) {
                throw new Error('param is required');
            }
            if (!param.service) {
                param.service = this;
            }
            if (!param.controller) {
                require(['dynamicController'], _.bind(function (DynamicController) {
                    param.controller = DynamicController;
                    this._showDetails(param);
                }, this));
            } else {
                this._showDetails(param);
            }
        },
        /**
         * Show the details of the model.
         * @param param - The parameter object.
         * @param {Object} param.controller - The controller object.
         * @param {Object} param.service - The service object.
         * @private
         */
        _showDetails: function (param) {
            var needRoles = param.service.hasRole();
            if (needRoles) {
                require(['errorController'], _.bind(function (ErrorController) {
                    ErrorController.showMissingRoles(needRoles);
                }, this));
                return;
            }

            param.controller.showDetails(param);
        },
        getListConfiguration: function () {
            var defer = $.Deferred();
            var UserCodeListService = require('services/caccounts/userCodelist');
            defer.resolve([{
                fieldName: 'tags',
                values: UserCodeListService.findItemsByCode('TAG_BASETABLE'),
                codeListCode: 'TAG_BASETABLE'
            }]);
            return defer.promise();
        },
        getList: function (fieldName, model) {
            var defer = $.Deferred();
            this.getListConfiguration(model).done(function (configurations) {
                var configuration = _.findWhere(configurations, {fieldName: fieldName});
                if (configuration) {
                    configuration.values.done(function (items) {
                        if (items instanceof Backbone.Collection) {
                            items = items.models;
                        }
                        defer.resolve({codeListCode: configuration.codeListCode, items: items});
                    });
                } else {
                    defer.reject('No found for ' + fieldName);
                }
            });
            return defer.promise();
        },
        getJqGridEvents: function () {
            return [];
        },
        findAll: function (params) {
            var defer = $.Deferred();
            var url = Settings.url(this.getCollectionUrl());

            // add missing properties to param
            params = params || {};
            if (!params.sord) {
                params.sord = this.getJqGridSord();
            }
            if (!params.sidx) {
                params.sidx = this.getJqGridSidx();
            }
            if (!params.rows) {
                params.rows = 9999;
            }

            $.ajax({
                type: 'POST',
                url: url,
                data: $.param(params),
                contentType: 'application/x-www-form-urlencoded',
                success: _.bind(function (result) {
                    if (this.getCollection) {
                        defer.resolve(this.getCollection(result));
                    } else {
                        defer.resolve(result);
                    }
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },
        equals: function (model, model2) {
            var same = _.any(Backbone.Relational.store._modelScopes, function (modelScope) {
                return _.any(modelScope, function (key) {
                    return model instanceof key && model2 instanceof key;
                });
            });
            if (!same) {
                return false;
            }
            return model.get(model.idAttribute) === model2.get(model2.idAttribute);
        },
        hasRole: function () {
            if (Settings.get('currentUserModel') && Settings.get('currentUserModel').hasRole(this.getNamespace().model.role)) {
                return null;
            }
            return this.getNamespace().model.role;
        },
        canRead: function () {
            return Settings.get('currentUserModel') && Settings.get('currentUserModel').hasType(this.getNamespace().model.role, Privileges.READ);
        },

        canAddOrEdit: function () {
            return Settings.get('currentUserModel') && Settings.get('currentUserModel').hasType(this.getNamespace().model.role, Privileges.WRITE);
        },
        isArchivable: function () {
            return Settings.get('currentUserModel') && Settings.get('currentUserModel').hasType(this.getNamespace().model.role, Privileges.ARCHIVE);
        },
        getById: function (secId) {
            var number = Number(secId);
            if (!_.isNaN(number)) {
                secId = number;
            }
            return this.getNamespace().model.find(secId);
        },
        _needLoader: function () {
            var loader = $('.js-global-loader');
            if (loader.css('display') === 'none') {
                loader.show();
                return loader;
            }
            return false;
        }
    };
});

