define('entities/caccounts/assayconfiguration',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/assayConfigurationResult',
    'entities/caccounts/assayConfigurationDisplayConfiguration',
    'entities/caccounts/colorCompensations',
    'entities/caccounts/assayConfigurationResultOrdered'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.AssayConfiguration = Backbone.RelationalModel.extend({
        service: 'services/caccounts/assayconfiguration',
        fetch: function () {
            this.url = Settings.url('rest/v2/assayConfigurations/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': null,
            'name': null,
            'results': [],
            'cyclers': [],
            'refColorCompensation': null,
            'refAssay': null,
            displayOvar: [],
            displayPlate: [],
            displayWell: [],
            displayValidation: [],
            displayContamination: []
        },
        idAttribute: 'secId',

        relations: [{
            type: Backbone.HasMany,
            key: 'results',
            relatedModel: 'AssayConfigurationResult',
            collectionType: 'AssayConfigurationResultCollection'
        }, {
            type: Backbone.HasMany,
            key: 'cyclers',
            relatedModel: 'Cycler',
            collectionType: 'CyclerCollection'
        }, {
            type: Backbone.HasOne,
            key: 'refColorCompensation',
            relatedModel: 'ColorCompensation'
        }, {
            type: Backbone.HasOne,
            key: 'refAssay',
            relatedModel: 'Assay',
            includeInJSON: false
        }, {
            type: Backbone.HasMany,
            key: 'displayConfigurations',
            relatedModel: 'AssayConfigurationDisplayConfiguration',
            collectionType: 'AssayConfigurationDisplayConfigurationCollection'
        }],

        save: function () {
            throw new Error('Can\'t save this entity');
        },

        jsonObjectName: 'AssayConfigurationJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'AssayConfiguration',

        getDisplay: function (type) {
            var model = this.get('displayConfigurations').findWhere({type: type});
            if (!model) {
                return new Backbone.Collection();
            }
            return model.get('results');
        }

    });

    app.AssayConfiguration.role = RolesMixin.ASSAY;

    app.AssayConfigurationCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/assayConfigurations/');
        },
        model: app.AssayConfiguration,
        findByCycler: function (cycler) {
            return this.filter(function (assayConfiguration) {
                return assayConfiguration.get('cyclers').findWhere({id: cycler.id});
            });
        }
    });
});

