define('runContentGridCollectionView',[
    'module',
    'backbone',
    'underscore',
    'backbone.marionette',
    'runContentGridItemView',
    'runContentGridItemHeaderView',
    'colorUtils',
    'entities/ns'
], function (
    module,
    Backbone,
    _,
    Marionette,
    ItemView,
    ItemHeaderView,
    ColorUtils,
    app
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        className: 'grid-wells',
        attributes: function () {
            var column = Number.parseInt(this.model.get('plateSize').substring(1));
            var line = this.model.get('plateSize').substring(0, 1).charCodeAt(0) - 64;
            return {
                'style': 'flex-grow: 1;display: grid; grid-template-columns: 30px repeat(' + column + ', calc((100% - 30px)/ ' + column + ')); grid-template-rows: 30px repeat(' + line + ', 80px);'
            };
        },
        childView: function (model) {
            if (model.get('type') === 'header') {
                return ItemHeaderView;
            } else {
                return ItemView;
            }
        },
        initialize: function () {
            this.collection = new Backbone.Collection();
            this.collection.comparator = 'pos';
            this.options.collection.each(_.bind(function (model) {
                this.collection.add(model);
            }, this));
            var plateSize = this.model.get('plateSize');
            this.column = Number.parseInt(plateSize.substring(1));
            var row = plateSize.substring(0, 1).charCodeAt(0);
            this.collection.push(new Backbone.Model({pos: '000', type: 'header', name: ''}));
            for (var i = 1; i <= this.column; i++) {
                this.collection.push(new Backbone.Model({
                    pos: '0' + String(i).padStart(2, '0'),
                    type: 'header',
                    name: String(i).padStart(2, '0')
                }));
            }
            for (var j = 65; j <= row; j++) {
                this.collection.push(new Backbone.Model({
                    pos: String.fromCharCode(j) + '00',
                    type: 'header',
                    name: String.fromCharCode(j)
                }));
            }
            this.collection.sort();

            var colorsAssay = ['20', '60', '#fff302', '#d06767', '#00FF00', '#285085', '#0012ff'];
            if (this.model.get('settings') && this.model.get('settings')['COLORS-ASSAY']) {
                colorsAssay = this.model.get('settings')['COLORS-ASSAY'].split(';');
            }
            var index = 2;
            var assaysWaitingList = '';

            this.colorsByWells = [];

            if (this.options.model instanceof app.PrepRun) {
                this.options.collection.each(_.bind(function (model) {
                    var backgroundColor = 'white';
                    backgroundColor = '#efefef';
                    var wellAssaysWaitingList = model.getWellAssaysWaitingList().join(';');
                    if (colorsAssay.length > 0 && wellAssaysWaitingList) {
                        if (assaysWaitingList !== wellAssaysWaitingList) {
                            assaysWaitingList = wellAssaysWaitingList;
                            index++;
                        }
                        if (index + 1 > colorsAssay.length) {
                            index = 2;
                        }
                        backgroundColor = ColorUtils.defineAlpha(colorsAssay[index], colorsAssay[0]);
                    } else {
                        assaysWaitingList = '';
                    }
                    this.colorsByWells.push({
                        model: model,
                        backgroundColor: backgroundColor
                    });
                }, this));
            }

            var assayIndex = 2;
            var colorAssay;
            if (this.options.model instanceof app.PcrRun) {
                var assayVersions = _.chain(this.options.model.get('wells').getAssays()).uniq().value();
                this.options.collection.each(_.bind(function (model) {
                    _.each(assayVersions, _.bind(function (value, key) {
                        if (value && model.get('refAssay') && value.get('assay') === model.get('refAssay')) {
                            assayIndex = key + 1;
                        }
                    }, this));
                    if (!!(model.get('refAssay') && model.get('refAssay').get('code'))) {
                        var i = (assayIndex % (colorsAssay.length - 2));

                        if (i === 0) {
                            i = colorsAssay.length - 2;
                        }
                        colorAssay = colorsAssay[i + 1];
                    }
                    var cellOpacity = colorsAssay[0];
                    var assayOpacity = colorsAssay[1];
                    this.colorsByWells.push({
                        model: model,
                        backgroundColor: ColorUtils.defineAlpha(colorAssay, cellOpacity),
                        assayColor: ColorUtils.defineAlpha(colorAssay, assayOpacity)
                    });
                }, this));
            } else if (this.options.model instanceof app.PrepRun) {
                var previous = null;
                this.options.collection.each(_.bind(function (model) {
                    if (model.getWellAssaysWaitingList().length === 0) {
                        return;
                    }
                    if (_.isEqual(previous, model.getWellAssaysWaitingList())) {
                        assayIndex++;
                    }
                    previous = model.getWellAssaysWaitingList();
                    var i = (assayIndex % (colorsAssay.length - 2));

                    if (i === 0) {
                        i = colorsAssay.length - 2;
                    }
                    colorAssay = colorsAssay[i + 1];
                    var cellOpacity = colorsAssay[0];
                    var assayOpacity = colorsAssay[1];
                    this.colorsByWells.push({
                        model: model,
                        backgroundColor: ColorUtils.defineAlpha(colorAssay, cellOpacity),
                        assayColor: ColorUtils.defineAlpha(colorAssay, assayOpacity)
                    });
                }, this));
            }
        },
        childViewOptions: function (model) {
            var backgroundColor = 'transparent';
            if (model.get('type') !== 'header') {
                if (model instanceof app.PcrWell && model.get('refAssay')) {
                    backgroundColor = _.filter(this.colorsByWells, function (item) {
                        return item.model.get('pos') === model.get('pos');
                    })[0].backgroundColor;
                } else if (model instanceof app.PrepWell && model.getAssayStatusCount().waiting) {
                    backgroundColor = _.filter(this.colorsByWells, function (item) {
                        return item.model.get('pos') === model.get('pos');
                    })[0].backgroundColor;
                }
            }
            return {
                view: this.options.view,
                backgroundColor: backgroundColor
            };
        },
        childViewEvents: {
            'item:click': '_itemClick'
        },
        _itemClick: function (view) {
            var func = this.options.events && this.options.events.click;
            if (func) {
                var childrenViews = this.children.filter(function (child) {
                    return child instanceof ItemView && child.isSelected();
                });
                // to keep the original order
                var wells = this.options.collection.filter(function (model) {
                    return _.find(childrenViews, function (child) {
                        return child.model === model;
                    });
                });
                func({model: view.model, wells: wells});
            }
        }
    });
});

