define('propertiesView',[
    'module',
    'underscore',
    'dialogFormView',
    'jqgridView'
], function (
    module,
    _,
    DialogFormView,
    JqGridView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: _.template('<div class="js-grid-region" style="flex-grow: 1; width: 100%; height: 100%;"></div>'),
        regions: {
            gridRegion: '.js-grid-region'
        },

        className: 'content d-f',
        attributes: {
            style: 'flex-grow: 1; width: 100%; height: 100%;'
        },

        paginationObject: function () {
            return {
                first: 0,
                rowCount: 0,
                rows: 20,
                currentPage: 1
            };
        },

        onRender: function () {
            var data = {
                datatype: 'local',
                data: this.options.data
            };

            this.jqGridView = new JqGridView({
                data: data,
                pager: true,
                filtersName: 'wellTableListFilters',
                gridOptions: _.bind(this.gridOptions, this),
                paginationObject: this.paginationObject
            });
            this.showChildView('gridRegion', this.jqGridView);
        },

        serializeData: function () {
            return {};
        },
        gridOptions: function () {
            var options = {
                colModel: [{
                    key: true,
                    label: _.i18n('property.name'),
                    name: 'name',
                    formatter: this.defaultFormatter,
                    search: true,
                    searchoptions: {sopt: ['cn'], clearSearch: false},
                    sortable: true,
                    index: 'name',
                    width: 390
                }, {
                    label: _.i18n('property.value'),
                    name: 'value',
                    formatter: this.defaultFormatter,
                    search: true,
                    searchoptions: {sopt: ['cn'], clearSearch: false},
                    sortable: true,
                    index: 'value',
                    width: 260
                }
                ],
                sortname: this.sortOn ? this.sortOn : 'row'
            };
            return options;
        }
    });
});

