define('runPcrHeaderPlateRightView',[
    'module',
    'backbone.marionette',
    'backbone',
    'template!runPcrHeaderPlateRightView',
    'bootbox',
    'underscore',
    'jquery',
    'menuCheck'
], function (
    module,
    Marionette,
    Backbone,
    Tpl,
    bootbox,
    _,
    $,
    MenuCheck
) {
    'use strict';

    return Marionette.View.extend({
        template: Tpl,
        className: 'right',
        events: {
            'click .js-reset-filters': 'onResetFilters'
        },
        regions: {
            'sampleMenu': '.js-sample-menu',
            'validationMenu': '.js-validation-menu',
            'repeatMenu': '.js-repeat-menu',
            'otherMenu': '.js-other-menu',
            'resultMenu': '.js-result-menu',
            'commentMenu': '.js-comment-menu'
        },
        initialize: function () {
            this.filters = [];
            this.filter = {};
        },
        onRender: function () {
            var sampleMenu = new Backbone.Model({
                nameCode: '',
                items: new Backbone.Collection([
                    {
                        nameCode: 'common.search.on.smpId',
                        click: _.bind(this.onSelectFilters, this),
                        mdi: 'mdi-magnify',
                        attributes: {
                            name: 'select-smpId'
                        }
                    }
                ])
            });
            var validationMenu = new Backbone.Model({
                nameCode: 'common.status',
                items: new Backbone.Collection([
                    {
                        nameCode: 'common.select.val1',
                        click: _.bind(this.onSelectFilters, this),
                        mdi: 'mdi-check',
                        attributes: {
                            name: 'select-val1'
                        },
                        mdiAttributes: {
                            style: 'color: #00b700'
                        }
                    }, {
                        nameCode: 'common.select.val2',
                        click: _.bind(this.onSelectFilters, this),
                        custom: {
                            code: 'val2',
                            className: 'filter'
                        },
                        attributes: {
                            name: 'select-val2'
                        }
                    }, {
                        nameCode: 'common.select.expert',
                        click: _.bind(this.onSelectFilters, this),
                        mdi: 'mdi-school',
                        attributes: {
                            name: 'select-val-expert'
                        },
                        mdiAttributes: {
                            style: 'color: #1c3bc1'
                        }
                    }, {
                        nameCode: 'common.select.warning',
                        click: _.bind(this.onSelectFilters, this),
                        mdi: 'mdi-alert',
                        attributes: {
                            name: 'select-warning'
                        },
                        mdiAttributes: {
                            style: 'color: #ff5e00'
                        }
                    }
                ])
            });

            var repeatMenu = new Backbone.Model({
                nameCode: 'repeat',
                items: new Backbone.Collection([
                    {
                        nameCode: 'common.repeat.pcr',
                        click: _.bind(this.onSelectFilters, this),
                        mdi: 'mdi-triangle mdi-flip-v mdi-rotate-90',
                        attributes: {
                            name: 'select-repeat-pcr'
                        }
                    },
                    {
                        nameCode: 'common.repeat.extract',
                        click: _.bind(this.onSelectFilters, this),
                        attributes: {
                            name: 'select-repeat-extract'
                        },
                        custom: {
                            code: 'repeatExtraction',
                            className: 'filter'
                        }
                    }
                ])
            });

            var otherMenu = new Backbone.Model({
                nameCode: 'prevRes',
                items: new Backbone.Collection([
                    {
                        nameCode: 'common.sameAssay',
                        click: _.bind(this.onSelectFilters, this),
                        mdi: 'mdi-triangle',
                        attributes: {
                            name: 'select-sameAssay'
                        },
                        mdiAttributes: {
                            style: 'color: #ff0000;'
                        }
                    },
                    {
                        nameCode: 'common.otherAssay',
                        click: _.bind(this.onSelectFilters, this),
                        mdi: 'mdi-triangle-down',
                        attributes: {
                            name: 'select-otherAssay'
                        },
                        mdiAttributes: {
                            style: 'color: #09f9e5;'
                        }
                    }
                ])
            });

            var resultMenu = new Backbone.Model({
                nameCode: 'ovaRes',
                items: new Backbone.Collection()
            });

            var displayGroups = this.model.get('wells')
                .chain()
                .map(function (well) {
                    return well.getDisplay('OVAR').first();
                })
                .filter(function (ovar) {
                    return ovar && ovar.get('result');
                })
                .uniq(function (ovar) {
                    return ovar.get('result') && ovar.get('result').id;
                })
                .value();

            _.each(displayGroups, _.bind(function (wellRes) {
                resultMenu.get('items').add({
                    nameCode: wellRes.get('result').get('code'),
                    title: wellRes.get('result').get('resText'),
                    click: _.bind(this.onSelectFilters, this),
                    mdi: 'mdi-circle',
                    attributes: {
                        name: 'select-wellres-' + wellRes.get('result').get('code')
                    },
                    mdiAttributes: {
                        style: 'color: ' + wellRes.get('result').get('color')
                    },
                    secId: wellRes.get('result').id
                });
            }, this));

            var commentMenu = new Backbone.Model({
                nameCode: 'common.misc',
                items: new Backbone.Collection([
                    {
                        nameCode: 'common.comment.well',
                        click: _.bind(this.onSelectFilters, this),
                        mdi: 'mdi-comment-alert',
                        attributes: {
                            name: 'select-comment'
                        }
                    }
                ])
            });
            this.showChildView('sampleMenu', new MenuCheck({
                model: sampleMenu
            }));
            this.showChildView('validationMenu', new MenuCheck({
                model: validationMenu
            }));
            this.showChildView('repeatMenu', new MenuCheck({
                model: repeatMenu
            }));
            this.showChildView('otherMenu', new MenuCheck({
                model: otherMenu
            }));
            this.showChildView('resultMenu', new MenuCheck({
                model: resultMenu
            }));
            this.showChildView('commentMenu', new MenuCheck({
                model: commentMenu
            }));
        },
        serializeData: function () {
            var templateData = {};
            templateData.colorOtherWellSameAssay = '#FF0000';
            templateData.colorOtherWellOtherAssay = '#000000';
            if (this.model.get('settings') && this.model.get('settings')['OTHER-ASSAY-IND']) {
                var otherAssayInd = this.model.get('settings')['OTHER-ASSAY-IND'].split(';');
                if (otherAssayInd[0]) {
                    templateData.colorOtherWellSameAssay = otherAssayInd[0];
                }
                if (otherAssayInd[1]) {
                    templateData.colorOtherWellOtherAssay = otherAssayInd[1];
                }
            }

            return templateData;
        },
        onResetFilters: function () {
            this.filters = [];
            this.filter = {sampleId: null};
            this.model.get('wells').trigger('filter', this.model.get('wells').models);
            var items = this.sampleMenu.get('items').models;
            items = items.concat(this.repeatMenu.get('items').models);
            items = items.concat(this.validationMenu.get('items').models);
            items = items.concat(this.otherMenu.get('items').models);
            items = items.concat(this.resultMenu.get('items').models);
            _.each(items, function (item) {
                item.trigger('selected', false);
            });
        },
        onSelectFilters: function (e) {
            this._selectFilters(e).done(_.bind(function () {
                var wells;
                if (_.isEmpty(this.filters)) {
                    wells = this.model.get('wells').models;
                } else {
                    wells = this.model.get('wells').chain().filter(_.bind(function (well) {
                        return _.find(this.filters, _.bind(function (filter) {
                            switch (filter) {
                                case 'select-val1':
                                    return well.get('valWst') === 2.1;
                                case 'select-val2':
                                    return well.get('valWst') === 3.2;
                                case 'select-val-expert':
                                    return well.get('valWst') === 3.1;
                                case 'select-warning':
                                    return well.get('codeErr');
                                case 'select-smpId':
                                    return well.get('smpId').get('name').includes(this.filter.sampleId);
                                case 'select-repeat-pcr':
                                    return well.get('repeatStatus') && well.get('repeatStatus').startsWith('P');
                                case 'select-repeat-extract':
                                    return well.get('repeatStatus') && well.get('repeatStatus').startsWith('X');
                                case 'select-sameAssay':
                                    return well.get('existOtherWellSameAssay');
                                case 'select-otherAssay':
                                    return well.get('existOtherWellOtherAssay');
                                case 'select-comment':
                                    return well.get('smpId').get('comment') || well.get('smpId').get('lisComment');
                                default:
                                    if (filter.startsWith('select-wellres-')) {
                                        return well.getDisplay('OVAR').chain()
                                            .filter(function (result) {
                                                return result.isOvar();
                                            })
                                            .some(function (result) {
                                                var code = filter.substring('select-wellres-'.length);
                                                return result.get('result') && result.get('result').get('code') === code;
                                            })
                                            .value();
                                    }
                            }
                        }, this));
                    }, this)).value();
                }

                this.model.get('wells').forEach(function (well) {
                    well.trigger('selected', wells.includes(well));
                });
            }, this));
        },
        _selectFilters: function (e) {
            var defer = $.Deferred();

            var attribute = e.currentTarget.attributes.name.value;
            if (this.filters.includes(attribute)) {
                this.filters = _.reject(this.filters, function (filter) {
                    return filter === attribute;
                });
                defer.resolve();
            } else {
                this.filters.push(attribute);
                if (attribute === 'select-smpId') {
                    bootbox.prompt({
                        title: _.i18n('popup.select.smpId'),
                        className: 'actionPopupCss popupFitContent headerAction-bootboxPopup',
                        onEscape: true,
                        buttons: {
                            confirm: {
                                label: '<i></i>',
                                className: 'mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm btn-primary'
                            },
                            cancel: {
                                label: '<i></i>',
                                className: 'mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup'
                            }
                        },
                        callback: _.bind(function (result) {
                            this.filter.sampleId = result;
                            defer.resolve();
                        }, this)
                    });
                } else {
                    defer.resolve();
                }
            }
            return defer.promise();
        }
    });
});

