
define('template!assayResultVersion', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="col-w-all col-w-100 d-f p-l-0 p-r-5">\n	<div class="col-w-3">\n		<input type="number" data-field-name="sequence" min="1" value="' +
((__t = ( sequence )) == null ? '' : __t) +
'"\n			   class="col-w-100  form-control inputBackground"/>\n	</div>\n\n	<div class="col-w-9 js-dropDownRegion-input p-l-5">\n		<input type="text"\n			   class="form-control inputBackground"\n			   placeholder=""\n			   value="' +
((__t = ( target ? target.code : '' )) == null ? '' : __t) +
'"\n			   data-content="' +
((__t = ( _.i18n('common.duplicatedCodeError') )) == null ? '' : __t) +
'"\n			   data-placement="top" autocomplete="off">\n	</div>\n	<div class="col-w-2 p-l-5">\n		<input class="js-info-input targetGridIcRowText inputBackground js-radio"\n			   value="' +
((__t = ( secId )) == null ? '' : __t) +
'"\n			   id="ic-' +
((__t = ( secId )) == null ? '' : __t) +
'"\n			   name="ic" readonly="readonly"\n			   data-field-name="ic" type="radio" ' +
((__t = ( ic ? 'checked="checked"' : '' )) == null ? '' : __t) +
' />\n	</div>\n\n	<div class="col-w-45 d-b p-l-0 p-r-5 css-algoRes-column-color" style="padding: 2px 0 2px 0;">\n		<div class="js-algoResult"></div>\n	</div>\n	<div class="col-w-1"></div>\n\n	<div class="col-w-15 d-b p-l-0 p-r-5">\n		<div class="js-algoValidation"></div>\n	</div>\n\n	<div class="col-w-1">\n	</div>\n\n	<div class="col-w-15 d-b p-l-0 p-r-5">\n		<div class="js-algoExport"></div>\n	</div>\n</div>\n<div class="row js-targetValues">\n</div>';

}
return __p
};});

