define('entities/caccounts/pcrwellresults',[
    'entities/ns',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'rolesMixin',
    'privileges',
    'app',
    'backboneRelational',
    'entities/caccounts/userpids',
    'entities/caccounts/assayResultVersion',
    'entities/caccounts/mbanas',
    'entities/caccounts/mbanares',
    'entities/caccounts/curves',
    'entities/caccounts/standardCurve/standardCurveAssayConfigurationResultValues'
], function (
    app,
    Backbone,
    Settings,
    $,
    _,
    RolesMixin,
    Privileges,
    App
) {
    'use strict';

    app.PcrWellResult = Backbone.RelationalModel.extend({
        service: 'services/caccounts/pcrwellresults',
        fetch: function () {
            this.url = Settings.url('rest/v2/results/pcr/' + this.id);

            return Backbone.Model.prototype.fetch.call(this);
        },
        subModelTypes: {
            'CURVE': 'PcrWellResultCurve',
            'HL7': 'PcrWellResultHL7',
            'MAGPIX': 'PcrWellResultMagPix',
            'OTHER': 'PcrWellResultOther',
            'CALCULATED': 'PcrWellResultCalculated',
            'SPO_OVAR': 'PcrWellResultSPOOvar',
            'OVAR': 'PcrWellResultOvar',
            'OVAG_NPX': 'PcrWellResultOvagNpx'
        },

        subModelTypeAttribute: 'type',
        defaults: {
            'cts': [],
            'result': null,
            'comment': '',
            'codeErr': null,
            'commentErrAuto': '',
            'justificatory': [],
            'refValUser': null,
            'refValUser2': null,
            'valSt': 0,
            'valTStmp': null,
            'valTStmp2': null,
            'propC': '',
            'internalControl': false,
            'needValidation': false,
            'sequence': undefined,
            'refAssayResult': null
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'refValUser',
            relatedModel: 'UserPid',
            includeInJSON: false
        }, {
            type: Backbone.HasOne,
            key: 'refValUser2',
            relatedModel: 'UserPid',
            includeInJSON: false
        }, {
            type: Backbone.HasOne,
            key: 'refAssayResult',
            relatedModel: 'AssayResultVersion',
            includeInJSON: ['id', 'code', 'name']
        }, {
            type: Backbone.HasOne,
            key: 'result',
            relatedModel: 'MbAnaRes',
            includeInJSON: ['secId', 'code', 'name']
        }, {
            type: Backbone.HasOne,
            key: 'pcrWell',
            relatedModel: 'PcrWell'
        }],
        getPDF: function () {
            return Settings.getDocUrl('PcrWellResultJson.pdf');
        },
        isOvar: function () {
            return this instanceof app.PcrWellResultOvar;
        },

        postUrl: function () {
            if (this.isNew()) {
                return Settings.url('rest/v2/results/pcr/');
            }
            return Settings.url('rest/v2/results/pcr/' + this.id);
        },

        save: function () {
            this.url = this.postUrl();
            var defer = $.Deferred();
            Backbone.Model.prototype.save.call(this).done(_.bind(function () {
                require(this.service).findResultByWells(this.get('pcrWell')).done(function () {
                    defer.resolve();
                });
            }, this));
            return defer.promise();
        },
        confirmResult: function (justificatory) {
            var url = Settings.url('rest/v2/wells/pcr/' + this.get('pcrWell').get('secId') + '/confirm/' + this.get('refAssayResult').get('id')),
                defer = $.Deferred();
            $.ajax({
                url: url,
                type: 'PUT',
                data: justificatory,
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(_.bind(function () {
                        this.get('pcrWell').fetch().done(function () {
                            defer.resolve();
                        });
                    }, this));
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        negativeResult: function (justificatory) {
            var url = Settings.url('rest/v2/wells/pcr/' + this.get('pcrWell').get('secId') + '/negative/' + this.get('refAssayResult').get('id')),
                defer = $.Deferred();
            $.ajax({
                url: url,
                type: 'PUT',
                data: justificatory,
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(_.bind(function () {
                        this.get('pcrWell').fetch().done(function () {
                            defer.resolve();
                        });
                    }, this));
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        getResultIcon: function () {
            var resFormatted = (this.get('cts') && Array.isArray(this.get('cts'))) ? this.get('cts').join(';') : (this.get('cts') ? this.get('cts') : '');
            var quantificationFormatted = (this.get('quantifications') && Array.isArray(this.get('quantifications'))) ? this.get('quantifications')[0] : (this.get('quantifications') ? this.get('quantifications') : 0);

            var errMessComToDisplayList = '';
            var commentsList = [];

            if (this.get('codeErr') && this.get('codeErr') !== '') {
                commentsList.push(this.get('codeErr'));
            }
            if (this.get('justificatory') && this.get('justificatory') !== '') {
                commentsList.push(this.get('justificatory'));
            }
            if (this.get('comment') && this.get('comment') !== '') {
                commentsList.push(this.get('comment'));
            }

            if (commentsList.length > 0) {
                errMessComToDisplayList = commentsList.join(' - ');
            }

            return {
                targetCode: this.get('refAssayResult').get('target').get('code'),
                targetName: this.get('refAssayResult').get('target').get('name'),
                resCode: this.get('result') ? this.get('result').get('code') : null,
                resColor: this.get('result') ? this.get('result').get('color') : 0,
                valSt: this.get('valSt'),
                cts: resFormatted,
                quantifications: quantificationFormatted,
                propC: this.get('propC'),
                codeErr: this.get('codeErr'),
                commentErrAuto: this.get('commentErrAuto'),
                comment: this.get('comment'),
                justificatory: this.get('justificatory'),
                user: this.get('refValUser'),
                user2: this.get('refValUser2'),
                errMessComToDisplayList: errMessComToDisplayList,
                isOVAR: this.get('refAssayResult').get('type') === 'CALCULATED',
                color: this.get('result') ? this.get('result').get('color') : null
            };
        },

        getPropCFormated: function () {
            return JSON.stringify(this.get('propC'))
                .replaceAll('{', '')
                .replaceAll('}', '')
                .replaceAll('"', '')
                .replaceAll(',', '; ')
                .replaceAll(':', ': ');
        },
        isReadOnly: function () {
            return this.get('pcrWell') && (this.get('pcrWell').isReadOnly(true) || this.get('pcrWell').get('repeatStatus') !== null || this.get('valSt') >= 4 ||
                (this.get('refAssayResult').get('type') === 'CURVE' && this.get('pcrWell').get('valWst') >= 4) ||
                (this.get('valSt') === 3.1 && !(Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.VAL_EXPERT))));
        },
        loadProperties: function () {
            var defer = $.Deferred();
            $.ajax({
                url: Settings.url('rest/v2/properties/findByResult/pcr/' + this.get('secId')),
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function (response) {
                    defer.resolve(response);
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        }
    });

    app.PcrWellResultCollection = Backbone.Collection.extend({
        model: app.PcrWellResult,
        fetch: function () {
            var defer = $.Deferred();
            $.ajax({
                url: Settings.url('rest/v2/results/pcr/'),
                type: 'POST',
                contentType: 'application/json',
                data: JSON.stringify(this.pluck(app.PcrWellResult.idAttribute)),
                success: _.bind(function (datas) {
                    _.each(datas, function (data) {
                        app.PcrWellResult.findOrCreate(data);
                    });
                    defer.resolve();
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        }
    });

    app.PcrWellResultCalculated = app.PcrWellResult.extend({
        defaults: {},
        idAttribute: 'secId'
    });

    app.PcrWellResultCurve = app.PcrWellResult.extend({
        defaults: {
            'curves': [],
            standardCurveAssayConfigurationResultValue: null
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'curves',
            relatedModel: 'Curve',
            collectionType: 'CurveCollection'
        }, {
            type: Backbone.HasOne,
            key: 'standardCurveAssayConfigurationResultValue',
            relatedModel: 'StandardCurveAssayConfigurationResultValue'
        }],
        downloadRawData: function () {
            var url = Settings.url('rest/v2/wells/pcr/' + this.get('pcrWell').get('secId') + '/results/' + this.get('secId') + '/curve');
            App.downloadFile(url);
        },
        downloadFluorData: function () {
            var url = Settings.url('rest/v2/wells/pcr/' + this.get('pcrWell').get('secId') + '/results/' + this.get('secId') + '/curve/fluor');
            App.downloadFile(url);
        },
        showFluorData: function () {
            return Settings.url('rest/v2/wells/pcr/' + this.get('pcrWell').get('secId') + '/results/' + this.get('secId') + '/curve/fluor');
        }
    });

    app.PcrWellResult.role = RolesMixin.WELL_PCR;

    app.PcrWellResultHL7 = app.PcrWellResult.extend({
        defaults: {},
        idAttribute: 'secId'
    });

    app.PcrWellResultMagPix = app.PcrWellResult.extend({
        defaults: {
            beadCount: 0,
            mfiValue: 0,
            mfiThreshold: 0,
            mddValue: 0,
            mddThreshold: 0
        },
        idAttribute: 'secId'
    });

    app.PcrWellResultOther = app.PcrWellResult.extend({
        defaults: {},
        idAttribute: 'secId'
    });

    app.PcrWellResultOvar = app.PcrWellResult.extend({
        defaults: {},
        idAttribute: 'secId'
    });

    app.PcrWellResultSPOOvar = app.PcrWellResult.extend({
        defaults: {},
        idAttribute: 'secId'
    });

    app.PcrWellResultOvagNpx = app.PcrWellResult.extend({
        defaults: {
            results: []
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'results',
            relatedModel: 'PcrWellResult',
            collectionType: 'PcrWellResultCollection'
        }]
    });

    return app.PcrWellResult;
});

