define('services/caccounts/kitprots',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/kitprots'
], function (
    app,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.KitProt,
                collection: app.KitProtCollection
            };
        },
        getName: function () {
            return 'kitprot';
        },
        getUrl: function () {
            return 'rest/v2/kitprots/';
        },
        getDynamicJson: function () {
            return 'KitProtJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {clearSearch: false},
                width: '50px'
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {clearSearch: false},
                width: '70px'
            }, {
                label: _.i18n('kitprot.ampParam'),
                name: 'ampParam',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'ampParam',
                searchoptions: {clearSearch: false},
                width: '45px'
            }, {
                label: _.i18n('kitprot.meltParam'),
                name: 'meltParam',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'meltParam',
                searchoptions: {clearSearch: false},
                width: '45px'
            }, {
                label: _.i18n('kitprot.configurations'),
                name: 'configurations',
                classes: 'dynamic-link',
                formatter: this.configurationFormatter,
                sortable: false,
                search: true,
                index: 'cyclerCode',
                searchoptions: {clearSearch: false},
                width: '45px'
            }, {
                label: _.i18n('kitprot.assays'),
                name: 'assays',
                classes: 'dynamic-link',
                formatter: viewDynamic.codeNameListFormatter,
                sortable: false,
                search: false,
                width: '100px'
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description',
                searchoptions: {clearSearch: false},
                width: '200px'
            });
            return columns;
        },
        configurationFormatter: function (cellValue, options) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = [];
            }

            var values = [];
            _.each(cellValue, function (configuration) {
                if (configuration.refCycler) {
                    values.push(configuration.refCycler.code);
                }
            });
            cellValue = values.join(', ');
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + cellValue + '</span>';
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push(
                {'field': 'ampParam', 'param': {'type': 'ARRAYLIST', 'arrayCode': 'PROT-AC'}},
                {'field': 'meltParam', 'param': {'type': 'ARRAYLIST', 'arrayCode': 'PROT-MC'}},
                {'field': 'ampSmooth', 'param': {'type': 'ARRAYLIST', 'arrayCode': 'SMOOTH-AC'}},
                {'field': 'meltSmooth', 'param': {'type': 'ARRAYLIST', 'arrayCode': 'SMOOTH-MC'}},
                {'field': 'displayMode', 'param': {'type': 'REFERENCE', 'display': {'class': 'col-xs-2'}}},
                {
                    'field': 'assays',
                    'param': {
                        'type': 'LIST2ENTITY'
                    }
                }
            );
            return custom;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        }
    }, Dynamic);
});

