/**
 * Created by OBL on 14/01/2016.
 */
define('fileExplorerController',[
    'module',
    'underscore',
    'jquery',
    'fileExplorer',
    'backbone',
    'mimoza',
    'flash'
], function (
    module,
    _,
    $,
    View,
    Backbone,
    Mimoza,
    Flash
) {
    'use strict';

    module.exports = {
        show: function (settingSecId, selectable, callBackConfirm) {
            var view = new View({
                settingSecId: settingSecId,
                selectable: selectable,
                callBackConfirm: callBackConfirm,
                model: new Backbone.Model()
            });
            view.show({
                title: _.i18n('fileExplorer.title'),
                className: 'baseTableEditAddPopup fileExplorer'
            });
        },
        /**
         * Download a file
         * @param fileName
         * @param data
         */
        downloadFile: function (fileName, data) {
            // determine the type from extension of the fileName
            var extension = fileName.split('.').pop();
            var type = Mimoza.getMimeType(extension);
            var blob = new Blob([data], {type: type});
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
            Flash.success(_.i18n('download.success'));
        }
    };
});
