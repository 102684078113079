define('propertiesController',[
    'module',
    'underscore',
    'bootbox',
    'propertiesView'
], function (
    module,
    _,
    Bootbox,
    PropertiesView
) {
    'use strict';

    module.exports = {
        showProperties: function (model) {
            if(!model.loadProperties) {
                throw new Error('Model does not have loadProperties method');
            }
            model.loadProperties().done(_.bind(function(datas) {
                if(_.isEmpty(datas)) {
                    Bootbox.alert(_.i18n('no.properties'));
                    return;
                }
                var properties = _.chain(datas)
                    .map(function (property) {
                        return {
                            name: property.name,
                            value: property.value
                        };
                    })
                    .sort(function (a, b) {
                        return a.name.localeCompare(b.name);
                    })
                    .value();
                var view = new PropertiesView({
                    data: properties
                });
                view.show({
                    title: _.i18n('properties')
                });
            }));
        }
    };
});

