define('dynamicListToList',[
    'module',
    'backbone',
    'dialogFormView',
    'template!dynamicListToList',
    'underscore',
    'jquery',
    'settings',
    'savingBehavior',
    'commonListBox'
], function (
    module,
    Backbone,
    DialogFormView,
    tpl,
    _,
    $,
    Settings,
    SavingBehavior,
    CommonListBox
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: tpl,

        ui: {
            cancel: '.js-cancel-popup',
            confirm: '.js-confirm',
            rawParams: '#rawparams',
            copyParameter: '.js-copy',
            pasteParameter: '.js-paste',
            deleteParameter: '.js-delete'
        },

        events: {
            'click .mdi-transfer-left': 'onTransferToLeft',
            'click .mdi-transfer-right': 'onTransferToRight',
            'click .js-cancel-popup': 'onCancel',
            'click .js-confirm': 'onConfirm',
            'click @ui.copyParameter': 'onCopyParameter',
            'click @ui.pasteParameter': 'onPasteParameter',
            'click @ui.deleteParameter': 'onDeleteParameter',
            'change @ui.rawParams': 'onRawParamsChange'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        modelEvents: {
            'change': 'render'
        },

        regions: {
            available: '.js-available-region',
            selected: '.js-selected-region'
        },
        className: 'col-w-100',

        initialize: function () {
            if (!this.options.separator) {
                this.options.separator = ';';
            }

            // convert item to collection of new Backbone.Model
            if (this.options.items instanceof Backbone.Collection) {
                this.options.items = this.options.items.models;
            }
            this.valueKey = null;
            var items = _.map(this.options.items, _.bind(function (item) {
                var name;
                var value;
                if (item instanceof Backbone.Model) {
                    if (item.get('secId')) {
                        this.valueKey = 'secId';
                        value = item.get('code');
                    } else if (item.get('code')) {
                        this.valueKey = 'code';
                        value = item.get('code');
                    } else if (item.get('name')) {
                        this.valueKey = 'name';
                        value = item.get('name');
                    }
                    name = item.get('code') ? '[' + item.get('code') + ']: ' + item.get('name') : item.get('name');
                    return {id: item.get(this.valueKey), value: value, name: name, sequence: item.get('sequence')};
                }
                if (_.isArray(item) || _.isObject(item)) {
                    if (item.secId) {
                        this.valueKey = 'secId';
                        value = item.code;
                    } else if (item.code) {
                        this.valueKey = 'code';
                        value = item.code;
                    } else if (item.name) {
                        this.valueKey = 'name';
                        value = item.name;
                    }
                    name = item.code ? '[' + item.code + ']: ' + item.name : item.name;
                    return {id: item[this.valueKey], value: value, name: name, sequence: item.sequence};
                }
                return {value: item, name: '[' + item + ']: ' + item, sequence: undefined};
            }, this));

            this.model = new Backbone.Model();
            this.model.set('items', new Backbone.Collection(_.map(items, function (item) {
                return new Backbone.Model(item);
            })));


            if (this.options.values instanceof Backbone.Collection) {
                this.options.values = this.options.values.models;
            }

            if (!_.isArray(this.options.values)) {
                if (this.options.values) {
                    this.options.values = this.options.values.split(this.options.separator);
                } else {
                    this.options.values = [];
                }
            }

            this.model.set('available', new Backbone.Collection(this.model.get('items')
                .chain()
                .reject(_.bind(function (item) {
                    return _.any(this.options.values, _.bind(function (value) {
                        if (value instanceof Backbone.Model && this.valueKey) {
                            return item.id === value.get(this.valueKey);
                        }
                        if (item.get('value')) {
                            return item.get('value') === value;
                        }
                        return item === value;
                    }, this));
                }, this))
                .sortBy(function (item) {
                    if (item.get('value')) {
                        return item.get('value');
                    }
                    if (item.get('name')) {
                        return item.get('name');
                    }
                    return item;
                })
                .value()));

            this.model.set('selected', new Backbone.Collection(this.model.get('items')
                .chain()
                .filter(_.bind(function (item) {
                    return _.any(this.options.values, _.bind(function (value) {
                        if (value instanceof Backbone.Model && this.valueKey) {
                            return item.id === value.get(this.valueKey);
                        }
                        if (item.get('value')) {
                            return item.get('value') === value;
                        }
                        return item === value;
                    }, this));
                }, this))
                .sortBy(_.bind(function (item) {
                    // sort by order in this.options.values
                    return _.indexOf(this.options.values, item.get('value'));
                }, this))
                .value()));
        },

        serializeData: function () {
            var templateData = {maxSize: this.options.items.length < 30 ? this.model.get('available').length + this.model.get('selected').length : 30};
            templateData.readOnly = this.options.readOnly || (this.options.display && this.options.display.readOnly);
            templateData.accessToClipboard = navigator.clipboard;
            templateData.entityName = this.options.entityName;
            templateData.fieldName = this.options.field;
            templateData.codeListCode = this.options.codeListCode ? ' - ' + this.options.codeListCode : '';
            templateData.sortable = this.options.sortable;
            return templateData;
        },

        onRender: function () {
            var max = _.max([this.model.get('available').length, this.model.get('selected').length]);
            var size = max > 20 ? 20 : max;
            var attributes = {
                multiple: true,
                size: size
            };
            this.showChildView('available', new CommonListBox({filter: true, attr: attributes, collection: this.model.get('available'), displaySize: size}));
            this.showChildView('selected', new CommonListBox({
                filter: true,
                attr: attributes,
                collection: this.model.get('selected'),
                sortable: this.options.sortable,
                sortableSide: 'right',
                displaySize: size
            }));
            this.ui.rawParams.val(
                _.map(this.getChildView('selected').getValues(), _.bind(function (item) {
                    return this.model.get('items').chain()
                        .filter(function (s) {
                            return s.get('value') === item;
                        })
                        .map(function (s) {
                            return s.get('value');
                        })
                        .first()
                        .value();
                }, this)).join(this.options.separator));
            this.listenTo(this.getChildView('selected'), 'select:change:updown', this.onUpdateRawParams);
            this.setPermissions(!(this.options.readOnly || (this.options.display && this.options.display.readOnly)));
        },

        onUpdateRawParams: function () {
            this.ui.rawParams.val(
                _.map(this.getChildView('selected').getValues(), _.bind(function (item) {
                    return this.model.get('items').chain()
                        .filter(function (s) {
                            return s.get('value') === item;
                        })
                        .map(function (s) {
                            return s.get('value');
                        })
                        .first()
                        .value();
                }, this)).join(this.options.separator));
        },

        onConfirm: function () {
            var ret = [];
            var values = this.getChildView('selected').collection.pluck('id');
            _.map(this.options.items, _.bind(function (item) {
                if (item instanceof Backbone.Model) {
                    if (values.includes(item.get(this.valueKey))) {
                        ret.push(item);
                    }
                } else {
                    if (values.includes(item[this.valueKey])) {
                        ret.push(item);
                    }
                }
            }, this));
            this.trigger('listToList:confirm', ret);
            this.hide();
        },

        onTransferToRight: function () {
            var selectedItems = this.getChildView('available').getSelected();
            this._transfer(selectedItems, 'available', 'selected');
        },

        onTransferToLeft: function () {
            var selectedItems = this.getChildView('selected').getSelected();
            this._transfer(selectedItems, 'selected', 'available');
        },

        _transfer: function (items, origin, target) {
            var models = this.model.get('items').filter(_.bind(function (item) {
                return items.includes(item.get('value'));
            }, this));
            this.model.get(origin).remove(models);
            this.model.get(target).add(models);
            this.model.trigger('change');
        },

        onCopyParameter: function () {
            this.ui.copyParameter.css('color', '#4cc8da');
            if (!navigator.clipboard) {
                //old - document execcommand copy is deprecated
                var range = document.createRange();
                range.selectNode(document.getElementById('rawparams'));
                window.getSelection().removeAllRanges(); // clear current selection
                window.getSelection().addRange(range); // to select text
                document.execCommand('copy');
                window.getSelection().removeAllRanges();// to deselect
            } else {
                var textToCopy = this.ui.rawParams.val();
                navigator.clipboard.writeText(textToCopy);
            }
            setTimeout(_.bind(function () {
                this.ui.copyParameter.css('color', 'black');
            }, this), 100);
        },

        onPasteParameter: function () {
            navigator.clipboard.readText().then(_.bind(function (clipText) {
                this._changeParameter(clipText);
            }, this));
        },

        onDeleteParameter: function () {
            this._transfer(this.getChildView('selected').$el.val(), 'selected', 'available');
        },
        onRawParamsChange: function (e) {
            this._changeParameter(e.target.value);
        },
        _changeParameter: function (clipText) {
            this.model.get('available').reset(this.model.get('items').models);
            this.model.get('selected').reset();
            var items = _.filter(clipText.split(this.options.separator), function (m) {
                return m;
            });
            this._transfer(items, 'available', 'selected');
        }
    });
});

