define('entities/caccounts/assayConfigurationResult',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/mbanas',
    'entities/caccounts/assayconfiguration'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.AssayConfigurationResult = Backbone.RelationalModel.extend({
        service: 'services/caccounts/assayConfigurationResult',
        fetch: function () {
            this.url = Settings.url('rest/v2/assayConfigurationResults/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'target': null,
            'algoResult': {type: '', param: ''},
            'algoValidation': {type: 'NO', param: ''},
            'algoExport': {type: 'NO', param: ''},
            'ic': false,
            'configuration': null,
            'sequence': null,
            'needValidation': false,
            'assayConfiguration': null
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'target',
            relatedModel: 'MbAna',
            includeInJSON: ['secId', 'code', 'name']
        }, {
            type: Backbone.HasOne,
            key: 'assayConfiguration',
            relatedModel: 'AssayConfiguration',
            includeInJSON: false
        }, {
            type: Backbone.HasMany,
            key: 'linkedAssayConfigurationResults',
            relatedModel: 'AssayConfigurationResult',
            collectionType: 'AssayConfigurationResultCollection',
            includeInJSON: false
        }],


        save: function () {
            throw new Error('Can\'t save this entity');
        },

        jsonObjectName: 'AssayConfigurationResultJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },
        jsonObject: 'AssayConfigurationResult'
    });

    app.AssayConfigurationResult.role = RolesMixin.ASSAY;

    app.AssayConfigurationResultCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/assayConfigurationResults/');
        },
        model: app.AssayResult,
        comparator: 'sequence'
    });
});

