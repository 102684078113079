define('runPcrHeaderContaminationLeftView',[
    'module',
    'backbone.marionette',
    'backbone',
    'underscore',
    'template!runPcrHeaderContaminationLeftView',
    'commonSelect'
], function (
    module,
    Marionette,
    Backbone,
    _,
    template,
    CommonSelect
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: template,
        className: 'header contamination',
        regions: {
            selectTarget: '.select-target-region'
        },
        ui: {
            previous: '.previous',
            next: '.next',
            left: '.mdi-chevron-left',
            right: '.mdi-chevron-right'
        },
        events: {
            'click .clickable.previous': 'onLeft',
            'click .clickable.next': 'onRight'
        },

        initialize: function () {
            this.resultsByAssay = this.model.get('assayVersions').map(function (assayVersion) {
                return new Backbone.Model({
                    type: 'group',
                    name: assayVersion.get('code') + ' (' + assayVersion.get('secId') + ')',
                    items: assayVersion.get('results').map(function (target) {
                        var dye = target.get('algoResult') && ['AMP', 'AMP_IC', 'MUTV', 'SCID'].includes(target.get('algoResult').type) && target.get('algoResult').param ? target.get('algoResult').param.split(';')[0] : '';
                        return {name: target.get('target').get('code') + (dye ? ' (' + dye + ')' : ''), value: target.id};
                    })
                });
            });
            this.targets = new Backbone.Collection(
                this.model.get('assayVersions').chain().map(function (assayVersion) {
                    return assayVersion.get('results').models;
                }).flatten().value()
            );
        },
        serializeData: function () {
            return {};
        },
        onRender: function () {
            var selectTargetView = new CommonSelect({
                collection: this.resultsByAssay,
                model: this.model,
                modelProperty: 'assayResultVersion'
            });
            this.listenTo(selectTargetView, 'select:change', _.bind(this.changeAssayResultVersion, this));
            this.showChildView('selectTarget', selectTargetView);
            this._displayButton(this.targets.first());
        },
        changeAssayResultVersion: function (collectionView, targetId) {
            var assayResultVersion = this.targets.chain().filter(function (target) {
                return target.id.toString() === targetId.currentTarget.value.toString();
            }).first().value();
            this._displayButton(assayResultVersion);
            this.model.get('wells').each(function (well) {
                well.trigger('change:assayResultVersion', assayResultVersion);
            });
        },
        _displayButton: function (assayResultVersion) {
            var index = this.targets.indexOf(assayResultVersion);
            var enable = index > 0;
            this.ui.left.toggleClass('disabled', !enable);
            this.ui.left.attr('disabled', !enable);
            this.ui.left.toggleClass('clickable', enable);
            this.ui.previous.toggleClass('disabled', !enable);
            this.ui.previous.attr('disabled', !enable);
            this.ui.previous.toggleClass('clickable', enable);

            enable = index < this.targets.length - 1;
            this.ui.right.toggleClass('disabled', !enable);
            this.ui.right.attr('disabled', !enable);
            this.ui.right.toggleClass('clickable', enable);
            this.ui.next.toggleClass('disabled', !enable);
            this.ui.next.attr('disabled', !enable);
            this.ui.next.toggleClass('clickable', enable);
        },
        onLeft: function () {
            this.getChildView('selectTarget').previous();
        },
        onRight: function () {
            this.getChildView('selectTarget').next();
        }
    });
});
