define('services/caccounts/extractionMethodReagent',[
    'entities/ns',
    'module',
    'dynamic',
    'underscore',
    'entities/caccounts/extractionMethodReagent'
], function (
    app,
    module,
    Dynamic,
    _
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ExtractionMethodReagent,
                collection: app.ExtractionMethodReagentCollection
            };
        },
        getName: function () {
            return 'extractionMethodReagent';
        },
        getDynamicJson: function () {
            return 'ExtractionMethodReagentJson';
        },
        getCollection: function (modelJson) {
            if (!modelJson) {
                modelJson = [];
            }
            return new app.ExtractionMethodReagentCollection(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 450, 'fields': ['name', 'refExtractor.code']};
        },

        getCreateEditFieldIgnore: function () {
            var ignored = Dynamic.getCreateEditFieldIgnore.call(this);
            ignored.push('comment');
            return ignored;
        },

        getCreateEditLine: function (model) {
            var ExtractionKitService = require('services/caccounts/kitsextraction');
            var ExtractionReagentService = require('entities/caccounts/extractionMethodReagent');
            return [
                {
                    'field': 'sequence',
                    'param': {'type': 'TEXT', 'display': {'class': 'col-w-6', 'maxLength': '3'}}
                },
                {
                    'field': 'kit',
                    'param': {
                        'type': 'REFERENCE',
                        'config': {'getAutocompleteParam': ExtractionKitService.getAutocompleteParam},
                        'context': ExtractionKitService,
                        'display': {'class': 'col-w-20 m-5'},
                        onChange: function (fieldName, modelFieldName, model) {
                            var data = {reagent: null};
                            if (modelFieldName !== null) {
                                data[fieldName] = modelFieldName.toJSON ? modelFieldName.toJSON() : modelFieldName;
                            }
                            model.set(data, {silent: true});
                            model.trigger('change:kit');
                        }
                    }
                },
                {
                    'field': 'reagent',
                    'dependsOn': {
                        'model': model,
                        'field': 'kit'
                    },
                    'param': {
                        'type': 'REFERENCE',
                        'config': {
                            'getAutocompleteParam': model && model.get('kit') ? _.bind(ExtractionKitService.getAutocompleteParamForReagent, this, model.get('kit')) : null
                        },
                        'context': ExtractionReagentService,
                        'display': {'class': 'col-w-15'}
                    }
                },
                {'field': 'remark', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-44'}}},
                {'field': 'volumeByTest', 'param': {'type': 'NUMBER', 'display': {'class': 'col-w-8'}}},
                {'field': 'volumeDeath', 'param': {'type': 'NUMBER', 'display': {'class': 'col-w-8 '}}}
            ];
        }
    }, Dynamic);

});

