define('entities/caccounts/lmbextractors',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.LmbExtractor = Backbone.RelationalModel.extend({
        service: 'services/caccounts/lmbextractors',
        fetch: function () {
            this.url = Settings.url('rest/v2/lmbextractors/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'description': '',
            'identifier': '',
            'location': '',
            'refExtractor': {}
        },
        idAttribute: 'secId',

        postUrl: function () {
            return Settings.url('rest/v2/lmbextractors');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'LmbExtractorJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'LmbExtractor',

        importable: true
    });

    app.LmbExtractor.role = RolesMixin.LMBEXTRACTOR;

    app.LmbExtractorCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/lmbextractors');
        },
        model: app.LmbExtractor
    });

});

