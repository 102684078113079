define('runPcrValidationPlateWellView',[
    'module',
    'backbone.marionette',
    'pcrWellResultController',
    'entities/ns',
    'underscore'
], function (
    module,
    Marionette,
    PcrWellResultController,
    app,
    _
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<div class="wellres"></div><div class="result"></div>'),
        className: 'grid-well',
        regions: {
            result: '.result'
        },
        ui: {
            wellres: '.wellres'
        },
        modelEvents: {
            'selected': 'selected',
            'change': 'render'
        },
        onRender: function () {
            var result = this.model.getDisplay('VALIDATION').findWhere({refAssayResult: this.options.validationModel.get('assayResultVersion')});
            if (result) {
                var wellRes = this.model.getDisplay('OVAR').first();
                if (wellRes && wellRes.get('result')) {
                    this.ui.wellres.css('background-color', wellRes.get('result').get('color'));
                }
                PcrWellResultController.show(result, 'ValMini', this.getRegion('result'));
                this.$el.addClass('selected');
                this.$el.removeClass('disabled');
            } else {
                this.$el.addClass('disabled');
            }
        },
        events: {
            'dblclick': 'onItemDblClick',
            'click': 'onItemClick'
        },

        onItemClick: function () {
            clearTimeout(this.clickTimeout); // Annule le précédent timeout s'il existe
            this.clickTimeout = setTimeout(_.bind(function () {
                this.trigger('well:click', this.model);
            }, this), 300); // Retarde l'exécution de l'événement click de 300ms
        },

        onItemDblClick: function () {
            clearTimeout(this.clickTimeout); // Annule le timeout pour éviter l'exécution de l'événement click
            var PcrWellService = require('services/caccounts/pcrwells');
            PcrWellService.showDetails({model: this.model, cSelection: true, icRefKitsToDisplay: true});
        },
        selected: function (selected) {
            if (selected) {
                this.$el.addClass('selected');
            } else {
                this.$el.removeClass('selected');
            }
        }
    });
});

