define('qcListView',[
    'module',
    'backbone.marionette',
    'jquery',
    'underscore',
    'qcListListView',
    'jqgridView',
    'pcrWellResultController',
    'jqGridFormatter',
    'services/caccounts/displayModes',
    'services/caccounts/pcrwells',
    'services/caccounts/pcrwellresults',
    'wellUtils'
], function (
    module,
    Marionette,
    $,
    _,
    QcListListView,
    JqGridView,
    PcrWellResultController,
    JqGridFormatter,
    DisplayModeService,
    PcrWellService,
    PcrWellResultService,
    WellUtils
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<div class="col-xs-5 js-grid p-l-0 p-r-2"></div><div class="col-xs-7 js-graph p-r-2 p-l-0"></div>'),
        regions: {
            grid: '.js-grid',
            graph: '.js-graph'
        },
        serializeData: function () {
            return {};
        },
        onRender: function () {
            DisplayModeService.findByAssay(this.model.get('refAssay')).done(_.bind(function (displayMode) {
                this.displayMode = displayMode;

                var data = this.options.service.getDataForPcrKitLot(this.model);

                this.jqGridView = new JqGridView({
                    data: data,
                    pager: true,
                    filtersName: this.filtersName,
                    gridOptions: _.bind(this.gridOptions, this),
                    paginationObject: this.paginationObject,
                    extend: [{'setGroupHeaders': _.bind(this.jqGridViewSetGroupHeaders, this)}]
                });
                this.listenTo(this.jqGridView, 'click', _.bind(this.onClick, this));
                this.listenTo(this.jqGridView, 'loadComplete', _.bind(this.onLoadComplete, this));
                this.showChildView('grid', this.jqGridView);
            }, this));
        },
        onClick: function (obj) {
            var PcrWellService = require('services/caccounts/pcrwells');
            var well = PcrWellService.getModel({secId: parseInt(obj.rowId, 10)});
            well.fetch().done(function () {
                require(['pcrWellController'], function (PcrWellController) {
                    PcrWellController.showDetails({model: well});
                });
            });
        },
        jqGridViewSetGroupHeaders: function () {
            if (_.isEmpty(this.groups)) {
                return {};
            }
            var ret = {groupHeaders: []};
            _.each(this.groups, function (group) {
                ret.groupHeaders.push({
                    startColumnName: group.results[0],
                    numberOfColumns: group.results.length,
                    titleText: group.parent,
                    className: 'extend'
                });
            });
            return ret;
        },

        gridOptions: function (data) {
            var PcrKitLotService = require('services/caccounts/kitlotpcr');
            var options = {
                colModel: [
                    {
                        label: '',
                        name: 'secId',
                        key: true,
                        hidden: true
                    }, {
                        label: _.i18n('qc.run.date'),
                        name: 'run.period',
                        classes: 'displayPopover',
                        formatter: JqGridFormatter.dateFormatterFromSettings,
                        search: false,
                        width: 100
                    }, {
                        label: _.i18n('qc.run'),
                        name: 'run.name',
                        classes: 'displayPopover',
                        formatter: JqGridFormatter.defaultFormatter,
                        search: false,
                        width: 200
                    }, {
                        label: _.i18n('well.pos'),
                        name: 'pos',
                        classes: 'displayPopover',
                        formatter: JqGridFormatter.defaultFormatter,
                        search: false,
                        width: 40
                    }, {
                        label: _.i18n('well.sampleType'),
                        name: 'smpType',
                        classes: '',
                        formatter: JqGridFormatter.defaultFormatter,
                        search: data.service.getName() === PcrKitLotService.getName(),
                        width: 30,
                        index: 'qualityControl'
                    }, {
                        label: _.i18n('well.assay'),
                        name: 'refAssay',
                        classes: '',
                        formatter: JqGridFormatter.codeNameFormatter,
                        search: data.service.getName() === PcrKitLotService.getName(),
                        width: 100,
                        index: 'assayCode'
                    }],

                sidx: 'runNameDate',
                sord: 'desc',
                onSelectRow: _.bind(function (rowId) {
                    this.trigger('wellSelected', rowId);
                }, this)
            };

            var results = this.model.get('refAssay').get('configurations').chain()
                .filter(_.bind(function (config) {
                    if (this.model.get('refAssay').get('configurations').length === 1) {
                        return true;
                    }
                    return config.get('cyclers').contains(this.model.get('cycler'));
                }, this))
                .map(function (config) {
                    return config.get('displayConfigurations').models;
                })
                .flatten()
                .filter(function (displayConfiguration) {
                    return displayConfiguration.get('type') === 'PLATE';
                })
                .map(function (displayConfiguration) {
                    return displayConfiguration.get('results').models;
                })
                .flatten()
                .value();
            var max = results.length;
            var width = 20;
            var location = this.displayMode.findParameterForLocation('Well');
            var paramSplitted = location.get('parameter') && location.get('parameter').param ? location.get('parameter').param.split(';') : [];
            var isHorizontal = location.get('parameter') && location.get('parameter').type === 'HISTOGRAM' && paramSplitted.length > 11 && paramSplitted[11] === 'HORIZONTAL';
            if (!location.get('parameter') || location.get('parameter').type === 'DOT') {
                width = 24;
            } else {
                if (isHorizontal) {
                    if (max < 8) {
                        width = 60;
                    } else {
                        width = 40;
                    }
                }
            }

            this.groups = [];
            _.each(results, _.bind(function (result, j) {
                var target = result.get('assayConfigurationResult').get('target');
                var refMbAnaResGr = target.get('refMbAnaResGr');
                var mbAnaResValues = refMbAnaResGr.get('mbAnaRes').map(function (mbAnaRes) {
                    return mbAnaRes.get('code') + ':' + mbAnaRes.get('code');
                });

                // Prépare la valeur des options avec une valeur "any" au début
                var mbAnaRes = ':' + _.i18n('common.any') + ';' + mbAnaResValues.join(';');
                var displayGroup = {};
                if (!result.get('assayConfigurationResult').get('linkedAssayConfigurationResults').isEmpty()) {
                    displayGroup = {};
                    displayGroup.name = 'group_' + j + '_' + result.get('assayConfigurationResult').get('linkedAssayConfigurationResults').first().get('target').get('code');
                    displayGroup.size = result.get('assayConfigurationResult').get('linkedAssayConfigurationResults').length;
                }

                // Ajout d'une colonne avec des options spécifiques
                options.colModel.push({
                    label: target.get('name'),
                    name: target.get('code'),
                    labelClasses: 'rotated-text',
                    classes: '',
                    formatter: JqGridFormatter.defaultHtmlFormatter,
                    search: true,
                    sortable: !result.get('assayConfigurationResult').get('linkedAssayConfigurationResults').isEmpty(),
                    width: width,
                    fixed: true,
                    index: 'target-' + target.id,
                    stype: 'select',
                    searchrules: {select: true},
                    searchoptions: {
                        sopt: ['eq'],
                        value: mbAnaRes, // Valeur spécifique à cette colonne
                        clearSearch: false
                    },
                    displayGroup: displayGroup
                });
                if (!result.get('assayConfigurationResult').get('linkedAssayConfigurationResults').isEmpty()) {
                    var group = {parent: options.colModel[options.colModel.length - 1].name, results: []};
                    result.get('assayConfigurationResult').get('linkedAssayConfigurationResults').each(_.bind(function (res) {
                        var target = result.get('assayConfigurationResult').get('target');
                        var refMbAnaResGr = target.get('refMbAnaResGr');
                        var mbAnaResValues = refMbAnaResGr.get('mbAnaRes').map(function (mbAnaRes) {
                            return mbAnaRes.get('code') + ':' + mbAnaRes.get('code');
                        });

                        // Prépare la valeur des options avec une valeur "any" au début
                        var mbAnaRes = ':' + _.i18n('common.any') + ';' + mbAnaResValues.join(';');

                        options.colModel.push({
                            label: res.get('target').get('code'),
                            name: 'group_' + j + '_' + res.get('target').get('code'),
                            formatter: JqGridFormatter.defaultHtmlFormatter,
                            labelClasses: 'rotated-text extend',
                            search: true,
                            sortable: false,
                            width: width,
                            fixed: true,
                            frozen: true,
                            resizable: false,
                            index: 'target-' + res.get('target').id,
                            linkedTo: result.get('assayConfigurationResult').get('target').get('code'),
                            hidden: true,
                            stype: 'select',
                            searchrules: {select: true},
                            searchoptions: {
                                sopt: ['eq'],
                                value: mbAnaRes, // Valeur spécifique à cette colonne
                                clearSearch: false
                            }
                        });
                        group.results.push('group_' + j + '_' + res.get('target').get('code'));
                    }, this));
                    this.groups.push(group);
                }
            }, this));

            return options;
        },
        onLoadComplete: function (gridView, datas) {
            $('.js-global-loader').show();
            var grid = gridView.getGrid();
            var collection = PcrWellService.getCollection(datas);
            var defers = [];
            defers.push(PcrWellResultService.findResultByWells(collection));
            var assayVersions = collection.chain().map(function (well) {
                return well.get('assayVersion');
            }).uniq().value();
            _.each(assayVersions, function (assayVersion) {
                defers.push(assayVersion.fetch());
                defers.push(DisplayModeService.findByAssayVersion(assayVersion));
            });
            $.when.apply($, defers)
                .done(_.bind(function () {
                    var ids = grid.jqGrid('getDataIDs');
                    ids.forEach(function (id) {
                        var well = collection.chain().filter(function (well) {
                            return well.id === parseInt(id, 10);
                        })
                            .first()
                            .value();
                        var resultOvars = well.getDisplay('OVAR');
                        var template = resultOvars.map(function (result) {
                            return PcrWellResultController.generateContent(result, 'Val');
                        });
                        // get colModel
                        var colModel = grid.jqGrid('getGridParam', 'colModel');
                        // find col with "targetId" equal to "target.id"
                        var colTarget = colModel.filter(function (col) {
                            return col.index && col.index.startsWith('target-') && parseInt(col.index.replace('target-', ''), 10) === resultOvars.first().get('refAssayResult').get('target').id;
                        });
                        _.each(colTarget, function (col) {
                            grid.jqGrid('setCell', id, col.name, template.join(', '));
                        });
                        // reject colTarget from colModel
                        colModel = _.reject(colModel, function (col) {
                            return col.index && col.index.startsWith('target-') && parseInt(col.index.replace('target-', ''), 10) === resultOvars.first().get('refAssayResult').get('target').id;
                        });
                        var results = well.getResults();
                        _.each(colModel, function (col) {
                            if (col.index && col.index.startsWith('target-')) {
                                // get the result from results
                                var targetId = parseInt(col.index.replace('target-', ''), 10);
                                var result = results.find(function (result) {
                                    return result.get('refAssayResult').get('target').id === targetId;
                                });
                                if (result) {
                                    grid.jqGrid('setCell', id, col.name, PcrWellResultController.generateContent(result, 'Val'));
                                }
                            }
                        });
                    });
                    this.setWellListPopover(gridView, datas);
                }, this))
                .always(function () {
                    $('.js-global-loader').hide();
                });
        },
        setWellListPopover: function (jqGridView, datas) {
            var wellSecIds = _.pluck(datas, 'secId');
            var wells = PcrWellService.getCollection(wellSecIds);
            wells.fetch().done(_.bind(function () {
                _.each(datas, _.bind(function (data) {
                    var well = wells.get(data.secId);
                    if (!well) {
                        return;
                    }
                    var popovers = jqGridView.$el.find('tr#' + data.secId + ' .displayPopover');
                    if (popovers.length < 1) {
                        return;
                    }

                    var params = {
                        model: well
                    };

                    params.targets = popovers;

                    // if ((well.get('wellRes') && well.get('wellRes').get('result') && well.get('wellRes').get('result').get('code')) &&
                    //     (well.get('wellRes') && well.get('wellRes').get('quantificationFormatted'))) {
                    //     params.targets.push($('.ovrerallResultPopover-' + well.get('pos')));
                    // }
                    var placement = 'right';
                    var index = _.indexOf(datas, data);
                    if (index < 3) {
                        placement = 'bottom';
                    } else if (index > datas.length - 4) {
                        placement = 'top';
                    }
                    params.placement = placement;
                    WellUtils.showPcrWellPopover(params);
                }, this));
            }, this));
        }
    });
});
