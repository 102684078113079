define('entities/caccounts/pcrreagents',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.PcrReagent = Backbone.RelationalModel.extend({
        service: 'services/caccounts/pcrreagents',
        fetch: function () {
            this.url = Settings.url('rest/v2/pcr/reagents/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': undefined,
            'name': undefined,
            'description': undefined,
            'type': undefined,
            'shortName': undefined
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'kit',
            relatedModel: 'KitPcr',
            includeInJSON: ['secId', 'code', 'name']
        }],

        postUrl: function () {
            return Settings.url('rest/v2/pcr/reagents');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },
        getPDF: function () {
            return Settings.getDocUrl('PcrReagentJson.pdf');
        },
        jsonObjectName: 'PcrReagentJson'
    });

    app.PcrReagent.role = RolesMixin.KIT_PCR;

    app.PcrReagentCollection = Backbone.Collection.extend({
        model: app.PcrReagent,
        comparator: 'type'
    });
});

