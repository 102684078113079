define('entities/caccounts/standardCurve/standardCurveAssayConfigurationResultValuePcrWellValues',[
    'entities/ns',
    'backbone',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/pcrwells',
    'entities/caccounts/pcrruns'
], function (
    app,
    Backbone,
    RolesMixin
) {
    'use strict';

    app.StandardCurveAssayConfigurationResultValuePcrWellValue = Backbone.RelationalModel.extend({
        defaults: {
            'pcrWell': null,
            'ct': 0,
            'log': 0
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'pcrWell',
            relatedModel: 'PcrWell'
        }],
        getImageUid: function () {
        }
    });

    app.StandardCurveAssayConfigurationResultValuePcrWellValue.role = RolesMixin.STANDARDCURVE;

    app.StandardCurveAssayConfigurationResultValuePcrWellValueCollection = Backbone.Collection.extend({
        model: app.StandardCurveAssayConfigurationResultValuePcrWellValue
    });
});

