define('qcListListView',[
    'module',
    'backbone.marionette',
    'underscore',
    'jquery',
    'jqgridView',
    'template!qcListListView'
], function (
    module,
    Marionette,
    _,
    $,
    JqGridView,
    viewTpl
) {
    'use strict';

    module.exports = JqGridView.extend({
        template: viewTpl,
        filtersName: 'wellQCListFilters',

        ui: function () {
            return _.extend({
                jqGrid: '#jq-grid-listQC',
                jqGridPager: '#jq-grid-pager-listQC',
                jqgRow: 'tr.jqgrow',
                blockLoading: '.empty-page-loader'
            }, this.gridUi);
        },

        events: function () {
            return _.extend({}, this.gridEvents);
        },
        
        attributes: {
            style: 'width:730px;height:100%;'
        },

        onGridLoaded: function () {
            this.trigger('onGridLoaded', this);
        },

        serializeData: function () {
            return {};
        },

        pagination: function () {
            return {
                first: 0,
                rowCount: 0,
                rows: 18,
                currentPage: 1
            };
        }
    });
});

