define('menuCheck',[
    'module',
    'backbone.marionette',
    'underscore',
    'menuCheckItem'
], function (
    module,
    Marionette,
    _,
    ItemView
) {
    'use strict';

    /**
     * @class QuickActionCollectionView
     * @extends Marionette.CollectionView
     * @param {Object} options
     * @param {Object} options.collection
     * @param {Object} options.collection.items
     *
     * collection {
     *     items: Backbone.Collection
     * }
     */
    module.exports = Marionette.CollectionView.extend({
        template: _.template('<div class="name" title="<%= title %>"><%= name %></div><div class="buttons"></div>'),
        className: 'menu-check',
        childView: ItemView,
        childViewContainer: '.buttons',
        initialize: function () {
            this.collection = this.model.get('items');
        },
        serializeData: function () {
            return {
                title: this.model.get('nameCode') ? _.i18n(this.model.get('nameCode') + '.popover') : '',
                name: this.model.get('nameCode') ? _.i18n(this.model.get('nameCode')) : ''
            };
        },
        reset: function () {
            this.children.each(function (child) {
                child.defineSelected(false);
            });
        },
        onRender: function () {
            if (this.options.linkFilter) {
                this.children.each(_.bind(function (child) {
                    if (this.options.linkFilter.includes(child.model.get('nameCode'))) {
                        child.$el.click();
                    }
                }, this));
            }
        },
        childViewEvents: {
            'selected': function (model, selected) {
                if (this.options.linkFilter) {
                    if (selected) {
                        // add item if not already in the list
                        if (!this.options.linkFilter.includes(model.get('nameCode'))) {
                            this.options.linkFilter.push(model.get('nameCode'));
                        }
                    } else {
                        this.options.linkFilter = _.without(this.options.linkFilter, model.get('nameCode'));
                    }
                }
            }
        }
    });
});

