define('services/admin/codelistelement',[
    'entities/ns',
    'underscore',
    'jquery',
    'module',
    'dynamic',
    'entities/admin/codelistelement'
], function (
    app,
    _,
    $,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.CodeListElement,
                collection: app.CodeListElementCollection
            };
        },
        getName: function () {
            return 'codelistelement';
        },
        getDynamicJson: function () {
            return 'CodeListElementJson';
        },
        getCreateEditLine: function () {
            return [
                {'field': 'code', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-20 m-l-5'}}},
                {'field': 'name', 'param': {'type': 'TEXTAREA', 'display': {'class': 'col-w-40 m-l-5'}}},
                {'field': 'seq', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-5 m-l-5', maxLength: 3}}},
                {'field': 'description', 'param': {'type': 'TEXTAREA', 'display': {'class': 'col-w-40 m-l-5'}}}
            ];
        },
        getTypeDynamicJson: function () {
            return 'ADMIN';
        }
    }, Dynamic);
});

