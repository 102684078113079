define('entities/caccounts/lisRecordQueries',[
    'entities/ns',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    $,
    _,
    RolesMixin
) {
    'use strict';

    app.LisRecordQuery = Backbone.RelationalModel.extend({
        service: 'services/caccounts/lisRecordQueries',
        defaults: {
            createdDate: null,
            result: {},
            query: '',
            responseDate: null,
            responseType: null
        },
        idAttribute: 'secId',

        jsonObjectName: 'LisRecordQueryJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'LisRecordQuery',

        importable: false,
        retry: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/export/result/ASTM/' + this.get('lisRecord').get('secId') + '/' + this.get('secId') + '/retry');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('lisRecord').fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        }
    });

    app.LisRecordQuery.role = RolesMixin.LIS;

    app.LisRecordQueryCollection = Backbone.Collection.extend({
        model: app.LisRecordQuery
    });

});

