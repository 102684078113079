define('services/caccounts/displayModes',[
    'entities/ns',
    'underscore',
    'jquery',
    'module',
    'dynamic',
    'entities/caccounts/displayModes',
    'entities/caccounts/displayModeConfigurations'
], function (
    app,
    _,
    $,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.DisplayMode,
                collection: app.DisplayModeCollection
            };
        },
        getName: function () {
            return 'displaymode';
        },
        getUrl: function () {
            return 'rest/v2/displayModes/';
        },
        getDynamicJson: function () {
            return 'DisplayModeJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('kitProts'),
                name: 'kitProts',
                classes: 'dynamic-link',
                formatter: viewDynamic.codeNameListFormatter,
                search: false,
                index: 'kitProts',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('common.tag'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push({
                'field': 'kitProts',
                'param': {
                    'type': 'LIST2ENTITY'
                }
            });
            return custom;
        },
        getDefaultDisplayMode: function () {
            return app.DisplayMode.findOrCreate({
                'secId': 'default',
                'code': 'default',
                'name': 'Default',
                'displayTooltip': 'LIST',
                'displayValidation': 'TARGET',
                'description': 'Default display mode',
                'configurations': [{
                    'parameter': {
                        'type': 'DOT'
                    },
                    'locations': ['Plate', 'Well', 'Val', 'WellList', 'TargetList', 'ValMini', 'WellTarget', 'TooltipTarget', 'WellCurve', 'Tooltip']
                }, {
                    'parameter': {
                        'type': 'HISTOGRAM',
                        'param': 'NORMAL;0.0;45.0;20.0;42.0;30.0;;0.2;0.6'
                    },
                    'locations': ['Tooltip']
                }]
            });
        },
        findByAssay: function (assay) {
            var deferred = $.Deferred();
            $.ajax({
                url: 'rest/v2/displayModes/findByAssay/' + assay.id,
                type: 'GET',
                contentType: 'application/json',
                success: function (item) {
                    deferred.resolve(app.DisplayMode.findOrCreate(item));
                },
                error: function () {
                    deferred.reject();
                }
            });
            return deferred.promise();
        },
        findByAssayVersion: function (assayVersion) {
            var deferred = $.Deferred();
            $.ajax({
                url: 'rest/v2/displayModes/findByAssayVersion/' + assayVersion.id,
                type: 'GET',
                contentType: 'application/json',
                success: function (item) {
                    deferred.resolve(app.DisplayMode.findOrCreate(item));
                },
                error: function () {
                    deferred.reject();
                }
            });
            return deferred.promise();
        }
    }, Dynamic);
});

