define('entities/ns',[
    'backbone',
    'underscore',
    'module',
    'backboneRelational'
], function (
    Backbone,
    _,
    module
) {
    'use strict';

    var app = _.first(Backbone.Relational.store._modelScopes);
    if (!app) {
        app = {};
        Backbone.Relational.store.addModelScope(app);
    }

    module.exports = app;
});
