
define('template!pcrWellResultIconView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="containerIconResult ' +
((__t = ( ic ? 'ic': '')) == null ? '' : __t) +
'">\n    <div class="iconResult">\n        <span class="mdi ' +
((__t = ( mdi )) == null ? '' : __t) +
'" style="color: ' +
((__t = ( color )) == null ? '' : __t) +
';"></span>\n        ';
 if (valSt >= 2 && valSt < 3) { ;
__p += '\n        ';
 } else if (valSt === 3.1) { ;
__p += '\n        <span class="mdi mdi-school"></span>\n        ';
 } else if (valSt === 3.2) { ;
__p += '\n        <div class="validation1"></div>\n        ';
 } else if (valSt >= 4 && valSt < 5) { ;
__p += '\n        <div class="validation2"></div>\n        ';
 } else if (valSt === 5) { ;
__p += '\n        <span class="mdi mdi-check"></span>\n        ';
 } ;
__p += '\n        ';
 if (valSt !== 3.1) { ;
__p += '\n        ';
 if (resCodeErr === 'E' || resCodeErr === 'W') { ;
__p += '\n        <span class="mdi mdi-alert"></span>\n        ';
 } else if (repeatSt !== null && repeatSt !== undefined && valSt <= 5 && valSt > 0) { ;
__p += '\n        <span class="mdi mdi-triangle mdi-flip-v mdi-rotate-90"></span>\n        ';
 } else if (!resCodeErr && resCommentErrAuto) { ;
__p += '\n        <span class="mdi mdi-alert little"></span>\n        ';
 } ;
__p += '\n        ';
 } ;
__p += '\n    </div>\n</div>\n';
 if(displayCode) { ;
__p += '\n<div class="result">' +
((__t = ( result )) == null ? '' : __t) +
'</div>\n';
 } ;
__p += '\n';
 if(displayCt) { ;
__p += '\n<div class="cts">' +
((__t = ( cts )) == null ? '' : __t) +
' ' +
((__t = ( _.isEmpty(quantifications) ? '' : '(' + quantifications + ')' )) == null ? '' : __t) +
'</div>\n';
 } ;


}
return __p
};});

