define('entities/caccounts/assayreagent',[
    'entities/ns',
    'backbone',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/pcrreagents',
    'entities/caccounts/kitspcr'
], function (
    app,
    Backbone,
    RolesMixin
) {
    'use strict';

    app.AssayReagent = Backbone.RelationalModel.extend({
        service: 'services/caccounts/assayreagent',
        defaults: {
            'reagent': null,
            'volumeByTest': 0.0,
            'volumeDeath': 0.0,
            'refAssay': null
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'reagent',
            relatedModel: 'PcrReagent',
            includeInJSON: ['secId', 'code', 'name']
        }, {
            type: Backbone.HasOne,
            key: 'refAssay',
            relatedModel: 'Assay',
            includeInJSON: ['secId', 'code', 'name']
        }]
    });

    app.AssayReagent.role = RolesMixin.ASSAY;

    app.AssayReagentCollection = Backbone.Collection.extend({
        model: app.AssayReagent
    });
});

