define('services/caccounts/pcrSetup/pcrRunLite',[
    'entities/ns',
    'module',
    'entities/caccounts/pcrSetup/pcrRunLite'
], function (
    app,
    module
) {
    'use strict';

    module.exports = {
        create: function (params) {
            if (!params) {
                params = {};
            }
            return new app.PcrRunLite(params);
        }
    };
});
