define('services/caccounts/pcrruns',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'moment',
    'customBootboxMessage',
    'module',
    'dynamic',
    'backbone.marionette',
    'renderer',
    'template!pcrRunStatus',
    'template!pcrRunValStatus',
    'pcrRunCustomJqGridView',
    'app',
    'entities/caccounts/pcrruns'
], function (
    app,
    Settings,
    $,
    _,
    moment,
    CustomBootboxMessage,
    module,
    Dynamic,
    Marionette,
    Renderer,
    PcrRunStatusTemplate,
    PcrRunValStatusTemplate,
    PcrRunCustomJqGridView,
    App
) {
    'use strict';

    module.exports = _.defaults({

        // A finir
        getNamespace: function () {
            return {
                model: app.PcrRun,
                collection: app.PcrRunCollection
            };
        },

        getUrl: function () {
            return 'rest/v2/runs/pcr/';
        },

        getName: function () {
            return 'pcrrun';
        },
        getDynamicJson: function () {
            return 'PcrRunJson';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig.call(this);
            config.exportable = false;
            config.importable = false;
            config.duplicable = false;
            config.customLeft = new PcrRunCustomJqGridView();
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('runList.status'),
                name: 'status',
                formatter: _.bind(this.statusFormatter, this),
                search: true,
                searchoptions: {clearSearch: false, size: 2},
                index: 'status',
                fixed: true,
                width: 60
            }, {
                label: _.i18n('runList.period'),
                name: 'periodFormatted',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'periodFormatted',
                fixed: true,
                width: 70
            }, {
                label: _.i18n('runList.group'),
                name: 'group',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'group',
                fixed: true,
                width: 50
            }, {
                label: _.i18n('user'),
                name: 'refRespUser',
                formatter: viewDynamic.userFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'refRespUser.nickName',
                fixed: true,
                width: 75
            }, {
                label: _.i18n('plateSize'),
                name: 'plateSize',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'plateSize',
                fixed: true,
                width: 50
            }, {
                label: _.i18n('runList.name'),
                name: 'code',
                classes: 'css-run-listView-accentuation',
                formatter: _.bind(this.runNameFormatter, this),
                search: true,
                searchoptions: {clearSearch: false},
                index: 'code',
                fixed: true,
                width: 160
            }, {
                label: _.i18n('runList.valStatus'),
                name: 'valStatus',
                formatter: _.bind(this.valStatusFormatter, this),
                search: true,
                searchoptions: {clearSearch: false},
                index: 'valStatus',
                fixed: true,
                width: 60
            }, {
                label: _.i18n('runList.assays'),
                name: 'assays',
                formatter: viewDynamic.codeNameListFormatter,
                sortable: false,
                search: true,
                index: 'assays',
                searchoptions: {size: 40, clearSearch: false},
                fixed: true,
                width: 200
            }, {
                label: _.i18n('runList.refKitProt'),
                name: 'refKitProt.code',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'refKitProt.code',
                fixed: true,
                width: 160
            }, {
                label: _.i18n('runList.refCycler'),
                name: 'refCycler.code',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'cycler.code',
                fixed: true,
                width: 90
            }, {
                label: _.i18n('runList.refLmbCycler'),
                name: 'refLmbCycler.code',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'refLmbCycler.code',
                fixed: true,
                width: 120
            }, {
                label: _.i18n('runList.plateIdPC'),
                name: 'plateIdPC',
                formatter: this.plateIdFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'plateIdPC',
                fixed: true,
                width: 110
            }, {
                label: _.i18n('common.comment'),
                name: 'comment',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'comment'
            });
            return columns;
        },
        getJqGridSidx: function () {
            return 'id';
        },
        getJqGridSord: function () {
            return 'desc';
        },
        getJqGridSelectRow: function (model) {
            App.navigate('runs/pcr/' + model.get('secId'), {trigger: true});
        },
        plateIdFormatter: function (cellValue, options) {
            var idMaxLength = 12;
            var value = '', sub = 0;
            if (cellValue) {
                if (cellValue.length > idMaxLength) {
                    sub = cellValue.length - idMaxLength;
                    value = '...';
                }
                value += cellValue.substr(sub);
            }
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + value + '</span>';
        },
        runNameFormatter: function (cellValue, options) {
            return '<span class="cell-default" style="font-weight: bold; " data-row-id="' +
                options.rowId + '">' + _.escape(cellValue) + '</span>';
        },
        valStatusFormatter: function (cellValue, options, object) {
            var status = parseInt(object.status, 10);
            var valStatus = object.valStatus;
            var iconColor = [];
            var statusIcon = '';

            statusIcon += '<div>';
            statusIcon += '<span class="jqgrid-statusNb">' + valStatus + '</span>';
            var renderer = new Renderer({
                template: PcrRunValStatusTemplate,
                templateData: {
                    current: valStatus
                }
            });
            renderer.render();
            var content = renderer.$el.html();

            statusIcon += '<div class="cell-default popover-status" style="display: flex; top: 2px;\n' +
                '            position: relative;" data-row-id="' + options.rowId + '" data-content="' + _.escape(content) + '">';

            var valStatusArray = valStatus.split('');
            for (var i = 1; i <= 5; i++) {
                iconColor[i] = '';
                if (status >= i) {
                    iconColor[i] = 'statusIcon-upTo';
                }
                if (valStatusArray.includes(i.toString())) {
                    iconColor[i] = 'statusIcon-toDo';
                }
                if (i === 5 && valStatusArray.includes(i.toString()) && status === 5) {
                    iconColor[i] = 'statusIcon-upTo';
                }
                statusIcon += '<div class="jqgrid-valStatusIcon ' + iconColor[i] + '"></div>';
            }
            statusIcon += '</div>';
            statusIcon += '</div>';
            return statusIcon;
        },
        statusFormatter: function (cellValue, options) {
            var status = cellValue ? parseInt(cellValue, 10) : ' ';
            var iconColor = [];
            var statusIcon = '';

            statusIcon += '<div>';
            statusIcon += '<span class="jqgrid-statusNb">' + status + '</span>';

            var renderer = new Renderer({
                template: PcrRunStatusTemplate,
                templateData: {
                    current: status
                }
            });
            renderer.render();
            var content = renderer.$el.html();

            statusIcon += '<div class="cell-default popover-status" style="display: flex; top: 2px;\n' +
                '            position: relative;" data-toggle="popover" data-row-id="' + options.rowId + '" data-content="' + _.escape(content) + '">';

            for (var i = 1; i <= 5; i++) {
                iconColor[i] = '';
                if (status >= i) {
                    iconColor[i] = 'statusIcon-upTo';
                }
                statusIcon += '<div class="jqgrid-statusIcon' + i + ' ' + iconColor[i] + '"></div>';
            }
            statusIcon += '</div>';
            statusIcon += '</div>';
            return statusIcon;
        },
        getRoute: function () {
            return '#runs/pcr';
        },
        generateRunSheet: function (secIds) {
            if (!_.isArray(secIds)) {
                secIds = [secIds];
            }
            var url = Settings.url('rest/v2/runs/pcr/report/PCR_RUN_SHEET', {secIds: secIds});
            App.downloadFile(url);
        },
        generatePcrSetup: function (secIds) {
            if (!_.isArray(secIds)) {
                secIds = [secIds];
            }
            var url = Settings.url('rest/v2/runs/pcr/report/PCR_SETUP', {secIds: secIds});
            App.downloadFile(url);
        },
        generatePcrSetupMMX: function (secIds) {
            if (!_.isArray(secIds)) {
                secIds = [secIds];
            }
            var url = Settings.url('rest/v2/runs/pcr/report/PCR_SETUP_MMX', {secIds: secIds});
            App.downloadFile(url);
        },
        
        getAutocompleteParamForColorCompensation: function (config, context) {
            var configuration = Dynamic.getAutocompleteParam(config, context);
            configuration.canAddOrEdit = false;
            configuration.valueProperty = config.valueProperty ? config.valueProperty : 'code';
            configuration.queryProperty = config.queryProperty ? config.queryProperty : 'code';
            configuration.urlPath = context.getUrl() + '?' + Settings.generateParamUrl((config.paramUrl ? config.paramUrl : {
                'sord': 'desc',
                'sidx': 'creatDate',
                withColorCompensation: true
            }));
            configuration.valueKey = config.valueKey ? config.valueKey : 'code';
            return configuration;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 450, 'fields': ['plateIdPC', 'refCycler.code', 'refLmbCycler.code']};
        },

        // create from popup
        importXmlFile: function (fileInfo, lmbCycler, kitProt) {
            var defer = $.Deferred(),
                formdata = new FormData(),
                param = {};

            if (lmbCycler) {
                param.lmbCyclerSecId = lmbCycler.get('secId');
            }
            if (kitProt) {
                param.kitProtSecId = kitProt.get('secId');
            }

            var url = Settings.url('rest/v2/runs/pcr/', param);

            _.each(fileInfo, function (file) {
                formdata.append('file', file);
            });

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: function (resp) {
                    defer.resolve(resp);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        filesToImport: function () {

            var url = Settings.url('rest/import/result/files'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                }
            });

            return defer.promise();
        },

        createRunFromResult: function (model) {
            var defer = $.Deferred(),
                formdata = new FormData(),
                param = {
                    cyclerSecId: model.get('cycler').get('secId'),
                    respUserSecId: model.get('respUser').get('secId')
                };

            var url = Settings.url('rest/v2/runs/pcr/fromResult', param);

            _.each(model.get('files'), function (file) {
                formdata.append('file', file);
            });

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: function (resp) {
                    defer.resolve(resp);
                },
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },

        getAll: function (params) {
            var url = Settings.url('rest/v2/runs/pcr/', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (runs) {
                    var runsList = [];
                    _.each(runs, function (run) {
                        runsList.push(run.secId);
                    });
                    defer.resolve(runsList);
                }
            });

            return defer.promise();
        },

        createFromPrep: function (json) {
            var url = Settings.url('rest/v2/runs/pcr/createFromPrep'),
                defer = $.Deferred();

            json.cyclerPublicSecId = json.refCycler.secId;
            json.assaySecId = json.refAssay.secId;
            json.pcrRunTemplateSecId = json.pcrRunTemplate.secId;
            json.respUserSecId = json.respUser ? json.respUser.secId : null;

            $.ajax({
                method: 'post',
                url: url,
                data: JSON.stringify(json),
                contentType: 'application/json',
                dataType: 'json',
                success: function (resp) {
                    defer.resolve(resp);
                }
            });
            return defer.promise();
        },

        plateLayoutToImport: function () {

            var url = Settings.url('rest/import/plateLayoutPcr/files'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                }
            });

            return defer.promise();
        },

        deleteFileRemote: function (fileName) {

            var url = Settings.url('rest/import/result/file', {fileName: fileName}),
                defer = $.Deferred();

            $.ajax({
                method: 'delete',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                }
            });

            return defer.promise();
        },


        createRunFromPlateLayout: function (model) {
            var defer = $.Deferred(),
                formdata = new FormData(),
                param = {
                    fileFormatSecId: model.get('fileFormat').get('secId')
                };

            var url = Settings.url('rest/v2/runs/pcr/fromPlateLayout', param);

            formdata.append('file', model.get('files')[0]);

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: function (resp) {
                    defer.resolve(resp);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },
        createRunFromSampleList: function (model) {
            var defer = $.Deferred(),
                formdata = new FormData();

            var param = {
                fileFormatSecId: model.get('fileFormat').get('secId'),
                startPos: model.get('wellSelection').get('start'),
                plateFillDirection: model.get('wellSelection').get('direction')
            };

            var url = Settings.url('rest/v2/runs/pcr/fromSampleList', param);

            formdata.append('file', model.get('files')[0]);

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: function (resp) {
                    defer.resolve(resp);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        }
    }, Dynamic);
});

