define('services/caccounts/lisBioGroups',[
    'entities/ns',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/lisBioGroups'
], function (
    app,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.LisBioGroup,
                collection: app.LisBioGroupCollection
            };
        },
        getName: function () {
            return 'lisBioGroup';
        },
        getUrl: function () {
            return 'rest/v2/lisBioGroups/';
        },
        getDynamicJson: function () {
            return 'LisBioGroupJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('lisBioGroup.lis'),
                name: 'lis',
                formatter: viewDynamic.codeNameFormatter,
                search: true,
                index: 'lis',
                width: 40
            }, {
                label: _.i18n('common.code'),
                name: 'code',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 50
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                width: 80
            }, {
                label: _.i18n('lisbiogroup.biogroup'),
                name: 'bioGroup',
                formatter: viewDynamic.codeNameFormatter,
                search: true,
                index: 'bioGroup.code',
                width: 80
            }, {
                label: _.i18n('common.criterias'),
                name: 'criterias',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: true,
                index: 'criterias',
                width: 40
            }, {
                label: _.i18n('common.tags'),
                name: 'tags',
                formatter: viewDynamic.defaultListFormatter,
                search: true,
                sortable: false,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            }, {
                label: _.i18n('common.comment'),
                name: 'comment',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'comment'
            });
            return columns;
        },
        getCreateEditFieldValidate: function () {
            var array = Dynamic.getCreateEditFieldValidate();
            array.push({name: 'lis', type: 'required'});
            array.push({name: 'bioGroup', type: 'required'});
            return array;
        },
        defineDefaultValue: function (model) {
            var defer = $.Deferred();
            var LisService = require('services/caccounts/lis');
            LisService.findDefault().done(function (value) {
                if (value && value.get('secId')) {
                    model.get('lisSet').push(value);
                }
                defer.resolve();
            });
            return defer.promise();
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push({field: 'lis', param: {display: {'class': 'col-xs-3 p-r-0'}}});
            custom.push({field: 'bioGroup', param: {display: {'class': 'col-xs-3 p-r-0'}}});
            return custom;
        },
        getCreateEditLine: function () {
            return [
                {'field': 'code', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-5'}}}
            ];
        },
        getListConfiguration: function () {
            var defer = $.Deferred();
            var configuration = Dynamic.getListConfiguration.call(this);
            configuration.done(function (resp) {
                var UserCodeListService = require('services/caccounts/userCodelist');
                resp.push({fieldName: 'criterias', values: UserCodeListService.findItemsByCode('FCRIT_LISBIOGR'), codeListCode: 'FCRIT_LISBIOGR'});
                defer.resolve(resp);
            });
            return defer.promise();
        }
    }, Dynamic);
});

