define('services/admin/translations',['module',
        'entities/ns',
        'entities/admin/translations'
    ],
    function (module,
              app) {
        'use strict';

        module.exports = {
            getModel: function (json) {
                if (!json) {
                    json = {};
                }
                return app.TranslationItem.findOrCreate(json);
            },
            getCollection: function (json) {
                if (!json) {
                    json = {};
                }
                return new app.TranslationCollection(json);
            }
        };

    });
