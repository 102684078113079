
define('template!assayConfigurationDisplayView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div>\n    <label class="control-label noWrapHidden">' +
((__t = ( _.i18n('assayConfiguration.display.ovar') )) == null ? '' : __t) +
'</label><br/>\n    <div class="js-display-OVAR-region"></div>\n</div>\n<div>\n    <label class="control-label noWrapHidden">' +
((__t = ( _.i18n('assayConfiguration.display.plate') )) == null ? '' : __t) +
'</label><br/>\n    <div class="js-display-PLATE-region"></div>\n</div>\n<div>\n    <label class="control-label noWrapHidden">' +
((__t = ( _.i18n('assayConfiguration.display.well') )) == null ? '' : __t) +
'</label><br/>\n    <div class="js-display-WELL-region"></div>\n</div>\n<div>\n    <label class="control-label noWrapHidden">' +
((__t = ( _.i18n('assayConfiguration.display.validation') )) == null ? '' : __t) +
'</label><br/>\n    <div class="js-display-VALIDATION-region"></div>\n</div>\n<div>\n    <label class="control-label noWrapHidden">' +
((__t = ( _.i18n('assayConfiguration.display.wellList') )) == null ? '' : __t) +
'</label><br/>\n    <div class="js-display-WELL_LIST-region"></div>\n</div>\n<div>\n    <label class="control-label noWrapHidden">' +
((__t = ( _.i18n('assayConfiguration.display.targetList') )) == null ? '' : __t) +
'</label><br/>\n    <div class="js-display-TARGET_LIST-region"></div>\n</div>\n<div>\n    <label class="control-label noWrapHidden">' +
((__t = ( _.i18n('assayConfiguration.display.contamination') )) == null ? '' : __t) +
'</label><br/>\n    <div class="js-display-CONTAMINATION-region"></div>\n</div>\n<div class="cancelConfirmRow">\n    <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n    <button disabled class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n</div>';

}
return __p
};});

