define('sampleCustomJqGridView',[
    'module',
    'dialogFormView',
    'underscore',
    'jquery',
    'template!sampleCustomJqGridView'

], function (
    module,
    DialogFormView,
    _,
    $,
    tpl
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: tpl,

        events: {
            'click .js-recalcSampleClass': 'onRecalcSampleClass',
            'click .js-refreshValidation': 'onRefreshValidation'
        },

        attributes: {
            style: 'display: flex; column-gap: 5px;'
        },

        onRecalcSampleClass: function () {
            var parent = this._parent._parent._parent;
            var checkedRowsList = parent.getCheckedRowsList();
            if (checkedRowsList && checkedRowsList.length > 0) {
                $('.js-global-loader').show();
                var secIds = [];
                _.each(checkedRowsList, function (row) {
                    secIds.push(row.secId);
                });

                var SampleService = require('services/caccounts/samples');
                SampleService.recalcSampleClassByList(secIds)
                    .done(_.bind(function () {
                        parent.trigger('reloadGrid');
                    }, this))
                    .always(
                        _.bind(function () {
                            parent.resetCheckedRowList();
                            $('.js-global-loader').hide();
                        }, this));
            }
        },
        onRefreshValidation: function () {
            var parent = this._parent._parent._parent;
            var checkedRowsList = parent.getCheckedRowsList();
            if (checkedRowsList && checkedRowsList.length > 0) {
                $('.js-global-loader').show();
                var secIds = [];
                _.each(checkedRowsList, function (row) {
                    secIds.push(row.secId);
                });

                var SampleService = require('services/caccounts/samples');
                SampleService.refreshValidationByList(secIds)
                    .done(_.bind(function () {
                        parent.trigger('reloadGrid');
                    }, this))
                    .always(
                        _.bind(function () {
                            parent.resetCheckedRowList();
                            $('.js-global-loader').hide();
                        }, this));
            }
        }

    });
});
