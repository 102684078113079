define('entities/caccounts/prepruntemplates',[
    'entities/ns',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/prepwelltemplates'
], function (
    app,
    Backbone,
    Settings,
    $,
    _,
    RolesMixin
) {
    'use strict';

    app.PrepRunTemplate = Backbone.RelationalModel.extend({
        service: 'services/caccounts/prepruntemplates',
        url: function () {
            return Settings.url('rest/v2/runtemplates/prep/' + this.get('secId'));
        },
        defaults: {
            'code': '',
            'name': '',
            'plateSize': '',
            'comment': '',
            'description': '',
            'group': '',
            'periodFormat': '',
            'pattern': '',
            'settings': null,
            'printRunSheet': true,
            'type': '',
            'seq': '',
            'wells': undefined,
            pool: false,
            tags: []
        },
        idAttribute: 'secId',

        relations: [{
            type: Backbone.HasMany,
            key: 'wells',
            relatedModel: 'PrepWellTemplate',
            collectionType: 'PrepWellTemplateCollection'
        }],

        getType: function () {
            return 'PrepRunTemplate';
        },
        getUrl: function () {
            return 'rest/v2/runtemplates/prep/';
        },


        postUrl: function () {
            return Settings.url('rest/v2/runtemplates/prep/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },
        getPDF: function () {
            return Settings.getDocUrl('PrepRunTemplateJson.pdf');
        },

        getLine: function () {
            return this.get('plateSize').substring(0, 1);
        },
        getColumn: function () {
            return parseInt(this.get('plateSize').substring(1), 10);
        },

        applyAssayWells: function (model) {
            var url = Settings.url('rest/v2/runtemplates/prep/' + this.get('secId') + '/wells/assay/add', {
                assaySecId: model.get('assay').get('secId'),
                from: model.get('wellSelection').get('start'),
                to: model.get('wellSelection').get('to'),
                direction: model.get('wellSelection').get('direction')
            }), defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        applyTestWells: function (model) {
            var url = Settings.url('rest/v2/runtemplates/prep/' + this.get('secId') + '/wells/test/add', {
                from: model.get('wellSelection').get('start'),
                to: model.get('wellSelection').get('to'),
                direction: model.get('wellSelection').get('direction')
            }), defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        clearAssay: function (model) {
            var params = {
                assaySecId: model.get('assay').get('secId'),
                from: model.get('wellSelection').get('start'),
                to: model.get('wellSelection').get('to'),
                direction: model.get('wellSelection').get('direction')
            };

            var url = Settings.url('rest/v2/runtemplates/prep/' + this.get('secId') + '/wells/assay/clear', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        replaceAssayWells: function (model) {
            var params = {
                assayReplaceSecId: model.get('assay').get('secId'),
                assayNewSecId: model.get('newAssay').get('secId'),
                from: model.get('wellSelection').get('start'),
                to: model.get('wellSelection').get('to'),
                direction: model.get('wellSelection').get('direction')
            };

            var url = Settings.url('rest/v2/runtemplates/prep/' + this.get('secId') + '/wells/assay/replace', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        removeTest: function (model) {
            var params = {
                from: model.get('wellSelection').get('start'),
                to: model.get('wellSelection').get('to'),
                direction: model.get('wellSelection').get('direction')
            };

            var url = Settings.url('rest/v2/runtemplates/prep/' + this.get('secId') + '/wells/test/remove', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        clearWells: function (model) {
            var url = Settings.url('rest/v2/runtemplates/prep/' + this.get('secId') + '/wells/clear', {
                from: model.get('wellSelection').get('start'),
                to: model.get('wellSelection').get('to'),
                direction: model.get('wellSelection').get('direction')
            }), defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'get',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },
        generateSampleId: function (sampleIds) {
            var url = Settings.url('rest/v2/runtemplates/prep/' + this.get('secId') + '/wells/generateSampleId'),
                defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'PUT',
                contentType: 'application/json',
                data: JSON.stringify(sampleIds),
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        clearSampleId: function (model) {
            var params = {
                from: model.get('wellSelection').get('start'),
                to: model.get('wellSelection').get('to'),
                direction: model.get('wellSelection').get('direction')
            };

            var url = Settings.url('rest/v2/runtemplates/prep/' + this.get('secId') + '/wells/clearSampleId', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        }
    });

    app.PrepRunTemplate.role = RolesMixin.RUN_PREP_TEMPLATE;

    app.PrepRunTemplateCollection = Backbone.Collection.extend({
        model: app.PrepRunTemplate
    });
});

