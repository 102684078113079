define('entities/caccounts/extractionreagents',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.ExtractionReagent = Backbone.RelationalModel.extend({
        service: 'services/caccounts/extractionreagents',
        fetch: function () {
            this.url = Settings.url('rest/v2/extraction/reagents/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': undefined,
            'name': undefined,
            'description': undefined,
            'shortName': undefined
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'kit',
            relatedModel: 'KitExtraction',
            includeInJSON: ['secId', 'code', 'name']
        }],

        postUrl: function () {
            return Settings.url('rest/v2/extraction/reagents');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },
        getPDF: function () {
            return Settings.getDocUrl('ExtractionReagentJson.pdf');
        }
    });

    app.ExtractionReagent.role = RolesMixin.EXTRACTIONMETHOD;

    app.ExtractionReagentCollection = Backbone.Collection.extend({
        model: app.ExtractionReagent,
        comparator: 'code'
    });

});

