define('services/caccounts/standardCurve/standardCurveAssayConfigurationResults',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/standardCurve/standardCurveAssayConfigurationResults'
], function (
    app,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.StandardCurveAssayConfigurationResult,
                collection: app.StandardCurveAssayConfigurationResultCollection
            };
        },
        getName: function () {
            return 'standardcurveassayconfigurationresult';
        },
        getDynamicJson: function () {
            return 'StandardCurveAssayConfigurationResultJson';
        },
        getCreateEditLine: function () {
            return [
                {
                    'field': 'assayConfigurationResult',
                    'param': {'type': 'ASSAYCONFIGURATIONRESULT_FROMASSAY', 'display': {'class': 'col-w-67'}}
                },
                {
                    'field': 'concentration',
                    'param': {'type': 'NUMBER', 'display': {'class': 'col-w-10 m-r-20'}}
                },
                {
                    'field': 'sampleId',
                    'param': {'type': 'TEXT', 'display': {'class': 'col-w-10'}}
                },
                {
                    'field': 'concentrationSample',
                    'param': {'type': 'NUMBER', 'display': {'class': 'col-w-10'}}
                },
                {
                    'field': 'show',
                    'param': {
                        'type': 'POPUP',
                        'config': _.bind(this.showResults, this),
                        'display': {
                            'class': 'col-w-3',
                            'mdi': 'mdi-eye',
                            'condition': {
                                type: 'FUNCTION',
                                func: function (model) {
                                    return model.get('values').length > 0;
                                }
                            }
                        }
                    }
                }
            ];
        },
        showResults: function (param) {
            param.model.get('values').fetch().done(_.bind(function () {
                require(['standardCurveController'], _.bind(function (Controller) {
                    Controller.showResults(this, param);
                }, this));
            }, this));
        }
    }, Dynamic);
});

