define('entities/caccounts/assayConfigurationDisplayConfiguration',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/assayConfigurationResult',
    'entities/caccounts/colorCompensations',
    'entities/caccounts/assayConfigurationResultOrdered'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.AssayConfigurationDisplayConfiguration = Backbone.RelationalModel.extend({
        service: 'services/caccounts/assayconfiguration',
        fetch: function () {
            this.url = Settings.url('rest/v2/assayConfigurations/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
        },
        idAttribute: 'secId',

        relations: [{
            type: Backbone.HasMany,
            key: 'results',
            relatedModel: 'AssayConfigurationResultOrdered',
            collectionType: 'AssayConfigurationResultOrderedCollection'
        }],

        save: function () {
            throw new Error('Can\'t save this entity');
        },

        jsonObjectName: 'AssayConfigurationDisplayConfigurationJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'AssayConfigurationDisplayConfiguration'

    });

    app.AssayConfigurationDisplayConfiguration.role = RolesMixin.ASSAY;

    app.AssayConfigurationDisplayConfigurationCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/assayConfigurations/');
        },
        model: app.AssayConfigurationDisplayConfiguration
    });
});

