define('services/caccounts/assayReagentVersion',[
    'entities/ns',
    'module',
    'entities/caccounts/assayReagentVersion'
], function (
    app,
    module
) {
    'use strict';

    module.exports = {
        getNamespace: function () {
            return {
                model: app.AssayReagentVersion,
                collection: app.AssayReagentVersionCollection
            };
        },
        getName: function () {
            return 'assayreagentversion';
        },
        getDynamicJson: function () {
            return 'AssayReagentVersionJson';
        }
    };
});

