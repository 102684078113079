define('assayConfigurationDisplayView',[
    'module',
    'dialogFormView',
    'underscore',
    'template!assayConfigurationDisplayView',
    'autocompleteView',
    'savingBehavior',
    'dynamicCreateEditCustomListToList',
    'services/caccounts/assayConfigurationResult',
    'services/caccounts/assayConfigurationResultOrdered',
    'backbone',
    'entities/ns'
], function (
    module,
    DialogFormView,
    _,
    Tpl,
    AutocompleteView,
    SavingBehavior,
    DynamicCreateEditCustomListToList,
    AssayConfigurationResultService,
    AssayConfigurationResultOrderedService,
    Backbone,
    app
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            cancel: '.js-cancel-popup',
            confirm: '.js-confirm'
        },

        regions: {
            displayOVAR: '.js-display-OVAR-region',
            displayPLATE: '.js-display-PLATE-region',
            displayWELL: '.js-display-WELL-region',
            displayVALIDATION: '.js-display-VALIDATION-region',
            'displayWELL_LIST': '.js-display-WELL_LIST-region',
            'displayTARGET_LIST': '.js-display-TARGET_LIST-region',
            displayCONTAMINATION: '.js-display-CONTAMINATION-region'
        },

        events: {
            'click .js-cancel-popup': 'onCancel',
            'click .js-confirm': 'onConfirm'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        className: 'assay-configuration-display-view',

        attributes: {
            style: 'flex-direction: column; gap: 15px;'
        },

        initialize: function () {
            this.modelBack = new Backbone.Collection();
            this.model.get('displayConfigurations').each(_.bind(function (displayConfiguration) {
                var model = new Backbone.Model({
                    type: displayConfiguration.get('type'),
                    results: new Backbone.Collection(displayConfiguration.get('results').models)
                });
                this.modelBack.push(model);
            }, this));
        },

        onRender: function () {
            var datas = this.model.get('results').chain().filter(function (result) {
                return result.get('target');
            }).map(function (result) {
                return {secId: result.id, code: result.get('target').get('code'), name: result.get('target').get('name')};
            }).value();
            this.displayFor('OVAR', datas);
            this.displayFor('PLATE', datas);
            this.displayFor('WELL', datas);
            this.displayFor('VALIDATION', datas);
            this.displayFor('WELL_LIST', datas);
            this.displayFor('TARGET_LIST', datas);
            this.displayFor('CONTAMINATION', datas);
            this.setPermissions(AssayConfigurationResultOrderedService.canAddOrEdit());
        },

        _findByType: function (type) {
            return this.model.get('displayConfigurations').findWhere({type: type}).get('results');
        },

        displayFor: function (type, data) {
            var collection = this._findByType(type);
            var model = new Backbone.Model({});
            model.refresh = function (collection) {
                this.set('display', collection.chain()
                    .filter(function (data) {
                        return data.get('assayConfigurationResult') && data.get('assayConfigurationResult').get('target');
                    })
                    .map(function (data) {
                        return data.get('assayConfigurationResult').get('target').get('code');
                    }).value());
            };
            model.refresh(collection);
            this.showChildView('display' + type, new DynamicCreateEditCustomListToList({
                'field': 'display',
                'model': model,
                'display': {
                    'rows': 1,
                    'style': 'height: 46px'
                },
                sortable: true,
                items: data,
                changeCallBack: _.bind(function (fieldName, itemsSelected) {
                    // remove all element where type === 'PLATE' on this.model.get('targetOrdereds')
                    collection.remove(collection.reject(function (result) {
                        return !itemsSelected.every(function (itemSelected) {
                            return itemSelected.secId !== result.get('assayConfigurationResult').id;
                        });
                    }));
                    // add all element where type === 'PLATE' on this.model.get('targetOrdereds')
                    _.each(itemsSelected, _.bind(function (itemSelected, index) {
                        // find assayConfigurationResult by secId from this.model.get('results')
                        var assayConfigurationResult = this.model.get('results').chain().filter(function (item) {
                            return item.id === itemSelected.secId;
                        }).first().value();
                        var result = collection.findWhere({assayConfigurationResult: assayConfigurationResult});
                        if (result) {
                            AssayConfigurationResultOrderedService.getModel({id: result.get('id'), assayConfigurationResult: assayConfigurationResult, type: type, sequence: index});
                        } else {
                            result = new app.AssayConfigurationResultOrdered({assayConfigurationResult: assayConfigurationResult, type: type, sequence: index});
                            collection.add(result);
                        }
                    }, this));
                    collection.sort();
                    model.refresh(collection);
                }, this)
            }));
        },
        onCancel: function () {
            this.model.get('displayConfigurations').each(_.bind(function (displayConfiguration) {
                var model = this.modelBack.findWhere({type: displayConfiguration.get('type')});
                displayConfiguration.get('results').reset(model.get('results').models);
            }, this));
            this.hide();
        },

        onConfirm: function () {
            this.hide();
        }
    });
});
