define('entities/caccounts/preprocess',[
    'entities/ns',
    'backbone',
    'settings',
    'jquery',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    $,
    RolesMixin
) {
    'use strict';

    app.PreProcess = Backbone.RelationalModel.extend({
        service: 'services/caccounts/preprocess',
        fetch: function () {
            this.url = Settings.url('rest/v2/preProcess/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'name': '',
            'description': '',
            'comment': '',
            'configuration': '',
            tags: []
        },
        idAttribute: 'secId',

        postUrl: function () {
            return Settings.url('rest/v2/preProcess/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'PreProcessJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'PreProcess',

        importable: true,

        processSample: function (sample, lisAnas) {
            var url = Settings.url('rest/v2/preProcess/' + this.get('secId') + '/process/' + sample.get('secId')),
                defer = $.Deferred();
            $.ajax({
                method: 'POST',
                url: url,
                contentType: 'application/json',
                data: JSON.stringify(lisAnas),
                success: function (json) {
                    defer.resolve(json);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        }
    });

    app.PreProcess.role = RolesMixin.PREPROCESS;

    app.PreProcessCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/preProcess/');
        },
        model: app.PreProcess,
        comparator: 'sequence'
    });
});

