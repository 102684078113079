define('entities/caccounts/curves',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'underscore',
    'jquery',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin,
    _,
    $
) {
    'use strict';

    app.Curve = Backbone.RelationalModel.extend({
        service: 'services/caccounts/curves',
        fetch: function (pcrWell) {
            this.url = Settings.url('rest/v2/well/pcr/' + pcrWell.get('secId') + '/curves/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'name': undefined,
            'lines': [],
            'type': undefined,
            'channel': undefined
        },
        idAttribute: 'secId',


        save: function () {
            throw new Error('Not available');
        },

        jsonObjectName: 'CurveJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'Curve'
    });

    app.Curve.role = RolesMixin.RUN_PCR;

    app.CurveCollection = Backbone.Collection.extend({
        model: app.Curve,
        comparator: 'channel',
        fetch: function (param) {
            if (!param) {
                param = {raw: true};
            }
            var params = {};
            params.secIds = this.pluck('secId');
            params.raw = param.raw;

            var deferred = $.Deferred();
            Backbone.ajax({
                type: 'POST',
                url: Settings.url('rest/v2/curves/'),
                contentType: 'application/json',
                data: JSON.stringify(params),
                success: _.bind(function (collection) {
                    this.reset(collection);
                    deferred.resolve();        
                }, this)
            });
            return deferred.promise();
        }
    });
});

