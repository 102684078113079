define('services/caccounts/userCodelistelement',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/userCodelistelement'
], function (
    app,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.UserCodeListElement,
                collection: app.UserCodeListElementCollection
            };
        },
        getName: function () {
            return 'usercodelistelement';
        },
        getDynamicJson: function () {
            return 'UserCodeListElementJson';
        },
        getCreateEditLine: function () {
            return [
                {'field': 'code', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-15'}}},
                {'field': 'name', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-35'}}},
                {'field': 'seq', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-5'}}},
                {'field': 'description', 'param': {'type': 'TEXTAREA', 'display': {'class': 'col-w-45'}}}
            ];
        }
    }, Dynamic);
});

