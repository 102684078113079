define('translationsTableController',[
        'module',
        'underscore',
        'translationsPopupFormView',
        'jquery',
        'app',
        'entities/ns'
    ],
    function (
        module,
        _,
        TranslationsPopupFormView,
        $,
        App,
        app
    ) {
        'use strict';

        module.exports = {
            /**
             * Show the details of the model.
             * @param param - The parameter object.
             * @param {Object} param.model - The model whose details are to be shown.
             * @param {Object} param.service - The service object.
             * @param {Function} param.callBackOnClose - The callback function to be called after the CreateEditLayoutView is shown.
             */
            showDetails: function (param) {
                var defers = [];
                defers.push(this.getLanguages());
                if(!param.model.isNew()) {
                    defers.push(param.model.fetch());
                }
                $.when.apply($, defers).done(_.bind(function (languages) {
                    var popupFormView = new TranslationsPopupFormView({
                        model: param.model,
                        languages: languages
                    });
                    popupFormView.show({
                        title: _.i18n('translationsList.textAreaDynamicTextEditor'),
                        className: 'baseTableEditAddPopup translationsPopup',
                        service: param.service
                    }, _.bind(function () {
                        if (param.callBackOnClose) {
                            param.callBackOnClose();
                        }
                    }, this));
                }, this));
            },
            getLanguages: function () {
                var deferred = $.Deferred();
                if (App.languages) {
                    deferred.resolve(App.languages);
                } else {
                    var languageCollection = new app.LanguageCollection();
                    languageCollection.getLanguages(true).done(_.bind(function (languageCollection) {
                        App.languages = languageCollection;
                        languageCollection.each(function (language) {
                            if (['en', 'fr', 'de'].includes(language.get('abbreviation'))) {
                                language.set('checked', true);
                            }
                        });
                        deferred.resolve(languageCollection);
                    }, this));
                }
                return deferred;
            }
        };
    });

