define('services/caccounts/routingaction',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/routingaction'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.RoutingAction,
                collection: app.RoutingActionCollection
            };
        },
        getName: function () {
            return 'routingAction';
        },
        getUrl: function () {
            return 'rest/v2/routingaction/';
        },
        getDynamicJson: function () {
            return 'RoutingActionJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 50
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                width: 80
            }, {
                label: _.i18n('common.sequence'),
                name: 'sequence',
                formatter: viewDynamic.defaultFormatter,
                index: 'sequence',
                search: true,
                width: 15
            }, {
                label: _.i18n('routingAction.type'),
                name: 'type',
                formatter: viewDynamic.defaultFormatter,
                index: 'type',
                search: true,
                width: 30
            }, {
                label: _.i18n('routingAction.refreshAnalyzes'),
                name: 'refreshAnalyzes',
                formatter: viewDynamic.booleanFormatter,
                search: false,
                width: 10
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getJqGridSidx: function () {
            return 'sequence';
        },
        getCollection: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return new app.RoutingActionCollection(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 500, 'fields': ['name']};
        },
        findRoutingAction: function (params) {
            if (!params) {
                params = {};
            }
            params.sidx = 'sequence';
            var url = Settings.url('rest/v2/routingaction/find'),
                defer = $.Deferred();

            $.ajax({
                method: 'POST',
                url: url,
                data: $.param(params),
                contentType: 'application/x-www-form-urlencoded',
                success: function (response) {
                    defer.resolve(new app.RoutingActionCollection(response));
                }
            });

            return defer.promise();
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push({
                'field': 'states',
                'param': {
                    'filter': [{
                        attributeChange: 'sampleRegistration',
                        values: [
                            {
                                value: false,
                                filter: {attribute: 'state', value: ['SAMPLE_NOT_REGISTERED', 'ERROR', 'NO_ROUTE']}
                            },
                            {
                                value: true,
                                filter: {
                                    attribute: 'state',
                                    value: ['SAMPLECLASS_NOT_FOUND', 'NO_DATASOURCE', 'SAMPLE_NOT_FOUND', 'SAMPLE_ALREADY_REGISTERED', 'SAMPLE_WITHOUT_LISANA', 'MULITPLE_SAMPLES_SAME_ORDER', 'ERROR', 'NO_ROUTE']
                                }
                            }]
                    }]
                }
            });
            custom.push({
                'field': 'plateSize',
                'param': {
                    'type:': 'STRING',
                    'display': {'class': 'col-xs-1'}
                },
                'extra': {
                    'type': 'LABEL',
                    'display': {'class': 'col-xs-7'}
                }
            });
            custom.push(
                {
                    'field': 'type', 'param': {
                        'type': 'CODELIST', 'code': 'ROUTACT_TYP', 'display': {'class': 'col-xs-3'}
                    }
                }
            );
            return custom;
        }
    }, Dynamic);
});

