define('services/caccounts/pcrwellresults',[
    'entities/ns',
    'module',
    'jquery',
    'underscore',
    'backbone',
    'dynamic',
    'entities/caccounts/pcrwellresults',
    'entities/caccounts/pcrwells'
], function (
    app,
    module,
    $,
    _,
    Backbone,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.PcrWellResult,
                collection: app.PcrWellResultCollection
            };
        },
        getModel: function (item) {
            return app.PcrWellResult.findOrCreate(item);
        },
        getCollection: function (items) {
            return new app.PcrWellResultCollection(items);
        },
        getUrl: function () {
            return 'rest/v2/results/wells/pcr/';
        },
        findResultByWells: function (wells, types) {
            if (wells instanceof Backbone.Collection) {
                wells = wells.models;
            }
            if (!_.isArray(wells)) {
                wells = [wells];
            }
            wells = _.map(wells, function (well) {
                if (well instanceof Backbone.Model) {
                    return well;
                }
                return app.PcrWell.findOrCreate(well);
            });

            if (types && !_.isArray(types)) {
                types = [types];
            }
            var pcrWellResultIds = [];
            if (_.isEmpty(types)) {
                _.each(wells, function (well) {
                    types = well.get('resultByTypes')
                        .each(function (resultByType) {
                            resultByType.get('results')
                                .chain()
                                .filter(function (r) {
                                    return r.get('pcrWell');
                                })
                                .each(function (r) {
                                    if (!_.findWhere(pcrWellResultIds, {id: r.id})) {
                                        pcrWellResultIds.push({id: r.id, well: well});
                                    }
                                });
                        });
                });
            } else {
                _.each(types, function (type) {
                    _.chain(wells)
                        .filter(function (well) {
                            return well.get('resultByTypes').findWhere({type: type});
                        })
                        .map(function (well) {
                            var resultByType = well.get('resultByTypes').findWhere({type: type});
                            return resultByType.get('results').map(function (result) {
                                return {id: result.id, well: well};
                            });
                        })
                        .flatten()
                        .each(function (item) {
                            if (!_.findWhere(pcrWellResultIds, {id: item.id})) {
                                pcrWellResultIds.push(item);
                            }
                        });
                });
            }
            var deferred = $.Deferred();
            $.ajax({
                url: 'rest/v2/results/pcr/',
                type: 'POST',
                contentType: 'application/json',
                data: JSON.stringify(_.pluck(pcrWellResultIds, 'id')),
                success: function (items) {
                    _.each(items, function (item) {
                        var resultByWell = _.findWhere(pcrWellResultIds, {id: item.secId});
                        item.pcrWell = {secId: resultByWell.well.id};
                        app.PcrWellResult.findOrCreate(item);
                    });
                    deferred.resolve();
                },
                error: function (xhr) {
                    deferred.reject(xhr);
                }
            });
            return deferred.promise();
        }
    }, Dynamic);
});

