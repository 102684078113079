define('entities/caccounts/reportProperties',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.ReportProperty = Backbone.RelationalModel.extend({
        service: 'services/caccounts/reportProperties',
        fetch: function () {
            this.url = Settings.url('rest/v2/reports/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'report': {},
            'key': undefined,
            'value': undefined
        },
        idAttribute: 'secId',
        jsonObjectName: 'ReportPropertyJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'ReportProperty'
    });

    app.ReportProperty.role = RolesMixin.REPORT;

    app.ReportPropertyCollection = Backbone.Collection.extend({
        model: app.ReportProperty
    });

});

