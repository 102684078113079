define('editWellTargetView',[
    'module',
    'template!editWellTargetView',
    'underscore',
    'jquery',
    'autocompleteView',
    'dialogFormView',
    'pcrWellResultController',
    'rolesMixin',
    'settings',
    'privileges',
    'colorUtils'
], function (
    module,
    WellTargetTpl,
    _,
    $,
    AutocompleteView,
    DialogFormView,
    PcrWellResultController,
    RolesMixin,
    Settings,
    Privileges,
    ColorUtils
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: WellTargetTpl,

        ui: {
            cts: '.js-ui-cts',
            quantifications: '.js-ui-quantifications',
            input: '.js-info-input'
        },

        events: {
            'change @ui.input': 'onInputChange',
            'change @ui.cts': 'onChangeCts',
            'change @ui.quantifications': 'onChangeQuanfitications'
        },

        regions: {
            codeErrRegion: '.js-select-codeErr-region',
            mbAnaResRegion: '.js-mbAnaRes',
            resultRegion: '.js-result'
        },

        modelEvents: {
            'change:result': 'render',
            'change:cts': 'onChangeValue',
            'change:quantifications': 'onChangeValue'
        },

        className: 'col-xs-12 p-l-2 p-r-2 d-f p-t-5 p-b-5',

        attributes: function () {
            if (this.model.isOvar()) {
                return {
                    style: 'background-color: ' + ColorUtils.defineAlpha(this.model.get('result').get('color'), '30') + '; gap: 2px;align-items: center;'
                };
            }
            return {style: 'gap: 2px;align-items: center;'};
        },

        initialize: function () {
            this.canEdit = (this.model.get('valSt') < 4 || !!this.model.isOvar()) && Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.MRES);
        },

        serializeData: function () {
            var templateData = {
                mbAnaCode: this.model.get('refAssayResult').get('target').get('code'),
                resCode: this.model.get('result') ? this.model.get('result').get('code') : null,
                quantification: this.model.get('quantification'),
                comment: this.model.get('comment'),
                codeErr: this.model.get('codeErr')
            };
            templateData.allowToNeg = templateData.propC ? Object.keys(templateData.propC).some(function (k) {
                return ~k.indexOf('Amp');
            }) : false;
            templateData = _.extend(templateData, this.model.getResultIcon());
            templateData.propC = this.model.getPropCFormated();

            templateData.isOVAR = this.model.isOvar();
            templateData.canEdit = this.canEdit;

            return templateData;
        },

        onRender: function () {
            if (this.canEdit && !this.model.isOvar()) {
                var PcrWellService = require('services/caccounts/pcrwells');
                var codeErrView = new AutocompleteView(
                    this._getAutocompleteOptions('codeErr', 'code', 'code',
                        'rest/public/codeLists/code/ERRTYPE/elements?sord=asc&sidx=code',
                        'common.empty.placeholder', 'code',
                        this.onChangeCodeErr,
                        null, '',
                        PcrWellService.getErrCodeFieldsToDisplay()
                    )
                );
                this.getRegion('codeErrRegion').show(codeErrView);
            }
            if (this.canEdit) {
                var MbAnaResService = require('services/caccounts/mbanares');
                this.resGroupView = new AutocompleteView(
                    this._getAutocompleteOptionObject(MbAnaResService.getAutocompleteParam({
                        modelProperty: 'result',
                        callBackOnChange: this.onChangeResult,
                        values: {
                            mbAnaResGrSecId: this.model.get('refAssayResult').get('target').get('refMbAnaResGr').get('secId')
                        },
                        canAddOrEdit: false
                    })));
                this.getRegion('mbAnaResRegion').show(this.resGroupView);
            }
            PcrWellResultController.show(this.model, 'Well', this.getRegion('resultRegion'));
            this.setPermissions(Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.MRES));
        },

        onChangeCodeErr: function (fieldName, model) {
            if (model) {
                model = model.get('code');
            }
            this.onChange(fieldName, model);
        },

        onChangeResult: function (fieldName, model) {
            if (model) {
                if (model.get('code') === 'N') {
                    this.model.set({cts: [], quantifications: []}, {silent: true});
                }
                var MbAnaResService = require('services/caccounts/mbanares');
                var errorParameter = MbAnaResService.getErrorParameter(model);
                model.codeErr = errorParameter.code;
                model.commentErrAuto = errorParameter.message;
                model.justificatory = errorParameter.message;
                if (this.model.isOvar()) {
                    this.$el.css('background-color', ColorUtils.rgbaString(ColorUtils.hexToRgba(ColorUtils.defineAlpha(model.get('color'), '30'))));
                }
            }
            this.onChange(fieldName, model);
        },

        onChangeValue: function () {
            if ((this.model.get('cts') || this.model.get('quantifications')) && this.model.get('result').get('code') === 'N') {
                var MbAnaResService = require('services/caccounts/mbanares');
                MbAnaResService.findByCode('P', this.model.get('result').get('refMbAnaResGr').get('secId')).done(_.bind(function (result) {
                    this.onChange('result', result);
                }, this));
            }
        },

        onChangeCts: function (e) {
            var $target = $(e.currentTarget);
            var cts = $target.val().split(';');
            this.model.set('cts', cts);
        },

        onChangeQuanfitications: function (e) {
            var $target = $(e.currentTarget);
            var quantifications = $target.val().split(';');
            if (quantifications.length === 1) {
                quantifications[0] = parseFloat(quantifications[0]);
            }
            this.model.set('quantifications', quantifications);
        }
    });
});

