define('entities/caccounts/userCodelistelement',[
    'entities/ns',
    'backbone',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    RolesMixin
) {
    'use strict';

    app.UserCodeListElement = Backbone.RelationalModel.extend({
        service: 'services/caccounts/userCodelistelement',
        defaults: {
            secId: null,
            code: '',
            name: '',
            seq: '',
            description: ''
        },
        idAttribute: 'secId'
    });

    app.UserCodeListElement.role = RolesMixin.USERCODELIST;

    app.UserCodeListElementCollection = Backbone.Collection.extend({
        model: app.UserCodeListElement,
        comparator: function (o1, o2) {
            if (!o1.get('seq')) {
                return -1;
            }
            if (!o2.get('seq')) {
                return 1;
            }
            return (o1.get('seq') < o2.get('seq') ? -1 : (o1.get('seq') > o2.get('seq') ? 1 : 0));
        }
    });
});

