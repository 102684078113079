define('adminTrashView',[
    'module',
    'underscore',
    'jquery',
    'customBootboxMessage',
    'jqgridView',
    'template!bioTrashTpl'
], function (
    module,
    _,
    $,
    CustomBootboxMessage,
    JqGridView,
    viewTpl
) {
    'use strict';

    module.exports = JqGridView.extend({
        template: viewTpl,
        filtersName: 'trashListFilters',
        triggers: {},

        ui: function () {
            return _.extend({
                jqGrid: '#jq-grid-list',
                jqGridPager: '#jq-grid-pager-list',
                exportTrash: '.export-link-action'
            }, this.gridUi);
        },

        events: function () {
            return _.extend({
                'click @ui.exportTrash': 'onDownloadTrash'
            }, this.gridEvents);
        },

        serializeData: function () {
            this.checkedRowsList = [];

            var templateData = {
                data: this.options.data
            };
            return templateData;
        },

        gridOptions: function (data) {
            // var that = this;
            var options = {
                colModel: [
                    {
                        label: ' ',
                        name: 'export',
                        sortable: false,
                        search: false,
                        classes: 'export-link-action ignore-row-click',
                        formatter: this.exportActionIconFormatter,
                        fixed: true,
                        width: 20
                    }, {
                        label: _.i18n('common.filename'),
                        name: 'name',
                        classes: 'trash-link',
                        formatter: this.getRowObjectFormatter,
                        search: true,
                        index: 'name',
                        searchoptions: {size: 30}
                    }
                ],
                sortname: 'search',

                onSelectRow: function (/*rowId, bool, e*/) {
                    // if (!$(e.target).parent().hasClass('ignore-row-click') && e.type === 'click') {
                    // }
                }
            };
            options = _.defaults(options, this.gridInitOptions(data));
            return options;
        },

        onDownloadTrash: function (e) {
            var fileName = $('#' + $(e.target).attr('data-row-id')).find('.trash-link').attr('title');
            if (fileName) {
                this.downloadTrashByFileName(fileName)
                    .done(_.bind(function (result) {
                        if (!result) {
                            this.showExportFailNotification(fileName);
                        }
                    }, this));
            }
        },

        downloadTrashByFileName: function (fileName) {
            this.exportErrors = [];
            var defer = $.Deferred();
            var TrashService = require('services/trash');
            TrashService.downloadTrashByFileName(fileName)
                .done(_.bind(function (response) {
                    if (response.status && response.status !== 200) {
                        this.exportErrors.push(fileName);
                        defer.resolve(this.exportErrors);
                    } else {
                        var element = document.createElement('a');
                        element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(response.data));
                        element.setAttribute('download', response.fileName);
                        element.style.display = 'none';
                        document.body.appendChild(element);
                        element.click();
                        document.body.removeChild(element);
                        defer.resolve(this.exportErrors);
                    }
                }, this));
            return defer.promise();
        },

        showExportFailNotification: function (fileName) {
            if (fileName) {
                var message = ': </br>';
                message = message + fileName;
                var params = {
                    title: _.i18n('download.fail.notification.title'),
                    message: _.i18n('download.fail.notification.message') + message,
                    type: 'warning'
                };
                CustomBootboxMessage.customAlert(params);
            }
        },

        getRowObjectFormatter: function (cellvalue, options, rowObject) {
            return rowObject;
        }
    });
});

