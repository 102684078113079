define('entities/caccounts/setting',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.Setting = Backbone.RelationalModel.extend({
        service: 'services/caccounts/setting',
        fetch: function () {
            this.url = Settings.url('rest/v2/settings/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'params': '',
            'description': '',
            'pathSetting': false,
            comment: null,
            tags: []
        },
        idAttribute: 'secId',

        postUrl: function () {
            return Settings.url('rest/v2/settings/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },
        getPDF: function () {
            return Settings.getDocUrl('SettingJson.pdf');
        },
        jsonObjectName: 'SettingJson',
        jsonObject: 'Setting',

        importable: false
    });

    app.Setting.role = RolesMixin.SETTING;

    app.SettingCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/settings/');
        },
        model: app.Setting
    });

});

