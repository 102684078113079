define('kitLotsActionRegionView',[
    'module',
    'dialogFormView',
    'template!kitLotsActionRegionView',
    'savingBehavior',
    'kitLotsActionPopupView',
    'kitLotsActionGenericView',
    'kitLotsActionGenericQuantityView',
    'kitLotsActionWellsView',
    'underscore',
    'settings',
    'bootbox',
    'entities/ns',
    'privileges'
], function (
    module,
    DialogFormView,
    Tpl,
    SavingBehavior,
    KitLotsActionPopupView,
    KitLotsActionGenericView,
    KitLotsActionGenericQuantityView,
    KitLotsWellView,
    _,
    Settings,
    bootbox,
    app,
    Privileges
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            edit: '.js-edit',
            backToStatus: '.js-status',
            wells: '.js-wells'
        },

        events: {
            'click @ui.edit': 'onEdit',
            'click @ui.backToStatus': 'onBackToStatus',
            'click @ui.wells': 'onWells'
        },

        regions: {
            value: '.js-value'
        },

        className: function () {
            return 'status' + (this.model.get('status') === this.options.status ? ' active' : '');
        },

        serializeData: function () {
            var templateData = {status: this.options.status};
            templateData.forAction = this.enableStatus().includes(this.options.status);
            templateData.forEdit = this.enableForEdit().includes(this.options.status);
            templateData.backToStatus = this.enableForBackToStatus().includes(this.options.status);
            templateData.isTested = this.model.get('testDate') !== null;
            templateData.isPcr = this.model instanceof app.KitLotPcr;
            return templateData;
        },

        onRender: function () {
            var view;
            if (['TEST', 'VAL1', 'VAL2', 'CURRENT', 'EXHAUSTED'].includes(this.options.status)) {
                view = new KitLotsActionGenericView(_.extend({readOnly: true}, this.options));
            } else if (['DELIVERED', 'PAUSED'].includes(this.options.status)) {
                view = new KitLotsActionGenericQuantityView(_.extend({readOnly: true}, this.options));
            }
            this.showChildView('value', view);
        },

        onEdit: function (e) {
            var newStatus = this.options.status;
            var changeStatus = e.currentTarget.attributes['data-change-status'].value === 'true';
            if (newStatus === 'VAL2' && !Settings.get('currentUserModel').hasType(this.options.role, Privileges.VAL12)) {
                if (this.model.get('validatedBy') === Settings.get('currentUserModel')) {
                    bootbox.alert(_.i18n('kitLots.onlyAnotherUserWithValidation2'));
                    return;
                }
            }
            var view = new KitLotsActionPopupView(_.extend({isAction: true}, this.options));
            view.show({
                title: _.i18n('lot.' + newStatus.toLowerCase()),
                className: 'baseTableEditAddPopup kitLotStatus'
            });
            this.listenTo(this.model, 'confirmAction', _.bind(function () {
                if (changeStatus) {
                    this.model.set('status', newStatus);
                }
                this.model.trigger('change');
            }, this));
            this.model.listenTo(this.model, 'cancelStatus', _.bind(function () {
                this.model.trigger('change');
            }, this));
        },

        onBackToStatus: function () {
            var status = this.options.status;
            this.model.set('status', status);
        },
        enableStatus: function () {
            var ret;
            switch (this.model.get('status')) {
                case 'DELIVERED':
                    ret = ['TEST', 'VAL1'];
                    break;
                case 'TEST':
                    ret = ['VAL1'];
                    break;
                case 'VAL1':
                    if (this.options.numberOfValidation === 1) {
                        ret = ['CURRENT'];
                    } else {
                        ret = ['VAL2'];
                    }
                    break;
                case 'VAL2':
                    ret = ['CURRENT'];
                    break;
                case 'CURRENT':
                    ret = ['PAUSED', 'EXHAUSTED'];
                    break;
                case 'PAUSED':
                    ret = ['EXHAUSTED'];
                    break;
                case 'EXHAUSTED':
                    ret = [];
                    break;
                default:
                    ret = [];
                    break;
            }
            ret = ret.filter(_.bind(function (status) {
                return Settings.get('currentUserModel').hasType(this.options.role, status);
            }, this));
            return ret;
        },
        _filterStatus: function (ret) {
            if (this.options.numberOfValidation === 1) {
                ret = ret.filter(function (status) {
                    return status !== 'VAL2';
                });
            }
            ret = ret.filter(_.bind(function (status) {
                return Settings.get('currentUserModel').hasType(this.options.role, status);
            }, this));
            return ret;
        },
        enableForEdit: function () {
            var ret;
            switch (this.model.get('status')) {
                case 'DELIVERED':
                    ret = ['DELIVERED'];
                    break;
                case 'TEST':
                    ret = ['DELIVERED', 'TEST'];
                    break;
                case 'VAL1':
                    ret = ['DELIVERED', 'TEST', 'VAL1'];
                    break;
                case 'VAL2':
                    ret = ['DELIVERED', 'TEST', 'VAL1', 'VAL2'];
                    break;
                case 'CURRENT':
                    ret = ['DELIVERED', 'TEST', 'VAL1', 'VAL2', 'CURRENT'];
                    break;
                case 'PAUSED':
                    ret = ['DELIVERED', 'TEST', 'VAL1', 'VAL2', 'CURRENT', 'PAUSED'];
                    break;
                case 'EXHAUSTED':
                    ret = ['DELIVERED', 'TEST', 'VAL1', 'VAL2', 'CURRENT', 'PAUSED', 'EXHAUSTED'];
                    break;
                default:
                    ret = [];
            }
            return this._filterStatus(ret);
        },
        enableForBackToStatus: function () {
            var ret;
            switch (this.model.get('status')) {
                case 'DELIVERED':
                    ret = [];
                    break;
                case 'TEST':
                    ret = ['DELIVERED'];
                    break;
                case 'VAL1':
                    ret = ['DELIVERED', 'TEST'];
                    break;
                case 'VAL2':
                    ret = ['DELIVERED', 'TEST', 'VAL1'];
                    break;
                case 'CURRENT':
                    ret = ['DELIVERED', 'TEST', 'VAL1', 'VAL2'];
                    break;
                case 'PAUSED':
                    ret = ['DELIVERED', 'TEST', 'VAL1', 'VAL2', 'CURRENT'];
                    break;
                case 'EXHAUSTED':
                    ret = ['DELIVERED', 'TEST', 'VAL1', 'VAL2', 'CURRENT', 'PAUSED'];
                    break;
                default:
                    ret = [];
                    break;
            }
            return this._filterStatus(ret);
        },
        onWells: function () {
            var loader = $('.js-global-loader');
            loader.show();
            this.model.loadWells().done(function (response) {
                if (response.pcrWells.isEmpty() && response.prepWells.isEmpty()) {
                    bootbox.alert(_.i18n('kitLots.noWells'));
                    return;
                }
                new KitLotsWellView({
                    pcrWells: response.pcrWells,
                    prepWells: response.prepWells
                }).show({
                    title: _.i18n('kitLots.wells'),
                    className: 'baseTableEditAddPopup kitLotWells'
                });
            }).always(function () {
                loader.hide();
            });

        }
    });
});

