define('services/caccounts/samplehandlers',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/samplehandlers'
], function (
    app,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.SampleHandler,
                collection: app.SampleHandlerCollection
            };
        },
        getName: function () {
            return 'sampleHandler';
        },
        getUrl: function () {
            return 'rest/v2/sampleHandlers/';
        },
        getDynamicJson: function () {
            return 'SampleHandlerJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('sampleHandler.lmbSampleHandlers'),
                name: 'lmbSampleHandlers',
                classes: 'dynamic-link',
                formatter: viewDynamic.codeNameListFormatter,
                sortable: false,
                search: true,
                index: 'lmbSampleHandlerCode',
                searchoptions: {size: 10},
                width: 100
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        }
    }, Dynamic);
});

