define('entities/global/dynamicDefinition',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational',
    'entities/global/dynamicDefinitionField',
    'entities/global/text'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.DynamicDefinition = Backbone.RelationalModel.extend({
        service: 'services/global/dynamicDefinition',
        fetch: function () {
            if (!this.get(this.idAttribute) || parseInt(this.get(this.idAttribute), 10)) {
                this.url = Settings.url('rest/public/dynamic/create', {
                    context: this.get('context'),
                    classNameJson: this.get('classNameJson')
                });
            } else {
                this.url = Settings.url('rest/public/dynamic/' + this.get(this.idAttribute));
            }
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'context': '',
            'classNameJson': '',
            'text': null,
            'fields': []
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'fields',
            relatedModel: 'DynamicDefinitionField',
            collectionType: 'DynamicDefinitionFieldCollection'
        }, {
            type: Backbone.HasOne,
            key: 'text',
            relatedModel: 'Text'
        }],

        postUrl: function () {
            return Settings.url('rest/public/dynamic/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },


        importable: false
    });

    app.DynamicDefinition.role = RolesMixin.SUPER_USER;
});

