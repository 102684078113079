define('services/caccounts/attachment',[
    'entities/ns',
    'settings',
    'underscore',
    'jquery',
    'module',
    'dynamic',
    'entities/caccounts/attachment'
], function (
    app,
    Settings,
    _,
    $,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.Attachment,
                collection: app.AttachmentCollection
            };
        },
        getName: function () {
            return 'attachment';
        },
        getUrl: function () {
            return 'rest/v2/attachment/';
        },
        getDynamicJson: function () {
            return 'AttachmentJson';
        },
        getCreateEditFieldValidate: function () {
            var config = Dynamic.getCreateEditFieldValidate();
            config.push({name: 'context', type: 'required'});
            config.push({name: 'contextSecId', type: 'required'});
            config.push({name: 'contextCode', type: 'required'});
            config.push({name: 'type', type: 'required'});
            return config;
        },
        getCreateEditFieldIgnore: function () {
            var ignored = Dynamic.getCreateEditFieldIgnore.call(this);
            ignored.push('context');
            ignored.push('contextSecId');
            ignored.push('contextCode');
            return ignored;
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig.call(this);
            config.exportable = false;
            config.archivable = false;
            config.selectArchivable = false;
            config.importable = false;
            config.attachmentable = false;
            config.selectable = false;
            config.creatable = true;
            config.duplicable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: ' ',
                name: 'fileName',
                classes: 'dynamic-link',
                formatter: this.viewFileFormatter,
                search: false,
                width: 30
            }, {
                label: ' ',
                name: 'fileName',
                classes: 'dynamic-link',
                formatter: this.downloadFileFormatter,
                search: false,
                width: 30
            }, {
                label: _.i18n('attachment.context'),
                name: 'context',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'context',
                searchoptions: {size: 10},
                width: 80
            }, {
                label: _.i18n('attachment.contextSecId'),
                name: 'contextSecId',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'contextSecId',
                searchoptions: {size: 10},
                width: 80
            }, {
                label: _.i18n('attachment.contextCode'),
                name: 'contextCode',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'contextCode',
                searchoptions: {size: 10}
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 230
            }, {
                label: _.i18n('attachment.type'),
                name: 'type',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'type',
                searchoptions: {size: 10},
                width: 100
            }, {
                label: _.i18n('common.creatBy'),
                name: 'creatBy',
                classes: 'dynamic-link',
                formatter: viewDynamic.userFormatter,
                search: false,
                searchoptions: {size: 10},
                width: 50
            }, {
                label: _.i18n('common.creatDate'),
                name: 'creatDate',
                classes: 'dynamic-link',
                formatter: viewDynamic.dateTimeFormatter,
                search: false,
                searchoptions: {size: 10},
                width: 110
            }, {
                label: _.i18n('common.comment'),
                name: 'comment',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'comment',
                searchoptions: {size: 10}
            });
            return columns;
        },
        viewFileFormatter: function (cellValue, object, json) {
            if (json.name.endsWith('.pdf')) {
                return '<span class="mdi mdi-eye-outline clickable js-icon" data-secId="' + json.secId + '" title="' + _.i18n('attachment.viewFile') + '"></span>';
            }
            return '';
        },
        downloadFileFormatter: function (cellValue, object, json) {
            if (cellValue) {
                return '<span class="mdi mdi-file-download clickable js-icon" data-secId="' + json.secId + '" title="' + _.i18n('attachment.downloadFile') + '"></span>';
            }
            return '';
        },
        getJqGridEvents: function () {
            var events = Dynamic.getJqGridEvents();
            events.push({filter: '.mdi-file-download.clickable', func: this.downloadFile});
            events.push({filter: '.mdi-eye-outline.clickable', func: this.viewFile});
            return events;
        },
        downloadFile: function (secId) {
            var xhr = new XMLHttpRequest();
            xhr.open('GET', Settings.url('rest/v2/attachment/' + secId + '/file/download'), true);
            xhr.responseType = 'blob';

            xhr.onload = function () {
                if (xhr.status === 200) {
                    var blob = xhr.response;

                    // Extraire le nom de fichier de l'en-tête Content-Disposition
                    var contentDisposition = xhr.getResponseHeader('Content-Disposition');
                    var match = contentDisposition && contentDisposition.match(/filename="(.+)"$/);
                    var filename = match[1];

                    // Créer une URL à partir de l'objet Blob
                    var blobUrl = URL.createObjectURL(blob);
                    // Créer un lien temporaire
                    var tempLink = document.createElement('a');
                    tempLink.href = blobUrl;
                    tempLink.setAttribute('download', filename);

                    // Ajouter le lien au DOM
                    document.body.appendChild(tempLink);

                    // Simuler un clic sur le lien
                    tempLink.click();

                    // Retirer le lien du DOM
                    document.body.removeChild(tempLink);
                    // Libérer l'URL Blob après le téléchargement
                    URL.revokeObjectURL(blobUrl);
                }
            };
            xhr.send();
        },
        viewFile: function (secId) {
            window.open('/ViewerJS/#../' + Settings.url('rest/v2/attachment/' + secId + '/file/download', undefined, undefined, false), '_blank');
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        },
        getJqGridSidx: function () {
            return 'creatDate';
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push(
                {
                    'field': 'context',
                    'param': {
                        'type': 'LABEL'
                    }
                }
            );
            return custom;
        },
        createWithFile: function (attachment, fileInfo) {
            var defer = $.Deferred(),
                formdata = new FormData(),
                param = {'context': attachment.get('context'), 'contextSecId': attachment.get('contextSecId')};

            var url = Settings.url('rest/v2/attachment/file', param);

            formdata.append('file', fileInfo);

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: function (resp) {
                    defer.resolve(resp);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },
        openAttachment: function (service, model) {
            require([
                'dynamicController', 'adminAttachmentController'
            ], _.bind(function (DynamicController, AttachmentController) {
                AttachmentController.showListForContext(
                    null, {
                        params: {
                            'context': service.getName().toUpperCase(),
                            'contextSecId': model.get(model.idAttribute)
                        },
                        defaultModel: {
                            'context': service.getName().toUpperCase(),
                            'contextSecId': model.get(model.idAttribute),
                            'contextCode': model.get('code') ? model.get('code') : model.get('name')
                        },
                        callBackCreateEditView: AttachmentController.showDetails,
                        parentModel: model
                    }, undefined, this);
            }, this));
        },

        getAttachments: function (service, model) {
            return this.findAll(service.getName(), model.get(model.idAttribute));
        }
    }, Dynamic);
});

