define('entities/caccounts/mbanaresgrs',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/mbanares'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.MbAnaResGr = Backbone.RelationalModel.extend({
        service: 'services/caccounts/mbanaresgrs',
        fetch: function () {
            this.url = Settings.url('rest/v2/mbanaresgrs/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'name': '',
            'description': '',
            'comment': '',
            'mbAnaRes': [],
            'mbAnas': []
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'mbAnaRes',
            relatedModel: 'MbAnaRes',
            collectionType: 'MbAnaResCollection'
        }, {
            type: Backbone.HasMany,
            key: 'mbAnas',
            relatedModel: 'MbAna',
            collectionType: 'MbAnaCollection'
        }],

        postUrl: function () {
            return Settings.url('rest/v2/mbanaresgrs/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'MbAnaResGrJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'MbAnaResGr',

        importable: true
    });

    app.MbAnaResGr.role = RolesMixin.MBANARESGR;

    app.MbAnaResGrCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/mbanaresgrs/');
        },
        model: app.MbAnaResGr
    });
});

