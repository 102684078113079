define('services/errors',[
    'entities/ns',
    'module',
    'entities/errors'
], function (
    app,
    module
) {
    'use strict';

    module.exports = {
        getCollection: function (models) {
            return new app.ErrorCollection(models);
        },
        getModel: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return app.Error.findOrCreate(modelJson);
        }
    };
});
