define('services/caccounts/mbanaresgrs',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/mbanaresgrs'
], function (
    app,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.MbAnaResGr,
                collection: app.MbAnaResGrCollection
            };
        },
        getName: function () {
            return 'mbAnaResGr';
        },
        getUrl: function () {
            return 'rest/v2/mbanaresgrs/';
        },
        getDynamicJson: function () {
            return 'MbAnaResGrJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'mbanaresgr-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'mbanaresgr-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('mbAnaResGr.mbAnaResCode'),
                name: 'mbAnaRes',
                classes: 'mbanaresgr-link',
                formatter: this.mbAnaResCodeFormatter,
                sortable: false,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('mbAna'),
                name: 'mbAnas',
                classes: 'mbanaresgr-link',
                formatter: viewDynamic.codeNameListFormatter,
                sortable: false,
                search: true,
                index: 'mbAnaCode',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'mbanaresgr-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        mbAnaResCodeFormatter: function (cellValue, options) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = [];
            }

            var values = [];
            _.each(cellValue, function (mbAnaRes) {
                values.push(mbAnaRes.code);
            });
            cellValue = values.join(', ');
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + cellValue + '</span>';
        },
        getCreateEditFieldCustom: function () {
            return [
                {field: 'mbAnas', param: {type: 'LIST2ENTITY'}}
            ];
        },
        getCollection: function (modelJson) {
            if (!modelJson) {
                modelJson = [];
            }
            return new app.MbAnaResGrCollection(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 500, 'fields': ['name']};
        }
    }, Dynamic);
});

