define('entities/caccounts/attachment',[
    'entities/ns',
    'backbone',
    'settings',
    'underscore',
    'jquery',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    _,
    $,
    RolesMixin
) {
    'use strict';

    app.Attachment = Backbone.RelationalModel.extend({
        service: 'services/caccounts/attachment',
        fetch: function () {
            this.url = Settings.url('rest/v2/attachment/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'context': null,
            'contextSecId': null,
            'contextCode': null,
            'name': null,
            'type': null,
            'tags': [],
            'fileName': null,
            'comment': null
        },
        idAttribute: 'secId',

        postUrl: function () {
            return Settings.url('rest/v2/attachment/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        fileUrl: function () {
            return Settings.url('rest/v2/attachment/' + this.get('secId') + '/file/download');
        },

        importFile: function (fileInfo) {
            var defer = $.Deferred(),
                formdata = new FormData(),
                param = {};

            var url = Settings.url('rest/v2/attachment/' + this.get('secId') + '/file/upload', param);

            formdata.append('file', fileInfo);

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: function (resp) {
                    defer.resolve(resp);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        }
    });

    app.Attachment.role = RolesMixin.ATTACHMENT;

    app.AttachmentCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/attachment/');
        },
        model: app.Attachment
    });
});

