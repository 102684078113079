define('entities/caccounts/routingaction_state',[
    'entities/ns',
    'backbone',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    RolesMixin
) {
    'use strict';

    app.RoutingActionState = Backbone.RelationalModel.extend({
        service: 'services/caccounts/routingaction_state',
        defaults: {
            state: null,
            color: 16777215,
            rackId: null,
            showPopup: false,
            ignored: false,
            canBeIgnored: false
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'refRoutingAction',
            relatedModel: 'RoutingAction'
        }]
    });

    app.RoutingActionState.role = RolesMixin.ROUTINGACTION;

    app.RoutingActionStateCollection = Backbone.Collection.extend({
        model: app.RoutingActionState,
        comparator: 'sequence'
    });

});

