define('services/caccounts/files',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/files'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.File,
                collection: app.FileCollection
            };
        },
        getName: function () {
            return 'file';
        },
        getUrl: function () {
            return 'rest/v2/settings/filesExplorer/';
        },
        getDynamicJson: function () {
            return 'FileJson';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig.call(this);
            config.exportable = false;
            config.archivable = false;
            config.selectArchivable = false;
            config.importable = false;
            config.creatable = false;
            config.attachmentable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('file.fileName'),
                name: 'file',
                classes: 'dynamic-link',
                formatter: _.bind(this.iconFormatter, this),
                search: false,
                width: 10
            }, {
                label: _.i18n('file.fileName'),
                name: 'fileName',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'fileName',
                searchoptions: {size: 10},
                width: 100
            }, {
                label: _.i18n('file.created'),
                name: 'created',
                formatter: viewDynamic.toDateTimeFormatString,
                search: false,
                width: 40
            }, {
                label: _.i18n('file.lastModified'),
                name: 'lastModified',
                formatter: viewDynamic.toDateTimeFormatString,
                search: false,
                width: 40
            }, {
                label: _.i18n('file.size'),
                name: 'byteLength',
                formatter: _.bind(this.byteLengthFormatter, this),
                search: false,
                width: 20
            }, {
                label: _.i18n('file.subPath'),
                name: 'subPath',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false
            });
            return columns;
        },
        iconFormatter: function (cellValue) {
            var isFile = cellValue;
            var statusIcon = '';

            statusIcon += '<div>';
            statusIcon += '<span class="mdi ' + (isFile ? 'mdi-file' : 'mdi-folder') + '"></span>';
            statusIcon += '</div>';
            return statusIcon;
        },
        byteLengthFormatter: function (cellValue) {
            var statusIcon = '';
            cellValue = parseInt(cellValue, 10);
            if (cellValue === 0) {
                return '0 bytes';
            }
            var k = 1024;
            var sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            var i = Math.floor(Math.log(cellValue) / Math.log(k));
            var size = parseFloat((cellValue / Math.pow(k, i)).toFixed(2));

            statusIcon += '<div>';
            statusIcon += '<span>' + size + ' ' + sizes[i] + '</span>';
            statusIcon += '</div>';
            return statusIcon;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        }
    }, Dynamic);
});

