define('flash',[
    'module',
    'jquery',
    'underscore'
], function (
    module,
    $,
    _
) {
    'use strict';

    /**
     * Class utils to display some alert (flash message) on top the screen with Bootstrap 3 alert style
     * - success : display 2sec and disappear
     * - error : display until user close it
     * - info : display 2sec and disappear
     * - warning : display until user close it
     * @type {{success: Function, error: Function, info: Function, warning: Function}}
     * @exports utils/flash
     * @example
     * var flash = require('utils/flash');
     * flash.success('Success message');
     * flash.error('Error message');
     * flash.info('Info message');
     * flash.warning('Warning message');
     * @module utils/flash
     * @returns {{success: Function, error: Function, info: Function, warning: Function}}
     * @version 1.0
     * @since 1.0
     *
     */
        // Créer un conteneur d'alertes global si ce n'est pas déjà fait
    var $alertContainer = $('#alert-container');
    if ($alertContainer.length === 0) {
        $alertContainer = $('<div id="alert-container" class="alert-container"></div>');
        $('body').append($alertContainer);
    }

    module.exports = {
        /**
         * Display a success message
         * @param {string} message - The message to display
         * @param {string} subMessage - The sub message to display
         */
        success: function (message, subMessage) {
            this._show(message, subMessage, 'success', 2000);
        },
        /**
         * Display an error message
         * @param {string} message - The message to display
         * @param {string} subMessage - The sub message to display
         */
        error: function (message, subMessage) {
            this._show(message, subMessage, 'danger', 0);
        },
        /**
         * Display an info message
         * @param {string} message - The message to display
         * @param {string} subMessage - The sub message to display
         */
        info: function (message, subMessage) {
            this._show(message, subMessage, 'info', 2000);
        },
        /**
         * Display a warning message
         * @param {string} message - The message to display
         * @param {string} subMessage - The sub message to display
         */
        warning: function (message, subMessage) {
            this._show(message, subMessage, 'warning', 0);
        },
        /**
         * Display a message
         * @param {string} message - The message to display
         * @param {string} subMessage - The sub message to display
         * @param {string} type - The type of message (success, error, info, warning)
         * @param {number} timeout - The time to display the message (0 for infinite)
         * @private
         */
        _show: function (message, subMessage, type, timeout) {
            // Créer une nouvelle alerte
            var $alert = $('<div class="alert alert-' + type + ' alert-dismissible" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span></button>' +
                '<strong>' + message + '</strong>' +
                (subMessage ? '<br>' + subMessage : '') +
                '</div>');

            // Ajouter l'alerte au conteneur
            $alert.hide().appendTo($alertContainer).fadeIn(500);

            // Lorsqu'on ferme l'alerte, la faire disparaître et réajuster les autres
            $alert.find('.close').click(_.bind(function () {
                $alert.fadeOut(500, _.bind(function () {
                    $alert.remove();
                }, this));
            }, this));

            if (timeout > 0) {
                // Fermer l'alerte après le timeout
                setTimeout(_.bind(function () {
                    $alert.fadeOut(500, _.bind(function () {
                        $alert.remove();
                    }, this));
                }, this), timeout);
            }
        }
    };
});
