
define('template!wellPcrFilter', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="filter">\n    <div>\n        <label>' +
((__t = ( _.i18n('assay') )) == null ? '' : __t) +
'</label>\n        <div class="js-assay-region" style="width: 100px;"></div>\n    </div>\n    <div class="js-configuration">\n        <label>' +
((__t = ( _.i18n('assay.assayConfiguration') )) == null ? '' : __t) +
'</label>\n        <div class="js-assayConfiguration-region" style="width: 100px;"></div>\n    </div>\n    <button class="js-filter-button btn btn-info">Filter</button>\n    <button class="js-clear-button btn btn-warning">Clear</button>\n</div>\n<div class="js-grid-region"></div>\n';

}
return __p
};});

