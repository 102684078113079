define('commonListBox',[
    'module',
    'backbone',
    'backbone.marionette',
    'underscore',
    'commonGenericSelectView',
    'bootbox'
], function (
    module,
    Backbone,
    Marionette,
    _,
    Select,
    Bootbox
) {
    'use strict';

    module.exports = Marionette.View.extend({
        getTemplate: function () {
            var template = '';
            if (this.options.filter) {
                template += '<input type="text" class="js-filter" placeholder="' + _.i18n('filter') + '" style="margin-bottom: 3px"><div></div>';
            }
            template += '<div class="js-list d-f" style="flex-grow: 1"></div></div>';
            if (this.options.sortable) {
                template += '<div style="display:flex; align-items: center; justify-content: center; flex-direction: column;">' +
                    '<span class="mdi mdi-arrow-up-thin" style="font-size: 40px; line-height: 40px;"></span>' +
                    '<span class="mdi mdi-arrow-down-thin" style="font-size: 40px; line-height: 40px;"></span>' +
                    '</div>';
            }
            return _.template(template);
        },
        regions: {
            list: '.js-list'
        },
        ui: {
            filter: '.js-filter',
            up: '.mdi-arrow-up-thin',
            down: '.mdi-arrow-down-thin'
        },
        events: {
            'keyup @ui.filter': 'onFilter',
            'click .mdi-arrow-up-thin.clickable': 'onUp',
            'click .mdi-arrow-down-thin.clickable': 'onDown'
        },
        className: 'select-component d-f column',

        attributes: function () {
            if (this.options.sortable) {
                if (this.options.sortableSide === 'left') {
                    return {style: 'display: grid; grid-template-columns: 40px 1fr;'};
                } else {
                    return {style: 'display: grid; grid-template-columns: 1fr 40px;'};
                }
            }
            return {};
        },

        initialize: function () {
            this.collection = new Backbone.Collection(this.options.collection.models);
            this.listenTo(this, 'change:select', _.bind(this.onChangeSelect, this));
        },

        onRender: function () {
            this.view = new Select({
                collection: this.collection,
                attr: this.options.attr,
                model: this.options.model,
                modelProperty: this.options.modelProperty,
                value: this.options.value,
                displaySize: this.options.displaySize
            });
            this.showChildView('list', this.view);
            this.listenTo(this.view, 'select:change', function (selectView, e) {
                var values = selectView.$el.val();
                if (_.isArray(values) && values.length > 1) {
                    // disable
                    this.ui.up.prop('disabled', true);
                    this.ui.up.removeClass('clickable');
                    this.ui.up.addClass('disabled');
                    this.ui.down.prop('disabled', true);
                    this.ui.down.removeClass('clickable');
                    this.ui.down.addClass('disabled');
                    return;
                }
                var value;
                if (_.isArray(values)) {
                    value = _.first(values);
                } else {
                    value = values;
                }
                var model = this.collection.findWhere({value: value});
                // search model on list, if it's the first, disable up button
                var index = this.collection.indexOf(model);
                this.ui.up.prop('disabled', index === 0);
                this.ui.up.toggleClass('clickable', index !== 0);
                this.ui.up.toggleClass('disabled', index === 0);
                // search model on list, if it's the last, disable down button
                this.ui.down.prop('disabled', index === this.collection.length - 1);
                this.ui.down.toggleClass('clickable', index !== this.collection.length - 1);
                this.ui.down.toggleClass('disabled', index === this.collection.length - 1);
                if (this.options.model && this.options.modelProperty) {
                    this.options.model.set(this.options.modelProperty, value);
                }
                this.trigger('select:change', selectView, e);
            });
            this._defineUpBottom();
        },
        onFilter: function () {
            var filter = this.ui.filter.val().toLowerCase();
            this.collection.reset(this.options.collection.filter(function (model) {
                return model.get('name').toLowerCase().indexOf(filter) !== -1 || model.get('value').toLowerCase().indexOf(filter) !== -1;
            }));
        },
        getSelected: function () {
            return this.getChildView('list').$el.val();
        },
        remove: function (items) {
            if (!_.isArray(items)) {
                items = [items];
            }
            _.each(items, _.bind(function (item) {
                this.collection.remove(this.collection.findWhere({value: item}));
            }, this));
        },
        add: function (items) {
            if (!_.isArray(items)) {
                items = [items];
            }
            _.each(items, _.bind(function (item) {
                this.collection.add(item);
            }, this));
        },
        getValues: function () {
            return this.collection.map(function (model) {
                return model.get('value');
            });
        },
        _defineUpBottom: function (model) {
            // if filter is enable, disable up and down button
            if (this.ui.filter.val()) {
                // disable up and down button
                this.ui.up.prop('disabled', true);
                this.ui.up.removeClass('clickable');
                this.ui.up.addClass('disabled');
                this.ui.down.prop('disabled', true);
                this.ui.down.removeClass('clickable');
                this.ui.down.addClass('disabled');
                return;
            }
            var index = this.collection.indexOf(model);
            if (index === -1) {
                // disable up and down button
                this.ui.up.prop('disabled', true);
                this.ui.up.removeClass('clickable');
                this.ui.up.addClass('disabled');
                this.ui.down.prop('disabled', true);
                this.ui.down.removeClass('clickable');
                this.ui.down.addClass('disabled');
                return;
            }
            this.ui.up.prop('disabled', index === 0);
            this.ui.up.toggleClass('clickable', index !== 0);
            this.ui.up.toggleClass('disabled', index === 0);
            this.ui.down.prop('disabled', index === this.collection.length - 1);
            this.ui.down.toggleClass('clickable', index !== this.collection.length - 1);
            this.ui.down.toggleClass('disabled', index === this.collection.length - 1);
            this.trigger('select:change:updown', this.collection.pluck('value'));
        },
        /**
         * Up the selected item in the list
         */
        onUp: function () {
            // Si il y a plusieurs éléments sélectionné, on arrête
            var values = this.getChildView('list').$el.val();
            if (values.length > 1) {
                Bootbox.alert(_.i18n('select_one_item'));
                return;
            }
            // Si aucun élément n'est sélectionné, on arrête
            if (!values) {
                return;
            }
            var value = _.first(values);
            var model = this.collection.findWhere({value: value});
            var index = this.collection.indexOf(model);
            if (index === 0) {
                return;
            }
            this.collection.remove(model);
            this.collection.add(model, {at: index - 1});
            this._defineUpBottom(model);
            // reselect the item
            this.getChildView('list').$el.val(value);
        },
        /**
         * Down the selected item in the list
         */
        onDown: function () {
            // Si il y a plusieurs éléments sélectionné, on arrête
            var values = this.getChildView('list').$el.val();
            if (values.length > 1) {
                Bootbox.alert(_.i18n('select_one_item'));
                return;
            }
            // Si aucun élément n'est sélectionné, on arrête
            if (!values) {
                return;
            }
            var value = _.first(values);
            var model = this.collection.findWhere({value: value});
            var index = this.collection.indexOf(model);
            if (index === this.collection.length - 1) {
                return;
            }
            this.collection.remove(model);
            this.collection.add(model, {at: index + 1});
            this._defineUpBottom(model);
            this.getChildView('list').$el.val(value);
        },
        onChangeSelect: function (value) {
            this.view.trigger('change:select', value);
        }
    });
});
