define('services/caccounts/colorCompensationMatriceValues',[
    'entities/ns',
    'underscore',
    'dynamic',
    'module',
    'entities/caccounts/colorCompensationMatriceValues'
], function (
    app,
    _,
    Dynamic,
    module
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ColorCompensationMatriceValue,
                collection: app.ColorCompensationMatriceValueCollection
            };
        },
        getName: function () {
            return 'ColorCompensationMatrice';
        },
        getDynamicJson: function () {
            return 'ColorCompensationMatriceJson';
        }
    }, Dynamic);
});

