define('services/caccounts/lmbextractors',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/lmbextractors'
], function (
    app,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.LmbExtractor,
                collection: app.LmbExtractorCollection
            };
        },
        getName: function () {
            return 'lmbextractor';
        },
        getUrl: function () {
            return 'rest/v2/lmbextractors/';
        },
        getDynamicJson: function () {
            return 'LmbExtractorJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('lmbExtractor.refExtractor'),
                name: 'refExtractor.code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'refExtractor.code',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('lmbExtractor.identifier'),
                name: 'identifier',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'identifier',
                searchoptions: {size: 10},
                width: 100
            }, {
                label: _.i18n('lmbExtractor.location'),
                name: 'location',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'location',
                searchoptions: {size: 10},
                width: 100
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['location', 'identifier']};
        },
        getCreateEditLine: function () {
            return [
                {'field': 'code', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-20'}}},
                {'field': 'identifier', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-20'}}},
                {'field': 'location', 'param': {'type': 'TEXTAREA', 'display': {'class': 'col-w-30'}}},
                {'field': 'description', 'param': {'type': 'TEXTAREA', 'display': {'class': 'col-w-30'}}}
            ];
        },

        getAutocompleteParamFromExtractionMethodSecId: function (config, extractionMethodSecId) {
            if (!config.paramUrl) {
                config.paramUrl = {
                    'sord': 'asc',
                    'sidx': 'code'
                };
            }
            config.paramUrl.refExtractionMethodSecId = extractionMethodSecId;

            return Dynamic.getAutocompleteParam(config, this);
        }
    }, Dynamic);

});

