define('entities/admin/arrayelement',[
    'entities/ns',
    'backbone',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    RolesMixin
) {
    'use strict';

    app.ArrayElement = Backbone.RelationalModel.extend({
        service: 'services/admin/arrayelement',
        defaults: {
            secId: null,
            name: '',
            seq: '',
            contentType: null,
            contentTypeParam: null,
            description: null
        },
        idAttribute: 'secId'
    });

    app.ArrayElement.role = RolesMixin.SUPER_USER;

    app.ArrayElementCollection = Backbone.Collection.extend({
        model: app.ArrayElement,
        comparator: 'seq'
    });

});

