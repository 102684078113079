define('entities/caccounts/pcrWellResultByType',[
    'entities/ns',
    'backbone',
    'backboneRelational',
    'entities/caccounts/pcrwellresults'
], function (
    app,
    Backbone
) {
    'use strict';

    app.PcrWellResultByType = Backbone.RelationalModel.extend({
        service: 'services/caccounts/pcrwellresults',

        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'results',
            relatedModel: 'PcrWellResult',
            collectionType: 'PcrWellResultCollection',
            includeInJSON: false
        }]
    });

    app.PcrWellResultByTypeCollection = Backbone.Collection.extend({
        model: app.PcrWellResultByType
    });
});

