define('services/admin/dye',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'rolesMixin',
    'entities/admin/dye'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic,
    RolesMixin
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.Dye,
                collection: app.DyeCollection
            };
        },
        getName: function () {
            return 'dye';
        },
        getUrl: function () {
            return 'rest/public/dyes/';
        },
        getDynamicJson: function () {
            return 'DyeJson';
        },
        getTypeDynamicJson: function () {
            return 'ADMIN';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig.call(this);
            config.exportable = false;
            config.archivable = false;
            config.selectArchivable = false;
            config.importable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('common.shortName'),
                name: 'shortName',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'shortName',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('detectionFormat.label'),
                name: 'refDetectionFormat.code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'refDetectionFormat.code',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('dye.color'),
                name: 'color',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'color',
                searchoptions: {size: 10},
                width: 25
            }, {
                label: _.i18n('common.comment'),
                name: 'comment',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'comment'
            });
            return columns;
        },
        getJqGridSidx: function () {
            return 'name';
        },
        canAddOrEdit: function () {
            return Settings.get('currentUserModel').hasRole(RolesMixin.SUPER_USER);
        },
        getAutocompleteParam: function (config) {
            var configuration = Dynamic.getAutocompleteParam(config, this);
            configuration.valueProperty = config.valueProperty ? config.valueProperty : 'shortName';
            configuration.queryProperty = config.queryProperty ? config.queryProperty : 'shortName';
            configuration.callbackEdit = undefined;
            configuration.callbackAdd = undefined;
            configuration.urlPath = this.getCollectionUrl();
            configuration.values = config.paramUrl ? config.paramUrl : {
                'sord': 'asc',
                'sidx': 'shortName'
            };
            configuration.valueKey = config.valueKey ? config.valueKey : 'shortName';
            return configuration;
        },
        getCreateEditFieldValidate: function () {
            return [
                {name: 'channel', type: 'required'},
                {name: 'name', type: 'required'},
                {name: 'shortName', type: 'required'},
                {name: 'refDetectionFormat', type: 'required'}
            ];
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['channel', 'name', 'refDetectionFormat.code', 'comment']};
        },
        getCreateEditLine: function () {
            return [
                {'field': 'channel', 'param': {'type': 'NUMBER', 'display': {'class': 'col-w-5 m-r-5'}}},
                {'field': 'name', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-15 m-r-5'}}},
                {'field': 'shortName', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-15 m-r-5'}}},
                {'field': 'color', 'param': {'type': 'COLOR', 'display': {'class': 'col-w-15 m-r-5'}}},
                {'field': 'excitation', 'param': {'type': 'NUMBER', 'display': {'class': 'col-w-15 m-r-5'}}},
                {'field': 'emission', 'param': {'type': 'NUMBER', 'display': {'class': 'col-w-15 m-r-5'}}},
                {'field': 'comment', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-15 m-r-5'}}}
            ];
        }
    }, Dynamic);
});

