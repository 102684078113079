define('entities/caccounts/assayDisplayConfigurationVersion',[
    'entities/ns',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/assayReagentVersion',
    'entities/caccounts/assayResultOrderedVersion',
    'entities/caccounts/assayResultVersion',
    'entities/caccounts/kitprots',
    'entities/caccounts/assaygroup',
    'entities/caccounts/assay',
    'entities/caccounts/colorCompensations'
], function (
    app,
    Backbone,
    Settings,
    $,
    _,
    RolesMixin
) {
    'use strict';

    app.AssayDisplayConfigurationVersion = Backbone.RelationalModel.extend({
        service: 'services/caccounts/assayVersion',
        fetch: function () {
            this.url = Settings.url('rest/v2/assayVersions/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
        },
        idAttribute: 'secId',

        relations: [{
            type: Backbone.HasMany,
            key: 'results',
            relatedModel: 'AssayResultOrderedVersion',
            collectionType: 'AssayResultOrderedVersionCollection'
        }],

        jsonObjectName: 'AssayDisplayConfigurationVersionJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'AssayDisplayConfigurationVersion'
    });

    app.AssayDisplayConfigurationVersion.role = [RolesMixin.ASSAY, RolesMixin.RUN_PCR];

    app.AssayDisplayConfigurationVersionCollection = Backbone.Collection.extend({
        model: app.AssayDisplayConfigurationVersion
    });
});

