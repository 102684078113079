define('pcrWellToolTipDotCollectionView',[
    'module',
    'backbone.marionette',
    'pcrWellToolTipDotItemView',
    'pcrWellToolTipDotItemOvagNpxView',
    'entities/ns',
    'template!pcrWellToolTipDotCollectionView',
    'pcrWellResultController'
], function (
    module,
    Marionette,
    PcrWellToolTipDotItemView,
    PcrWellToolTipDotItemOvagNpxView,
    app,
    Tpl,
    PcrWellResultController
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        template: Tpl,
        childView: function (model) {
            if (model instanceof app.PcrWellResultOvagNpx) {
                return PcrWellToolTipDotItemOvagNpxView;
            }
            return PcrWellToolTipDotItemView;
        },
        className: 'd-f',
        attributes: {
            style: 'flex-direction: column; gap: 5px;'
        },
        ui: {
            result: '.js-result'
        },
        childViewContainer: '.js-container',
        serializeData: function () {
            if (!this.options.ovar) {
                return {exist: false};
            }
            var model = this.options.ovar;

            var errMessComToDisplayList = '';
            var commentsList = [];

            if (model.get('codeErr') && model.get('codeErr') !== '') {
                commentsList.push(model.get('codeErr'));
            }
            if (model.get('justificatory') && model.get('justificatory') !== '') {
                commentsList.push(model.get('justificatory'));
            }
            if (model.get('comment') && model.get('comment') !== '') {
                commentsList.push(model.get('comment'));
            }

            if (commentsList.length > 0) {
                errMessComToDisplayList = commentsList.join(' - ');
            }
            return {
                exist: true,
                targetCode: model.get('refAssayResult') && model.get('refAssayResult').get('target').get('code'),
                resCode: model.get('result') ? model.get('result').get('code') : '',
                cts: model.get('cts'),
                quantifications: model.get('quantifications'),
                errMessComToDisplayList: errMessComToDisplayList
            };
        },
        onRender: function () {
            if (this.options.ovar) {
                var content = PcrWellResultController.generateContent(this.options.ovar, 'TooltipTarget');
                this.ui.result.html(content);
            }
        }
    });
});
