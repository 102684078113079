define('pcrWellResultRefValueView',[
    'module',
    'backbone.marionette',
    'pcrWellResultRefValueItemView'
], function (
    module,
    Marionette,
    PcrWellResultRefValueItemView
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        attributes: {
            'style': 'display: flex;'
        },
        childView: PcrWellResultRefValueItemView,
        childViewOptions: function () {
            return {
                width: this.options.config.width ? this.options.config.width : '100px',
                config: this.options.config
            };
        },
        viewFilter: function (view) {
            return !view.model.get('refAssayResult').get('target').get('classifiedValues').isEmpty();
        }
    });
});
