/**
 * Created by OBL on 14/01/2016.
 */
define('dynamicController',[
    'module',
    'underscore',
    'backbone',
    'jquery',
    'app',
    'dynamicList',
    'customBootboxMessage',
    'dynamicCreateEdit'
], function (
    module,
    _,
    Backbone,
    $,
    App,
    View,
    CustomBootboxMessage,
    CreateEditLayoutView
) {
    'use strict';

    module.exports = {
        showList: function (region, data, displayArchived) {
            var service = require('services/' + data.entityNamePath);
            this._showList(region, data, displayArchived, service);
        },
        _showList: function (region, data, displayArchived, service) {
            var needRoles = service.hasRole();
            if (needRoles) {
                require(['errorController'], _.bind(function (ErrorController) {
                    ErrorController.showMissingRoles(needRoles);
                }, this));
                return;
            }
            var view = new View({
                data: data,
                displayArchived: displayArchived,
                service: service,
                region: region
            });
            view.listenTo(view, 'dynamic:show', _.bind(function (param) {
                param.service = service;
                this.showDetails(param);
            }, this));
            if (region) {
                region.show(view);
            } else {
                view.show({
                    title: _.i18n('menu.' + service.getName()),
                    className: 'baseTableEditAddPopup ' + service.getName() + 'ListPopup'
                });
            }
        },

        /**
         * This function is used to display the details of a specific model.
         * It first checks if the correct number of arguments have been passed and if the passed argument is an object.
         * It then checks if the model exists and if it doesn't, it throws an error.
         * It also checks if the user has the necessary roles to view the model details.
         * If the user doesn't have the necessary roles, it shows an error message and returns.
         * If the model is not new, it fetches the model details and then calls the _afterFetch function.
         * If the model is new, it directly calls the _afterFetch function.
         *
         * @param {Object} param - The parameter object.
         * @param {Object} param.model - The model whose details are to be displayed.
         * @param {Object} param.service - The service that will be used to fetch the model details.
         * @throws {Error} If more than one argument is passed or if the passed argument is not an object.
         * @throws {Error} If the model does not exist.
         */
        showDetails: function (param) {
            if (arguments.length > 1 || !_.isObject(param)) {
                throw new Error('showDetails function has been called with more than one argument, please use only one argument');
            }
            if (!param.model) {
                $('.js-global-loader').hide();
                alert('Can\'t create a new model without entity, need to define empty model or existing model.');
                throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
            }
            var needRoles = param.service.hasRole();
            if (needRoles) {
                require(['errorController'], _.bind(function (ErrorController) {
                    ErrorController.showMissingRoles(needRoles);
                }, this));
                return;
            }
            if (!param.model.isNew()) {
                param.model.fetch().done(_.bind(function () {
                    $('.js-global-loader').hide();
                    this._afterFetch(param);
                }, this));
            } else {
                $('.js-global-loader').hide();
                this._afterFetch(param);
            }
        },

        /**
         * This function is called after fetching the details of a specific model.
         * It first checks if the correct number of arguments have been passed and if the passed argument is an object.
         * If the model is not new and a callback function for item click is defined, it calls the callback function with the model as an argument.
         * If the model is new and the service has a method to define default values, it calls this method and then shows the details of the model.
         * If the model is new but the service does not have a method to define default values, it directly shows the details of the model.
         *
         * @param {Object} param - The parameter object.
         * @param {Object} param.model - The model whose details have been fetched.
         * @param {Object} param.service - The service that was used to fetch the model details.
         * @param {Function} param.callBackItemClick - The callback function to be called after the CreateEditLayoutView is shown.
         * @param {Function} param.useCreateEditView - The function to be called to show the CreateEditLayoutView.
         * @throws {Error} If more than one argument is passed or if the passed argument is not an object.
         */
        _afterFetch: function (param) {
            if (arguments.length > 1 || !_.isObject(param)) {
                throw new Error('showDetails function has been called with more than one argument, please use only one argument');
            }
            if (!param.model.isNew() && param.callBackItemClick) {
                param.callBackItemClick(param.model);
            } else {
                if (param.useCreateEditView) {
                    param.useCreateEditView(param);
                } else {
                    if (param.model.isNew() && param.service.defineDefaultValue) {
                        param.service.defineDefaultValue(param.model).done(_.bind(function () {
                            this._showDetails(param);
                        }, this));
                    } else {
                        this._showDetails(param);
                    }
                }
            }
        },

        /**
         * This function is used to show the details of a specific model.
         * It first gets the dynamic configuration of the service.
         * Then, for each field in the dynamic configuration, if the field has a reference class, it finds the service path for the entity by the reference class and adds it to the service paths.
         * It then loads each module in the service paths. Once all modules are loaded, it creates a new instance of the CreateEditLayoutView with the model, service, dynamic configuration, and a flag indicating whether the model is new.
         * The new instance of the CreateEditLayoutView is then shown.
         *
         * @param {Object} param - The parameter object.
         * @param {Object} param.model - The model whose details are to be shown.
         * @param {Object} param.service - The service that will be used to get the dynamic configuration and name.
         * @param {Function} param.callBackOnClose - The callback function to be called after the CreateEditLayoutView is shown.
         * @param {Boolean} param.disableCodeName - The flag to indicate whether the code name should be disabled.
         */
        _showDetails: function (param) {
            param.service.getDynamicConfiguration().done(_.bind(function (dynamicConfiguration) {
                var servicePaths = [];
                _.each(dynamicConfiguration.fields, _.bind(function (field) {
                    if (field.refClass) {
                        var servicePath = 'services/' + this.findServiceByJsonName(field.context, field.refClass);
                        servicePaths.push({field: field, path: servicePath});
                    }
                }, this));

                var loadedModulesCount = 0;

                var moduleLoaded = function () {
                    loadedModulesCount++;
                    if (loadedModulesCount === servicePaths.length) {
                        var options = {
                            model: param.model,
                            service: param.service,
                            dynamicConfiguration: dynamicConfiguration,
                            newItem: !param.model.get('secId'),
                            disableCodeName: param.disableCodeName
                        };
                        var createEditView = new CreateEditLayoutView(options);
                        createEditView.show({
                            title: param.title ? param.title : _.i18n(param.service.getName() + '.new'),
                            className: 'popupDynamicEdit baseTableEditAddPopup ' + param.service.getName() + 'EditPopup',
                            service: param.service
                        }, function () {
                            if (param.callBackOnClose) {
                                param.callBackOnClose();
                            }
                        });
                    }
                };

                _.each(servicePaths, _.bind(function (servicePathObj) {
                    require([servicePathObj.path], _.bind(function (serviceModule) {
                        servicePathObj.field.service = serviceModule;
                        moduleLoaded();
                    }, this));
                }, this));
            }, this));
        },

        findServiceByJsonName: function (context, objectJsonName) {
            if (context === 'CACCOUNT') {
                switch (objectJsonName.toLowerCase()) {
                    case 'assaygroupjson':
                        return 'caccounts/assaygroup';
                    case 'assayjson':
                        return 'caccounts/assay';
                    case 'biogroupjson':
                        return 'caccounts/biogroups';
                    case 'colorcompensationjson':
                        return 'caccounts/colorCompensations';
                    case 'colorcompensationmatricejson':
                        return 'caccounts/colorCompensationMatrices';
                    case 'cyclerjson':
                        return 'caccounts/cyclers';
                    case 'cyclerpublicfromcaccountjson':
                        return 'caccounts/cyclerpublics';
                    case 'displaymodeconfigurationjson':
                        return 'caccounts/displayModeConfigurations';
                    case 'displaymodejson':
                        return 'caccounts/displayModes';
                    case 'extractionkitjson':
                        return 'caccounts/kitsextraction';
                    case 'extractionkitlotjson':
                        return 'caccounts/kitlotextraction';
                    case 'extractionmethodjson':
                        return 'caccounts/extractionMethod';
                    case 'extractionmethodreagentjson':
                        return 'caccounts/extractionMethodReagent';
                    case 'extractionreagentjson':
                        return 'caccounts/extractionreagents';
                    case 'extractorjson':
                        return 'caccounts/extractors';
                    case 'fileformatplatelayoutjson':
                        return 'caccounts/fileFormatPlateLayouts';
                    case 'fileformatsamplelistjson':
                        return 'caccounts/fileFormatSampleLists';
                    case 'kitprotconfigurationjson':
                        return 'caccounts/kitprotconfiguration';
                    case 'kitprotjson':
                        return 'caccounts/kitprots';
                    case 'lisanajson':
                        return 'caccounts/lisana';
                    case 'lisbiogroupjson':
                        return 'caccounts/lisBioGroups';
                    case 'lisconfigurationjson':
                        return 'caccounts/lisConfiguration';
                    case 'lisspeciejson':
                        return 'caccounts/lisSpecies';
                    case 'lisjson':
                        return 'caccounts/lis';
                    case 'lmbcyclerjson':
                        return 'caccounts/lmbcyclers';
                    case 'lmbextractorjson':
                        return 'caccounts/lmbextractors';
                    case 'lmbsamplehandlerjson':
                        return 'caccounts/lmbsamplehandlers';
                    case 'mbanaresgrjson':
                        return 'caccounts/mbanaresgrs';
                    case 'mbanaresjson':
                        return 'caccounts/mbanares';
                    case 'mbanajson':
                        return 'caccounts/mbanas';
                    case 'pcrkitjson':
                        return 'caccounts/kitspcr';
                    case 'pcrkitlotjson':
                        return 'caccounts/kitlotpcr';
                    case 'pcrreagentjson':
                        return 'caccounts/pcrreagents';
                    case 'reportjson':
                        return 'caccounts/reports';
                    case 'reportpropertyjson':
                        return 'caccounts/reportProperties';
                    case 'routingaction_routinggroupjson':
                        return 'caccounts/routingaction_routinggroup';
                    case 'routingaction_statejson':
                        return 'caccounts/routingaction_state';
                    case 'routingactionjson':
                        return 'caccounts/routingaction';
                    case 'samplejson':
                    case 'samplelitejson':
                        return 'caccounts/samples';
                    case 'sampleclassjson':
                        return 'caccounts/sampleClass';
                    case 'samplehandlerjson':
                        return 'caccounts/samplehandlers';
                    case 'speciejson':
                        return 'caccounts/species';
                    case 'standardcurveassayconfigurationresultjson':
                        return 'caccounts/standardCurve/standardCurveAssayConfigurationResults';
                    case 'standardcurveassayconfigurationresultvaluejson':
                        return 'caccounts/standardCurve/standardCurveAssayConfigurationResultValues';
                    case 'standardcurvesamplejson':
                        return 'caccounts/standardCurve/standardCurveSamples';
                    case 'standardcurvejson':
                        return 'caccounts/standardCurve/standardCurves';
                    case 'supplierjson':
                        return 'caccounts/suppliers';
                    case 'usercodelistelementjson':
                        return 'caccounts/userCodelistelement';
                    case 'usercodelistjson':
                        return 'caccounts/userCodelist';
                    case 'userpidjson':
                        return 'caccounts/userpids';
                    case 'printerjson':
                        return 'caccounts/printers';
                    case 'authoritygroupjson':
                        return 'caccounts/authorityGroup';
                    case 'usergroupjson':
                        return 'caccounts/userGroup';
                    case 'assayreagenttargetpcrkitlotjson':
                        return 'caccounts/assayReagentTargetPcrKitLots';
                    case 'pcrwelljson':
                        return 'caccounts/pcrWells';
                    case 'classifiedvaluejson':
                        return 'caccounts/mbanas';
                    case 'orderjson':
                        return 'caccounts/orders';
                }
            }
            if (context === 'ADMIN') {
                switch (objectJsonName.toLowerCase()) {
                    case 'arrayelementjson':
                        return 'admin/arrayelement';
                    case 'arrayjson':
                        return 'admin/array';
                    case 'codelistelementjson':
                        return 'admin/codelistelement';
                    case 'codelistjson':
                        return 'admin/codelist';
                    case 'cyclerpubliccodename':
                    case 'cyclerpublicjson':
                        return 'admin/cyclers';
                    case 'detectionformatjson':
                        return 'admin/detectionFormat';
                    case 'dyejson':
                        return 'admin/dye';
                }
            }
            if (context === 'GLOBAL') {
                switch (objectJsonName.toLowerCase()) {
                    case 'caccountjson':
                        return 'global/caccounts';
                    case 'caccounttypejson':
                        return 'global/caccounttypes';
                    case 'dynamicdefinitionfieldjson':
                        return 'global/dynamicDefinitionField';
                    case 'dynamicdefinitionjson':
                        return 'global/dynamicDefinition';
                    case 'logjson':
                        return 'global/logs';
                    case 'textjson':
                        return 'global/text';
                    case 'userpidglobaljson':
                        return 'global/userpids';
                }
            }
            console.error('Entity not found for the context: ' + context + ' and the JsonName: ' + objectJsonName);
        }
    };
});

