/**
 * Created by RKL on 21/08/2015.
 */
define('wellController',[
    'module',
    'underscore',
    'jquery',
    'wellEditSmpIdView',
    'pcrKitLotForValidationView',
    'wellPcrFilter'
], function (
    module,
    _,
    $,
    View,
    PcrKitLotForValidationView,
    WellPcrFilter
) {
    'use strict';

    module.exports = {
        /**
         * Show the details of the model.
         * @param param - The parameter object.
         * @param {Object} param.model - The model whose details are to be shown.
         */
        showDetails: function (param) {
            if (!param.model) {
                alert('The model well is undefined or null');
                return;
            }
            var SampleClasseService = require('services/caccounts/sampleClass');
            SampleClasseService.findBySampleId(param.model.get('smpId').get('code')).done(_.bind(function (sampleClass) {
                var run = param.model.get('run') ? param.model.get('run').get('code') : null;
                var view = new View({
                    model: param.model,
                    sampleClass: sampleClass,
                    test: param.test
                });
                view.show({
                    title: _.i18n('well.edit') + ' (' + run + ')',
                    className: 'wellEditTable'
                });
            }, this));
        },

        showPcrKitLotForValidation: function (param) {
            $('.js-global-loader').show();
            var deferred = $.Deferred();
            if (param.model.get('wellReference')) {
                param.model.get('wellReference').fetch().done(function () {
                    deferred.resolve();
                });
            } else {
                deferred.resolve();
            }
            deferred.done(function () {
                $('.js-global-loader').hide();
                var view = new PcrKitLotForValidationView({
                    model: param.model
                });
                view.show({
                    title: _.i18n('well.pcrKitLotForValidation'),
                    className: 'pcrKitLotForValidation'
                });
            });
        },

        show: function (region, model) {
            var view = new WellPcrFilter({
                model: model
            });
            region.show(view);
        }
    };
});

