define('commonGenericSelectView',[
    'module',
    'backbone',
    'backbone.marionette',
    'underscore',
    'commonGenericSelectItemView',
    'commonGenericSelectItemGroupView'
], function (
    module,
    Backbone,
    Marionette,
    _,
    ItemView,
    ItemGroupView
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        childView: function (model) {
            return model.get('type') === 'group' ? ItemGroupView : ItemView;
        },
        tagName: 'select',
        triggers: {
            'change': 'select:change'
        },

        className: function () {
            return this.options.className || 'form-control inputBackground';
        },
        initialize: function () {
            if (_.isArray(this.collection)) {
                this.collection = new Backbone.Collection(this.collection);
            }
            if (this.options.displaySize) {
                this.options.displaySize = 1;
            }
            this.listenTo(this, 'change:select', _.bind(this.onChangeSelect, this));
        },
        attributes: function () {
            if (this.options.displaySize === 1) {
                return _.extend(this.options.attr, {size: this.collection.length, style: 'flex-grow: 1;'});
            }
            return _.extend(this.options.attr, {size: this.options.displaySize, style: 'flex-grow: 1;height: calc(' + this.options.displaySize + '* 1.5em);'});
        },
        childViewOptions: function (model) {
            var options = this.options.childViewOptions || {};
            options.model = model;
            if (model.get('type') === 'group') {
                options.collection = model.get('items');
            }
            return options;
        },
        onChangeSelect: function (value) {
            this.$el.val(value);
            if (this.options.model && this.options.modelProperty) {
                this.options.model.set(this.options.modelProperty, value);
            }
        },
        previous: function () {
            var index = this.$el.prop('selectedIndex');
            if (index > 0) {
                this.$el.prop('selectedIndex', index - 1);
                this.$el.trigger('change');
            }
        },
        next: function () {
            var index = this.$el.prop('selectedIndex');
            if (index < this.$el.prop('length') - 1) {
                this.$el.prop('selectedIndex', index + 1);
                this.$el.trigger('change');
            }
        },
        onRender: function () {
            if (this.options.model && this.options.modelProperty) {
                this.$el.val(this.options.model.get(this.options.modelProperty));
            }
        }
    });
});
