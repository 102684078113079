define('entities/caccounts/prepruns',[
    'entities/ns',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'moment',
    'fieldUtils',
    'imageUtils',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/prepwells',
    'entities/caccounts/extractionDetails'
], function (
    app,
    Backbone,
    Settings,
    $,
    _,
    moment,
    FieldUtils,
    imageUtils,
    RolesMixin
) {
    'use strict';

    app.PrepRun = Backbone.RelationalModel.extend({
        service: 'services/caccounts/prepruns',
        fetch: function () {
            this.url = Settings.url('rest/v2/runs/prep/' + this.get('secId'));
            var defer = $.Deferred();
            Backbone.Model.prototype.fetch.call(this);
            this.once('sync', _.bind(function () {
                this.get('wells').fetch({run: this}).always(function () {
                    defer.resolve();
                });
            }, this));
            return defer.promise();
        },

        defaults: {
            'name': '',
            'creatDate': null,
            'modDate': null,
            'plateSize': 'H12',
            'comment': '',
            'refModUser': {},
            'refCreatUser': {},
            'refRespUser': {},
            'settings': [],
            'toBePooled': true,
            'period': '',
            'periodFormatted': '',
            'group': '',
            'sequence': 0,
            'sequenceFormat': '',
            'department': '',
            'pattern': '',
            'suffix': '',
            'status': 0,
            'archived': false,
            'archivedDate': '',
            'plateIdPR': '',
            'plateIdDW': '',
            'plateIdEL': '',
            'prState': true,
            'runTracExtraction': [],
            'type': '',
            'wells': [],
            'runAts': [],
            'pcrRuns': [],
            'reagentExtractionKitLots': [],
            'extractionDetails': null
        },

        idAttribute: 'secId',

        getWellService: function () {
            return require(this.get('wells').model.prototype.service);
        },

        relations: [{
            type: Backbone.HasMany,
            key: 'wells',
            relatedModel: 'PrepWell',
            collectionType: 'PrepWellCollection'
        }, {
            type: Backbone.HasMany,
            key: 'pcrRuns',
            relatedModel: 'PcrRun',
            collectionType: 'PcrRunCollection'
        }, {
            type: Backbone.HasOne,
            key: 'extractionDetails',
            relatedModel: 'ExtractionDetails'
        }],
        getType: function () {
            return 'PrepRun';
        },
        getUrl: function () {
            return 'rest/v2/runs/prep/';
        },

        postUrl: function () {
            return Settings.url('rest/v2/runs/prep/');
        },
        getLine: function () {
            return this.get('plateSize').substring(0, 1);
        },
        getColumn: function () {
            return parseInt(this.get('plateSize').substring(1), 10);
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        saveHeader: function () {
            var url = Settings.url('rest/v2/runs/prep/' + this.get('secId') + '/header'),
                defer = $.Deferred();

            var refRespUserSecId = this.get('refRespUser') && this.get('refRespUser').secId ? this.get('refRespUser').secId : null;

            var body = {
                name: this.get('name'),
                status: this.get('status'),
                period: this.get('period'),
                department: this.get('department'),
                group: this.get('group'),
                sequence: this.get('sequence'),
                suffix: this.get('suffix'),
                pattern: this.get('pattern'),
                plateIdDW: this.get('plateIdDW'),
                plateIdEL: this.get('plateIdEL'),
                plateIdPR: this.get('plateIdPR'),
                toBePooled: this.get('toBePooled'),
                comment: this.get('comment'),
                respUserSecId: refRespUserSecId,
                extractable: this.get('extractable')
            };

            $.ajax({
                url: url,
                type: 'PUT',
                data: JSON.stringify(body),
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch()
                        .done(function () {
                            defer.resolve();
                        });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        copyPlateLayoutFrom: function (model) {
            var request = {
                sourceSecId: model.get('sourceRun').get('secId'),
                sourceStart: model.get('wellSelection').get('start'),
                sourceEnd: model.get('wellSelection').get('to'),
                sourcePlateFillDirection: model.get('wellSelection').get('direction'),
                copySampleType: model.get('copySampleType'),
                copySampleId: model.get('copySampleId'),
                copyAssay: model.get('copyAssay'),
                allAssays: model.get('allAssays'),
                targetStart: model.get('wellTarget').get('start'),
                targetPlateFillDirection: model.get('wellTarget').get('direction')
            };

            var url = Settings.url('rest/v2/runs/prep/' + this.get('secId') + '/copyPlateLayoutFrom'),
                defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'PUT',
                contentType: 'application/json',
                data: JSON.stringify(request),
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: _.bind(function (err) {
                    this.fetch().done(function () {
                        defer.reject(err);
                    });
                }, this)
            });
            return defer.promise();
        },

        getFormattedRunAssayStatus: function () {
            var assaysWaitingList = [];
            var assaysRunningList = [];
            var assaysDoneList = [];

            this.get('wells').each(_.bind(function (well) {
                _.each(well.get('assayStatus'), _.bind(function (item) {
                    if (item.status === 'WAITING') {
                        assaysWaitingList.push(item.assay.code);
                    } else if (item.status === 'RUNNING') {
                        assaysRunningList.push(item.assay.code);
                    } else if (item.status === 'DONE') {
                        assaysDoneList.push(item.assay.code);
                    }
                }, this));
            }, this));

            return {
                waiting: this.getAssaysList(assaysWaitingList),
                running: this.getAssaysList(assaysRunningList),
                done: this.getAssaysList(assaysDoneList),
                waitingWithCount: this.getAssaysListWithCount(assaysWaitingList) || '-',
                runningWithCount: this.getAssaysListWithCount(assaysRunningList) || '-',
                doneWithCount: this.getAssaysListWithCount(assaysDoneList) || '-',
                assaysIcons: require('wellUtils').getPrepWellAssaysIcons()
            };
        },

        getAssaysList: function (assayList) {
            var retVal = _.uniq(assayList);
            return retVal.length === 0 ? '-' : retVal.join('; ');
        },

        getAssaysListWithCount: function (assayList) {
            var retVal = '';
            _.each(_.countBy(assayList), _.bind(function (count, key) {
                retVal = retVal + key + ' (' + count + '); ';
            }, this));
            retVal = FieldUtils.substringLastCharWithCharCheck(retVal, ';');
            return retVal;
        },

        updateWithXmlFile: function (fileInfo) {
            var defer = $.Deferred(),
                formdata = new FormData(),
                url = Settings.url('rest/v2/runs/prep/' + this.get('secId'));

            formdata.append('file', fileInfo);

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },

        importFilePlateLayout: function (model) {
            var param;

            param = {
                fileFormatSecId: model.get('fileFormat').get('secId')
            };

            var defer = $.Deferred(),
                formdata = new FormData(),
                url = Settings.url('rest/v2/runs/prep/' + this.get('secId') + '/plateLayout/import', param);

            formdata.append('file', model.get('files')[0]);

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },

        importFileSampleList: function (model) {
            var param = {
                fileFormatSecId: model.get('fileFormat').get('secId'),
                startPos: model.get('wellSelection').get('start'),
                plateFillDirection: model.get('wellSelection').get('direction')
            };

            var defer = $.Deferred(),
                formdata = new FormData(),
                url = Settings.url('rest/v2/runs/prep/' + this.get('secId') + '/sampleList/import', param);

            formdata.append('file', model.get('files')[0]);

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },

        exportFilePlateLayout: function (model) {
            var defer = $.Deferred();
            var url = Settings.url('rest/v2/runs/prep/' + this.get('secId') + '/plateLayout/export', {
                fileFormatSecId: model.get('fileFormat').get('secId'),
                downloadFile: model.get('downloadFile'),
                allAssays: model.get('allAssays')
            });
            if (model.get('downloadFile')) {
                this._downloadFile(url);
                defer.resolve(false);
            } else {
                $.ajax({
                    type: 'GET',
                    url: url,
                    success: function () {
                        defer.resolve(true);
                    },
                    error: function (error) {
                        defer.reject(error);
                    }
                });
            }
            return defer.promise();
        },

        exportFileSampleList: function (model) {
            var defer = $.Deferred();
            var url = Settings.url('rest/v2/runs/prep/' + this.get('secId') + '/sampleList/export', {
                fileFormatSecId: model.get('fileFormat').get('secId'),
                downloadFile: model.get('downloadFile')
            });

            if (model.get('downloadFile')) {
                this._downloadFile(url);
                defer.resolve(false);
            } else {
                $.ajax({
                    type: 'GET',
                    url: url,
                    success: function () {
                        defer.resolve(true);
                    },
                    error: function (error) {
                        defer.reject(error);
                    }
                });
            }
            return defer.promise();
        },

        _downloadFile: function (url) {
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'blob';

            xhr.onload = function () {
                if (xhr.status === 200) {
                    // Extraire le nom de fichier du header Content-Disposition
                    var disposition = xhr.getResponseHeader('Content-Disposition');
                    var matches = /filename="?([^"]+)"?;?/i.exec(disposition);
                    var filename = matches !== null && matches.length > 1 ? matches[1] : 'file';

                    // Créer un lien de téléchargement
                    var blob = new Blob([xhr.response], {type: 'application/octet-stream'});
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = filename;

                    // Déclencher le téléchargement
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            };

            xhr.send();
        },

        extractOrderIdFromSampleId: function (sampleId) {
            var sidOrderId = this.get('settings')['SID-ORDERID'];
            if (sidOrderId && sampleId) {
                var splitSidOrderId = sidOrderId.split(';');
                sampleId = sampleId.substring(Number(splitSidOrderId[0]) > 0 ? Number(splitSidOrderId[0]) - 1 : Number(splitSidOrderId[0]),
                    Number(splitSidOrderId[1]));
            }
            return sampleId;
        },

        applyAssayWells: function (model) {
            var url = Settings.url('rest/v2/runs/prep/' + this.get('secId') + '/wells/assay/add', {
                assaySecId: model.get('assay').get('secId'),
                from: model.get('wellSelection').get('start'),
                to: model.get('wellSelection').get('to'),
                direction: model.get('wellSelection').get('direction')
            }), defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'get',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        applyTestWells: function (model) {
            var url = Settings.url('rest/v2/runs/prep/' + this.get('secId') + '/wells/test/add', {
                from: model.get('wellSelection').get('start'),
                to: model.get('wellSelection').get('to'),
                direction: model.get('wellSelection').get('direction')
            }), defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'get',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        clearAssay: function (model) {
            var url = Settings.url('rest/v2/runs/prep/' + this.get('secId') + '/wells/assay/clear', {
                assaySecId: model.get('assay').get('secId'),
                from: model.get('wellSelection').get('start'),
                to: model.get('wellSelection').get('to'),
                direction: model.get('wellSelection').get('direction')
            }), defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'get',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        replaceAssayWells: function (model) {
            var url = Settings.url('rest/v2/runs/prep/' + this.get('secId') + '/wells/assay/replace', {
                assayReplaceSecId: model.get('assay').get('secId'),
                assayNewSecId: model.get('newAssay').get('secId'),
                from: model.get('wellSelection').get('start'),
                to: model.get('wellSelection').get('to'),
                direction: model.get('wellSelection').get('direction')
            }), defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'get',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        removeTest: function (model) {
            var url = Settings.url('rest/v2/runs/prep/' + this.get('secId') + '/wells/test/remove', {
                from: model.get('wellSelection').get('start'),
                to: model.get('wellSelection').get('to'),
                direction: model.get('wellSelection').get('direction')
            }), defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'get',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        clearWells: function (model) {
            var url = Settings.url('rest/v2/runs/prep/' + this.get('secId') + '/wells/clear', {
                from: model.get('wellSelection').get('start'),
                to: model.get('wellSelection').get('to'),
                direction: model.get('wellSelection').get('direction')
            }), defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'get',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },
        generateSampleId: function (sampleIds) {
            var url = Settings.url('rest/v2/runs/prep/' + this.get('secId') + '/wells/generateSampleId'),
                defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'PUT',
                contentType: 'application/json',
                data: JSON.stringify(sampleIds),
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        clearSampleId: function (model) {
            var params = {
                from: model.get('wellSelection').get('start'),
                to: model.get('wellSelection').get('to'),
                direction: model.get('wellSelection').get('direction')
            };

            var url = Settings.url('rest/v2/runs/prep/' + this.get('secId') + '/wells/clearSampleId', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        sheetUpload: function (target) {
            var defer = $.Deferred();

            var fileInfo = imageUtils.getFileInfo(target, 0);

            if (!fileInfo) {
                throw 'Unable to get file info';
            }

            $.ajax({
                type: 'POST',
                url: Settings.url('rest/v2/runs/' + this.get('secId') + '/sheet'),
                data: fileInfo.formData,
                processData: false,
                contentType: false,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },

        sheetRemove: function () {
            var defer = $.Deferred();

            $.ajax({
                type: 'DELETE',
                url: Settings.url('rest/v2/runs/' + this.get('secId') + '/sheet'),
                processData: false,
                contentType: false,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },

        getPreviousRun: function () {
            var url = Settings.url('rest/v2/runs/prep/' + this.get('secId') + '/previous'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function (resp) {
                    defer.resolve(resp);
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        getNextRun: function () {
            var url = Settings.url('rest/v2/runs/prep/' + this.get('secId') + '/next'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function (resp) {
                    defer.resolve(resp);
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        startScanning: function () {
            var url = Settings.url('rest/v2/runs/prep/' + this.get('secId') + '/scanning/start'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    defer.resolve();
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        stopScanning: function () {
            var url = Settings.url('rest/v2/runs/prep/' + this.get('secId') + '/scanning/stop'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        getPDF: function () {
            return Settings.getDocUrl('PrepRunJson.pdf');
        },

        startExtraction: function (extractionModel) {
            var params = {
                extractionMethodSecId: extractionModel.get('refExtractionMethod').secId,
                comment: extractionModel.get('comment'),
                startUserpidSecId: extractionModel.get('startUserpid') && extractionModel.get('startUserpid').secId ?
                    extractionModel.get('startUserpid').secId : ''
            };

            var url = Settings.url('rest/v2/runs/prep/' + this.get('secId') + '/extraction/start', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },

        undoExtraction: function () {
            var url = Settings.url('rest/v2/runs/prep/' + this.get('secId') + '/extraction/undo'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },

        stopExtraction: function (extractionModel) {
            var params = {
                lmbExtractorSecId: extractionModel.get('refLmbExtractor') && extractionModel.get('refLmbExtractor').get('secId') || null,
                comment: extractionModel.get('comment'),
                endUserpidSecId: extractionModel.get('endUserpid') && extractionModel.get('endUserpid').secId || ''
            };

            var url = Settings.url('rest/v2/runs/prep/' + this.get('secId') + '/extraction/stop', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },

        /**
         * Find pcrKitLot for a prep run
         * @returns {*|jQuery}
         */
        getKitLots: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/runs/prep/' + this.get('secId') + '/kitLots');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function (response) {
                    defer.resolve(response);
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        createBeadPooling: function (request) {
            var url = Settings.url('rest/v2/runs/prep/' + this.get('secId') + '/pooling/bead'),
                defer = $.Deferred();
            var data = {
                posFrom: request.get('posFrom'), posTo: request.get('posTo'),
                plateFillDirection: request.get('plateFillDirection'),
                runSecIds: request.get('runs').chain().filter(function (run) {
                    return run.get('prepRun');
                }).map(function (run) {
                    return run.get('prepRun').get('secId');
                }).value()
            };

            $.ajax({
                method: 'put',
                url: url,
                data: JSON.stringify(data),
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch()
                        .done(function () {
                            defer.resolve();
                        });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });

            return defer.promise();
        },

        createPrimaryPooling: function (request) {
            var url = Settings.url('rest/v2/runs/prep/' + this.get('secId') + '/pooling/primary'),
                defer = $.Deferred();

            $.ajax({
                method: 'put',
                url: url,
                data: JSON.stringify(request),
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch()
                        .done(function () {
                            defer.resolve();
                        });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });

            return defer.promise();
        },

        getGeneratePeriodFormatted: function (period) {
            var params = {
                period: period // $int64
            };
            var url = Settings.url('rest/v2/runs/prep/generatePeriodFormatted', params),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        generateRunName: function () {
            var params = {
                pattern: this.get('pattern'),
                department: this.get('department'),
                group: this.get('group'),
                period: this.get('period') ? Number(moment(this.get('period')).format('x')) : null,
                sequenceFormat: this.get('sequenceFormat'),
                sequence: this.get('sequence'),
                suffix: this.get('suffix')
            };
            var url = Settings.url('rest/v2/runs/prep/generateRunName', params),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        availableRunName: function () {
            var params = {
                runName: this.get('name')
            };

            var url = Settings.url('rest/v2/runs/prep/availableRunName', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (available) {
                    defer.resolve(available);
                },
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        findPriorityAssay: function () {
            var assaysCount = {};
            this.get('wells').each(function (well) {
                _.each(well.get('assayStatus'), function (assayStatus) {
                    if (assayStatus.status === 'WAITING') {
                        if (!assaysCount[assayStatus.assay.code]) {
                            assaysCount[assayStatus.assay.code] = {'assay': assayStatus.assay, 'count': 1};
                        } else {
                            assaysCount[assayStatus.assay.code].count++;
                        }
                    }
                });
            });
            var assay = _.first(_.sortBy(assaysCount, function (assayCount) {
                return assayCount.count;
            }).reverse());
            if (assay) {
                return assay.assay;
            }
            return undefined;
        },

        canAssignSID: function () {
            return this.get('status') < 4;
        },
        canAssignAssay: function () {
            return this.get('wells').findWhere({'refPrepWell': null, 'refAssay': null}) !== undefined;
        },
        canClearAssay: function () {
            return this.get('wells').filter(function (well) {
                return _.find(well.get('assayStatus'), function (assayStatus) {
                    return assayStatus.status === 'WAITING';
                }) !== undefined;
            }).length > 0;
        },
        controlSample: function () {
            var url = Settings.url('rest/v2/runs/prep/' + this.get('secId') + '/samples/control'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (controlSampleResponse) {
                    defer.resolve(controlSampleResponse);
                },
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },
        loadProperties: function () {
            var defer = $.Deferred();
            $.ajax({
                url: Settings.url('rest/v2/properties/findByRun/prep/' + this.get('secId')),
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function (response) {
                    defer.resolve(response);
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        }
    });

    app.PrepRun.role = RolesMixin.RUN_PREP;

    app.PrepRunCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/runs/prep/');
        },
        model: app.PrepRun
    });
});

