define('runPcrValidationMainTargetsView',[
    'module',
    'backbone.marionette',
    'underscore',
    'template!runPcrValidationMainTargetsView',
    'wellsGraphView',
    'jquery'
], function (
    module,
    Marionette,
    _,
    Tpl,
    WellsGraphView,
    $
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,
        regions: {
            graph: '.js-graph'
        },
        ui: {
            tab: 'li.nav-tab',
            firstTab: '.first-tab',
            rawDataAmpl: '.rawData.tab-ampl',
            rawDataMelt: '.rawData.tab-melt',
            colorCompensationBtn: '.js-colorCompensation-btn',
            chartLegendBtn: '.js-chartLegend-btn',
            smoothingBtn: '.js-smoothing-btn',
            rawDataBtn: '.js-rawData-btn'
        },
        events: {
            'click @ui.colorCompensationBtn': 'onCCchange',
            'click @ui.chartLegendBtn': 'onLegendChange',
            'click @ui.smoothingBtn': 'onRangeSmoothChange',
            'click @ui.rawDataBtn': 'onRawDataChange',
            'click @ui.tab': 'onTabClick'
        },
        modelEvents: {
            'change:assayResultVersion': 'render'
        },
        initialize: function (options) {
            this.withCC = true;
            this.withLegend = options.displayLegend || false;
            this.fixedColor = true;
            this.rangeSmooth = 1;
            this.model.set('curves', require('services/caccounts/curves').getCollection());
            switch (this.model.get('assayResultVersion').get('algoResult').type) {
                case 'MELT':
                case 'MUTV':
                    this.graphToDisplay = 'M';
                    break;
                case 'AMP':
                case 'AMP_IC':
                case 'CRI':
                default:
                    this.graphToDisplay = 'A';
                    break;
            }
            if (options.currentGraph) {
                if (options.currentGraph.startsWith('melt') && this.graphToDisplay.indexOf('A') === 0) {
                    options.currentGraph = 'amplCurvesS';
                } else if (options.currentGraph.startsWith('ampl') && this.graphToDisplay.indexOf('M') === 0) {
                    options.currentGraph = 'meltCurves1S';
                }
            } else {
                if (this.graphToDisplay.indexOf('A') === 0) {
                    options.currentGraph = 'amplCurvesS';
                } else if (this.graphToDisplay.indexOf('M') === 0) {
                    options.currentGraph = 'meltCurves1S';
                }
            }
            this.currentGraph = options.currentGraph;
            this.rawData = false;
        },
        serializeData: function () {
            var templateData = {};

            templateData.displayAmpl = this.graphToDisplay.indexOf('A') !== -1;
            templateData.displayMelt = this.graphToDisplay.indexOf('M') !== -1;
            templateData.displayCC = this.model.get('run').get('assayVersions').some(function (assayVersion) {
                return !!assayVersion.get('colorCompensation');
            });
            templateData.legendChecked = this.withLegend;
            templateData.rangeSmooth = this.rangeSmooth;
            templateData.currentGraph = this.currentGraph;
            templateData.rawData = this.rawData;
            templateData.withCC = this.withCC;
            return templateData;
        },
        onTabClick: function (e) {
            if (this.currentGraph === e.currentTarget.attributes['data-graph'].value) {
                return;
            }
            this.currentGraph = e.currentTarget.attributes['data-graph'].value;
            this.displayGraph();
        },
        onRender: function () {
            var loader = $('.js-global-loader');
            loader.show();
            this.model.get('curves').reset(this.model.get('wellSelected').chain().map(_.bind(function (well) {
                return well.getDisplay('VALIDATION').chain().filter(_.bind(function (result) {
                    return result.get('refAssayResult') === this.model.get('assayResultVersion');
                }, this)).map(function (result) {
                    return result.get('curves').models;
                }).value();
            }, this))
                .flatten()
                .value());
            this.model.get('curves').fetch({raw: this.rawData})
                .done(_.bind(function () {
                    this.displayGraph();
                }, this))
                .always(function () {
                    loader.hide();
                });
        },
        displayGraph: function () {
            if (this.isDestroyed()) {
                return;
            }
            if (this.rawData) {
                if (this.graphToDisplay.includes('M')) {
                    this.ui.rawDataMelt.show();
                }
                if (this.graphToDisplay.includes('A')) {
                    this.ui.rawDataAmpl.show();
                }
            } else {
                if (this.graphToDisplay.includes('M')) {
                    this.ui.rawDataMelt.hide();
                }
                if (this.graphToDisplay.includes('A')) {
                    this.ui.rawDataAmpl.hide();
                }
                this.ui.tab.removeClass('active');
                this.ui.firstTab.addClass('active');
            }

            var view = null;
            var models = this.model.get('wellSelected').models;
            switch (this.currentGraph) {
                case 'meltCurvesRaw':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'MELT'
                    });
                    view.setModel(models);
                    view.slopeEnable = false;
                    view.brutEnable = true;
                    view.assayResultVersion = this.model.get('assayResultVersion');
                    view.withoutCC = !this.withCC;
                    view.raw = true;
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    view.filterValuesY = null;
                    view.filterValuesX = null;
                    break;
                case 'meltCurves':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'MELT',
                        smooth: false
                    });
                    view.setModel(models);
                    view.slopeEnable = false;
                    view.brutEnable = true;
                    view.assayResultVersion = this.model.get('assayResultVersion');
                    view.withoutCC = !this.withCC;
                    view.raw = false;
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    view.filterValuesY = null;
                    view.filterValuesX = null;
                    break;
                case 'meltCurvesS':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'MELT',
                        smooth: true
                    });
                    view.setModel(models);
                    view.slopeEnable = false;
                    view.brutEnable = true;
                    view.assayResultVersion = this.model.get('assayResultVersion');
                    view.withoutCC = !this.withCC;
                    view.raw = false;
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    view.filterValuesY = null;
                    view.filterValuesX = null;
                    break;
                case 'meltCurves1':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'MELT',
                        smooth: false
                    });
                    view.setModel(models);
                    view.slopeEnable = true;
                    view.brutEnable = false;
                    view.deriv1Enable = true;
                    view.assayResultVersion = this.model.get('assayResultVersion');
                    view.withoutCC = !this.withCC;
                    view.raw = false;
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    view.filterValuesY = null;
                    view.filterValuesX = null;
                    break;
                case 'meltCurves1S':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'MELT',
                        smooth: true,
                        hideExtraPoint: !this.rawData
                    });
                    view.setModel(models);
                    view.slopeEnable = true;
                    view.brutEnable = false;
                    view.deriv1Enable = true;
                    view.assayResultVersion = this.model.get('assayResultVersion');
                    view.withoutCC = !this.withCC;
                    view.raw = false;
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    view.filterValuesY = null;
                    view.filterValuesX = null;
                    break;
                case 'amplCurvesRaw':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'AMP',
                        enableSlider: true,
                        enableSliderVertical: true
                    });
                    view.setModel(models);
                    view.slopeEnable = false;
                    view.brutEnable = true;
                    view.amplGraph = true;
                    view.withoutCC = !this.withCC;
                    view.raw = true;
                    view.assayResultVersion = this.model.get('assayResultVersion');
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    break;
                case 'amplCurves':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'AMP',
                        enableSlider: true,
                        enableSliderVertical: true,
                        smooth: false
                    });
                    view.setModel(models);
                    view.slopeEnable = false;
                    view.brutEnable = true;
                    view.amplGraph = true;
                    view.withoutCC = !this.withCC;
                    view.raw = false;
                    view.assayResultVersion = this.model.get('assayResultVersion');
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    break;
                case 'amplCurvesS':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'AMP',
                        enableSlider: true,
                        enableSliderVertical: true,
                        smooth: true,
                        hideExtraPoint: !this.rawData
                    });
                    view.setModel(models);
                    view.slopeEnable = false;
                    view.brutEnable = true;
                    view.amplGraph = true;
                    view.withoutCC = !this.withCC;
                    view.raw = false;
                    view.assayResultVersion = this.model.get('assayResultVersion');
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    break;
                case 'amplCurves1':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'AMP',
                        enableSlider: true,
                        enableSliderVertical: true,
                        smooth: false
                    });
                    view.setModel(models);
                    view.slopeEnable = true;
                    view.brutEnable = false;
                    view.amplGraph = true;
                    view.deriv1Enable = true;
                    view.withoutCC = !this.withCC;
                    view.raw = false;
                    view.assayResultVersion = this.model.get('assayResultVersion');
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    break;
                case 'amplCurves1S':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'AMP',
                        enableSlider: true,
                        enableSliderVertical: true,
                        smooth: true
                    });
                    view.setModel(models);
                    view.slopeEnable = true;
                    view.brutEnable = false;
                    view.amplGraph = true;
                    view.deriv1Enable = true;
                    view.withoutCC = !this.withCC;
                    view.raw = false;
                    view.assayResultVersion = this.model.get('assayResultVersion');
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    break;
                case 'amplCurves2':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'AMP',
                        enableSlider: true,
                        enableSliderVertical: true,
                        smooth: false
                    });
                    view.setModel(models);
                    view.slopeEnable = true;
                    view.brutEnable = false;
                    view.deriv2Enable = true;
                    view.amplGraph = true;
                    view.withoutCC = !this.withCC;
                    view.raw = false;
                    view.assayResultVersion = this.model.get('assayResultVersion');
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    break;
                case 'amplCurves2S':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'AMP',
                        enableSlider: true,
                        enableSliderVertical: true,
                        smooth: true
                    });
                    view.setModel(models);
                    view.slopeEnable = true;
                    view.brutEnable = false;
                    view.deriv2Enable = true;
                    view.amplGraph = true;
                    view.withoutCC = !this.withCC;
                    view.raw = false;
                    view.assayResultVersion = this.model.get('assayResultVersion');
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    break;
            }

            this.view = view;

            if (this.view) {
                this.view.filterValuesX = _.bind(function (min, max) {
                    if (min >= this.view.instance.chart.boxes[2].start && max <= this.view.instance.chart.boxes[2].end) {
                        this.onChangeCbx(min, max);
                    }
                }, this);
                this.view.filterValuesY = _.bind(function (min, max) {
                    if (min >= this.view.instance.chart.boxes[3].start && max <= this.view.instance.chart.boxes[3].end) {
                        this.onChangeCby(min, max);
                    }
                }, this);
                this.getRegion('graph').show(this.view);
            }
        },
        getCurrentResult: function (model) {
            return model.get('results').chain().filter(_.bind(function (result) {
                return result.get('refAssayResult') === this.model.get('assayResultVersion');
            }, this)).first().value();
        },

        _resetWellSelected: function () {
            this.model.get('wellSelected').reset(this.model.get('run').get('wells').filter(_.bind(function (well) {
                return well.getDisplay('VALIDATION').some(_.bind(function (result) {
                    return result.get('refAssayResult') === this.model.get('assayResultVersion');
                }, this));
            }, this)));
        },

        onChangeCbx: function (min, max) {
            if (!min && !max) {
                this._resetWellSelected();
                return;
            }
            var models = this.model.get('wellSelected').filter(_.bind(function (model) {
                var target = this.getCurrentResult(model);
                if (!target) {
                    return false;
                }
                return target.get('cts').length > 0 && target.get('cts')[0] > min && target.get('cts')[0] < max;
            }, this));

            this.model.get('wellSelected').reset(models);
        },

        onChangeCby: function (min, max) {
            if (!min && !max) {
                this._resetWellSelected();
                return;
            }
            var models = this.model.get('wellSelected').filter(_.bind(function (model) {
                var target = this.getCurrentResult(model);
                if (!target) {
                    return false;
                }

                var lastCurvValue = target.get('curves').first();
                if (!lastCurvValue) {
                    return false;
                }
                lastCurvValue = lastCurvValue.get('lines');

                var lastValue;
                var index = 0;
                switch (this.currentGraph) {
                    case 'amplCurvesRaw':
                    case 'meltCurvesRaw':
                        _.each(lastCurvValue, function (value, i) {
                            if (value.raw) {
                                index = i;
                            }
                        });
                        lastValue = lastCurvValue[index].raw;
                        break;
                    case 'amplCurves':
                    case 'meltCurves':
                        _.each(lastCurvValue, function (value, i) {
                            if (value.data) {
                                index = i;
                            }
                        });
                        lastValue = lastCurvValue[index].data.raw;
                        break;
                    case 'amplCurvesS':
                    case 'meltCurvesS':
                        _.each(lastCurvValue, function (value, i) {
                            if (value.data) {
                                index = i;
                            }
                        });
                        lastValue = lastCurvValue[index].data.smooth;
                        break;
                    case 'amplCurves1':
                    case 'meltCurves1':
                        lastValue = _.chain(lastCurvValue)
                            .filter(function (value) {
                                return value.dataD1;
                            })
                            .map(function (value) {
                                return value.dataD1.raw;
                            })
                            .max()
                            .value();
                        break;
                    case 'amplCurves1S':
                    case 'meltCurves1S':
                        lastValue = _.chain(lastCurvValue)
                            .filter(function (value) {
                                return value.dataD1;
                            })
                            .map(function (value) {
                                return value.dataD1.smooth;
                            })
                            .max()
                            .value();
                        break;
                    case 'amplCurves2':
                        lastValue = _.chain(lastCurvValue)
                            .filter(function (value) {
                                return value.dataD2;
                            })
                            .map(function (value) {
                                return value.dataD2.raw;
                            })
                            .max()
                            .value();
                        break;
                    case 'amplCurves2S':
                        lastValue = _.chain(lastCurvValue)
                            .filter(function (value) {
                                return value.dataD2;
                            })
                            .map(function (value) {
                                return value.dataD2.smooth;
                            })
                            .max()
                            .value();
                        break;
                }

                lastValue = Number(lastValue);
                if (lastValue === undefined || _.isNaN(lastValue)) {
                    return false;
                }
                return lastValue > min && lastValue < max;
            }, this));

            this.model.get('wellSelected').reset(models);
        },

        onCurveClick: function (wellSecId) {
            var model = this.model.get('wellSelected').findWhere({secId: wellSecId});
            require(['pcrWellController'], _.bind(function (PcrWellController) {
                PcrWellController.showDetails({model: model, cSelection: this.model.get('wellSelected').models});
            }, this));
        },
        onLegendChange: function (event) {
            if (event.target.checked !== undefined) {
                this.withLegend = event.target.checked;
            } else {
                this.withLegend = !this.withLegend;
            }
            if (this.withLegend) {
                this.ui.chartLegendBtn.addClass('selected');
            } else {
                this.ui.chartLegendBtn.removeClass('selected');
            }
            this.displayGraph();
        },

        onRangeSmoothChange: function (event) {
            if (event.target.checked !== undefined) {
                this.rangeSmooth = event.target.checked;
            } else {
                this.rangeSmooth = !this.rangeSmooth;
            }
            if (this.rangeSmooth) {
                this.ui.smoothingBtn.addClass('selected');
            } else {
                this.ui.smoothingBtn.removeClass('selected');
            }
            this.displayGraph();
        },

        onRawDataChange: function (event) {
            if (event.target.checked !== undefined) {
                this.rawData = event.target.checked;
            } else {
                this.rawData = !this.rawData;
            }
            if (this.rawData) {
                this.ui.rawDataBtn.addClass('selected');
            } else {
                this.ui.rawDataBtn.removeClass('selected');
            }
            if (!this.rawData) {
                if (this.graphToDisplay.indexOf('A') === 0) {
                    this.currentGraph = 'amplCurvesS';
                } else if (this.graphToDisplay.indexOf('M') === 0) {
                    this.currentGraph = 'meltCurves1S';
                }
            }
            this.render();
        },

        // cc color compensation filter - <select-option>
        onCCchange: function (event) {
            if (event.target.checked !== undefined) {
                this.withCC = event.target.checked;
            } else {
                this.withCC = !this.withCC;
            }
            if (this.withCC) {
                this.ui.colorCompensationBtn.addClass('selected');
            } else {
                this.ui.colorCompensationBtn.removeClass('selected');
            }
            this.displayGraph();
        }
    });
});

