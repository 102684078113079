define('services/caccounts/routingaction_routinggroup',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/routingaction_routinggroup'
], function (
    app,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.RoutingActionRoutingGroup,
                collection: app.RoutingActionRoutingGroupCollection
            };
        },
        getName: function () {
            return 'routingActionRoutingGroup';
        },
        getDynamicJson: function () {
            return 'RoutingAction_RoutingGroupJson';
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 500, 'fields': ['name']};
        },
        getCreateEditFieldValidate: function () {
            var ignored = [];
            ignored.push({name: 'sequence', type: 'required'});
            return ignored;
        },
        getCreateEditLine: function (model) {
            var RoutingGroupService = require('services/caccounts/routinggroup');
            return [
                {'field': 'sequence', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-6'}}},
                {
                    'field': 'refRoutingGroup',
                    'param': {
                        'type': 'REFERENCE',
                        'config': {
                            'getAutocompleteParam': RoutingGroupService.getAutocompleteParam
                        },
                        'context': RoutingGroupService,
                        'display': {'class': 'col-w-20'}
                    }
                },
                {'field': 'color', 'param': {'type': 'COLOR', 'display': {'class': 'col-w-17'}}},
                {'field': 'position', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-6'}}},
                {'field': 'showPopup', 'param': {'type': 'BOOLEAN', 'display': {'class': 'col-w-8'}}},
                {'field': 'rackId', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-21'}}},
                {
                    'field': 'refRoutingGroup.condition',
                    'dependsOn': {
                        'model': model,
                        'field': 'refRoutingGroup'
                    },
                    'param': {
                        'type': 'TEXTAREA',
                        'display': {
                            'class': 'col-w-50',
                            'readOnly': true
                        }
                    }
                }
            ];
        }
    }, Dynamic);
});

