define('runPcrHeaderPlateLeftView',[
    'module',
    'backbone.marionette',
    'backbone',
    'template!runPcrHeaderPlateLeftView',
    'underscore',
    'settings',
    'customBootboxMessage',
    'importResultsInPcrRunView',
    'bootbox',
    'runRemoteFolderView',
    'exportResultStatView',
    'pcrRunTracsView',
    'menuGroup',
    'entities/menuGroup',
    'rolesMixin',
    'privileges',
    'jquery'
], function (
    module,
    Marionette,
    Backbone,
    template,
    _,
    Settings,
    CustomBootboxMessage,
    ImportResultsInPcrRunView,
    Bootbox,
    RunRemoteFolderView,
    ExportResultStatView,
    PcrRunTracsView,
    MenuGroupView,
    MenuGroup,
    RolesMixin,
    Privileges,
    $
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: template,
        className: 'left',
        regions: {
            'plate-menu': '.js-plate-menu',
            'result-menu': '.js-result-menu',
            'other-menu': '.js-other-menu'
        },
        initialize: function () {
            this.RunPcrController = require('runPcrController');
        },
        serializeData: function () {
            var templateData = {};
            templateData.runReadyToexport = this.model.get('wells').find(function (well) {
                return well.get('valWst') >= 4 && well.get('valWst') < 5;
            });

            if (templateData.runReadyToexport) {
                templateData.runReadyToexport = true;
            }
            return templateData;
        },
        renderMenu: function () {

            this.plateMenu = new MenuGroup({
                nameCode: 'common.plateLayout',
                items: new Backbone.Collection([
                    {
                        nameCode: 'pcr.platelayout.import',
                        click: _.bind(this.onPlateLayoutImport, this)
                    }, {
                        nameCode: 'pcr.platelayout.import.from.other.run',
                        click: _.bind(this.onPlateLayoutImportFromOtherRun, this)
                    }, {
                        nameCode: 'pcr.sampleList.import',
                        click: _.bind(this.onSampleListImport, this)
                    }, {
                        nameCode: 'pcr.platelayout.export',
                        click: _.bind(this.onPlateLayoutExport, this)
                    }, {
                        nameCode: 'pcr.platelayout.cycler.export',
                        click: _.bind(this.onPlateLayoutCyclerExport, this)
                    }, {
                        nameCode: 'pcr.sampleList.export',
                        click: _.bind(this.onSampleListExport, this)
                    }, {
                        nameCode: 'pcr.platelayout.control',
                        click: _.bind(this.onPlateLayoutControl, this)
                    }, {
                        nameCode: 'pcr.platelayout.scan',
                        click: _.bind(this.onPlateLayoutScan, this)
                    }, {
                        nameCode: 'pcr.platelayout.edit',
                        click: _.bind(this.onPlateLayoutEdit, this)
                    }, {
                        nameCode: 'pcr.platelayout.generate.smpId',
                        click: _.bind(this.onGenerateSmpIds, this)
                    }, {
                        nameCode: 'pcr.platelayout.remove.smpId',
                        click: _.bind(this.onRemoveSmpIds, this)
                    }, {
                        nameCode: 'pcr.platelayout.assign.assay',
                        click: _.bind(this.onAddAssay, this)
                    }, {
                        nameCode: 'pcr.platelayout.remove.assay',
                        click: _.bind(this.onClearAssay, this)
                    }, {
                        nameCode: 'pcr.platelayout.replace.assay',
                        click: _.bind(this.onReplaceAssay, this)
                    }, {
                        nameCode: 'pcr.platelayout.add.test',
                        click: _.bind(this.onAddTest, this)
                    }, {
                        nameCode: 'pcr.platelayout.remove.test',
                        click: _.bind(this.onRemoveTest, this)
                    }, {
                        nameCode: 'pcr.platelayout.clear.wells',
                        click: _.bind(this.onClearWells, this)
                    }]),
                quickActions: new Backbone.Collection([
                    {
                        nameCode: 'pcr.platelayout.import',
                        click: _.bind(this.onPlateLayoutImport, this),
                        mdi: 'mdi-import'
                    }, {
                        nameCode: 'pcr.platelayout.edit',
                        click: _.bind(this.onPlateLayoutEdit, this),
                        mdi: 'mdi-table-edit'
                    }, {
                        items: new Backbone.Collection([{
                            nameCode: 'pcr.platelayout.assign.assay',
                            click: _.bind(this.onAddAssay, this),
                            mdi: 'mdi-plus-circle'
                        }, {
                            nameCode: 'pcr.platelayout.replace.assay',
                            click: _.bind(this.onReplaceAssay, this),
                            mdi: 'mdi-rotate-3d-variant'
                        }, {
                            nameCode: 'pcr.platelayout.remove.assay',
                            click: _.bind(this.onClearAssay, this),
                            mdi: 'mdi-minus-circle'
                        }])
                    }])
            });

            this.resultMenu = new MenuGroup({
                nameCode: 'common.result',
                items: new Backbone.Collection([
                    {
                        nameCode: 'pcr.result.import',
                        click: _.bind(this.onCheckBeforeImport, this)
                    }, {
                        nameCode: 'pcr.result.recalc',
                        click: _.bind(this.refreshBtnClick, this),
                        disabled: this.model.get('valStatus').includes('5') && !Settings.get('currentUserModel').hasType(RolesMixin.RUN_PCR, Privileges.EDIT_RESULT_EXPORTED)
                    }, {
                        nameCode: 'pcr.result.reexport',
                        click: _.bind(this.onReExportResult, this)
                    }, {
                        nameCode: 'pcr.result.exportManual',
                        click: _.bind(this.onExportResultManual, this)
                    }, {
                        nameCode: 'pcr.result.print.repeat',
                        click: _.bind(this.onPrintExportList, this),
                        attributes: {
                            'data-print-type': 'REPEAT'
                        }
                    }, {
                        nameCode: 'pcr.result.print.positive',
                        click: _.bind(this.onPrintExportList, this),
                        attributes: {
                            'data-print-type': 'POSITIVE'
                        }
                    }, {
                        nameCode: 'pcr.result.print.repeat.positive',
                        click: _.bind(this.onPrintExportList, this),
                        attributes: {
                            'data-print-type': 'REPEAT_POSITIVE'
                        }
                    }, {
                        nameCode: 'pcr.result.print.dissolve',
                        click: _.bind(this.onPrintExportList, this),
                        attributes: {
                            'data-print-type': 'DISSOLVE'
                        }
                    }, {
                        nameCode: 'pcr.result.print.result',
                        click: _.bind(this.onPrintExportList, this),
                        attributes: {
                            'data-print-type': 'RESULT'
                        }
                    }, {
                        nameCode: 'pcr.val2run',
                        click: _.bind(this.onVal2Run, this)
                    }]),
                quickActions: new Backbone.Collection([
                    {
                        nameCode: 'pcr.result.import',
                        click: _.bind(this.onCheckBeforeImport, this),
                        mdi: 'mdi-import'
                    }, {
                        nameCode: 'pcr.result.recalc',
                        click: _.bind(this.refreshBtnClick, this),
                        mdi: 'mdi-recycle',
                        disabled: this.model.get('valStatus').includes('5') && !Settings.get('currentUserModel').hasType(RolesMixin.RUN_PCR, Privileges.EDIT_RESULT_EXPORTED)
                    }, {
                        nameCode: 'pcr.result.export.quick',
                        click: _.bind(this.onExportResult, this),
                        mdi: 'mdi-export'
                    }])
            });

            this.otherMenu = new MenuGroup({
                nameCode: 'common.miscaelenous',
                items: new Backbone.Collection([
                    {
                        nameCode: 'pcr.setup.view.sheet',
                        click: _.bind(this.onPrintRunSheet, this)
                    }, {
                        nameCode: 'pcr.setup.print.sheet',
                        click: _.bind(this.onPrintPcrSetup, this)
                    }, {
                        nameCode: 'pcr.setupMMX.print.sheet',
                        click: _.bind(this.onPrintPcrSetupMMX, this)
                    }, {
                        nameCode: 'pcr.curve.re.import',
                        click: _.bind(this.onReloadCurves, this)
                    }, {
                        nameCode: 'run.tracs',
                        click: _.bind(this.onShowTracsView, this)
                    }, {
                        nameCode: 'run.report.summary',
                        click: _.bind(this.onReportSummaryClick, this)
                    }]),
                quickActions: new Backbone.Collection([
                    {
                        nameCode: 'run.tracs',
                        click: _.bind(this.onShowTracsView, this),
                        mdi: 'mdi-gnome'
                    }])
            });
        },
        onRender: function () {
            this.renderMenu();
            this.showChildView('plate-menu', new MenuGroupView({
                model: this.plateMenu
            }));
            this.showChildView('result-menu', new MenuGroupView({
                model: this.resultMenu
            }));
            this.showChildView('other-menu', new MenuGroupView({
                model: this.otherMenu
            }));

            this.allowActionButtons();
        },
        onEditWellsSmpId: function () {
            this.RunPcrController.editPlateLayout(this.model);
        },

        onPlateLayoutExport: function () {
            this.RunPcrController.exportPlateLayout(this.model);
        },

        onPlateLayoutCyclerExport: function () {
            this.RunPcrController.exportPlateLayoutForCycler(this.model);
        },

        onSampleListExport: function () {
            this.RunPcrController.exportSampleList(this.model);
        },

        onPlateLayoutImport: function () {
            this.RunPcrController.importPlateLayout(this.model);
        },

        onSampleListImport: function () {
            this.RunPcrController.importSampleList(this.model);
        },

        onPlateLayoutImportFromOtherRun: function () {
            var PcrRunService = require('services/caccounts/pcrruns');
            this.RunPcrController.copyFromRun(PcrRunService, this.model);
        },

        onPlateLayoutScan: function () {
            this.RunPcrController.scanPlateLayout(this.model);
        },

        onPlateLayoutEdit: function () {
            this.RunPcrController.editPlateLayout(this.model);
        },

        onGenerateSmpIds: function () {
            this.RunPcrController.generateSampleId(this.model);
        },

        onRemoveSmpIds: function () {
            this.RunPcrController.removeSampleId(this.model);
        },

        onPlateLayoutControl: function () {
            this.RunPcrController.controlSample(this.model);
        },
        onAddAssay: function () {
            this.RunPcrController.assignAssay(this.model);
        },

        statusChangeIcAlert: function (callback) {
            var confirmParams = {
                message: _.i18n('prepRun.st2+.sid.warning.message'),
                type: 'warning'
            };
            CustomBootboxMessage.customConfirm(confirmParams, callback);
        },

        onClearAssay: function () {
            var callback = _.bind(function (result) {
                if (result) {
                    this.RunPcrController.removeAssay(this.model);
                }
            }, this);
            if (this.model.get('status') > 3) {
                this.statusChangeIcAlert(callback);
            } else {
                callback(true);
            }
        },

        onReplaceAssay: function () {
            var callback = _.bind(function (result) {
                if (result) {
                    this.RunPcrController.replaceAssay(this.model);
                }
            }, this);
            if (this.model.get('status') > 3) {
                this.statusChangeIcAlert(callback);
            } else {
                callback(true);
            }
        },
        onAddTest: function () {
            this.RunPcrController.defineTestWell(this.model);
        },
        onRemoveTest: function () {
            this.RunPcrController.removeTestWell(this.model);
        },
        onClearWells: function () {
            this.RunPcrController.clearWell(this.model);
        },

        disableButton: function (menu, nameCode) {
            var items = menu.get('items').filter(function (item) {
                return item.get('nameCode') === nameCode;
            });
            var quickAccessItems = menu.get('quickActions').filter(function (item) {
                return item.get('nameCode') === nameCode;
            });
            if (!_.isEmpty(quickAccessItems)) {
                items = items.concat(quickAccessItems);
            }
            var quickAccessItemSub = menu.get('quickActions').chain().filter(function (item) {
                return item.get('items');
            }).map(function (item) {
                return item.get('items').models;
            }).flatten().filter(function (item) {
                return item.get('nameCode') === nameCode;
            }).value();
            if (!_.isEmpty(quickAccessItemSub)) {
                items = items.concat(quickAccessItemSub);
            }

            _.each(items, function (item) {
                item.trigger('enable', false);
            });
        },

        allowActionButtons: function () {
            var valStatus = this.model.get('valStatus');
            var status = this.model.get('status');
            var items = [];
            if (!valStatus.includes('1')) {
                items = items.concat(this.resultMenu.findItemsBy('pcr.result.recalc', false));
                items = items.concat(this.resultMenu.findItemsBy('pcr.result.print.repeat'));
                items = items.concat(this.resultMenu.findItemsBy('pcr.result.print.positive'));
                items = items.concat(this.resultMenu.findItemsBy('pcr.result.print.repeat.positive'));
                items = items.concat(this.resultMenu.findItemsBy('pcr.result.print.dissolve'));
                items = items.concat(this.resultMenu.findItemsBy('pcr.result.print.result'));
                items = items.concat(this.resultMenu.findItemsBy('pcr.val2run'));

                if (status > 1) {
                    items = items.concat(this.plateMenu.findItemsBy('pcr.platelayout.scan'));
                }
            }

            if (!valStatus.includes('4') && !valStatus.includes('5')) {
                items = items.concat(this.resultMenu.findItemsBy('pcr.result.export'));
                items = items.concat(this.resultMenu.findItemsBy('pcr.result.reexport'));
                items = items.concat(this.resultMenu.findItemsBy('pcr.result.exportManual'));
            }
            if (!valStatus.includes('4')) {
                items = items.concat(this.resultMenu.findItemsBy('pcr.result.export.quick'));
            }
            if (status >= 4) {
                items = items.concat(this.plateMenu.findItemsBy('pcr.platelayout.import'));
            }
            if (status >= 4) {
                items = items.concat(this.plateMenu.findItemsBy('pcr.platelayout.import.from.other.run'));
                items = items.concat(this.plateMenu.findItemsBy('pcr.platelayout.import'));
                items = items.concat(this.plateMenu.findItemsBy('pcr.platelayout.scan'));
                items = items.concat(this.plateMenu.findItemsBy('pcr.platelayout.edit'));
                items = items.concat(this.plateMenu.findItemsBy('pcr.platelayout.import'));
            }

            if (status > 1) {
                items = items.concat(this.plateMenu.findItemsBy('pcr.platelayout.scan'));
            }

            if (!this.model.canAssignSID()) {
                items = items.concat(this.plateMenu.findItemsBy('pcr.platelayout.edit'));
                items = items.concat(this.plateMenu.findItemsBy('pcr.platelayout.generate.smpId'));
                items = items.concat(this.plateMenu.findItemsBy('pcr.platelayout.remove.smpId'));
            }
            if (!this.model.canClearAssay()) {
                items = items.concat(this.plateMenu.findItemsBy('pcr.platelayout.remove.assay'));
                items = items.concat(this.plateMenu.findItemsBy('pcr.platelayout.replace.assay'));
            }
            _.each(items, function (item) {
                item.trigger('enable', false);
            });
        },

        onCheckBeforeImport: function () {
            var alreadyValidated = this.model.get('alreadyValidated');
            if (alreadyValidated) {
                Bootbox.confirm(_.i18n('warning.import.result'), _.bind(function (result) {
                    if (result) {
                        this.importResult();
                    }
                }, this));
            } else {
                this.importResult();
            }
        },

        importResult: function () {
            this.RunPcrController.importResult(this.model);
        },

        refreshBtnClick: function () {
            var valStatus = this.model.get('valStatus');
            if (valStatus.includes('2') || valStatus.includes('3') || valStatus.includes('4') || valStatus.includes('5')) {
                var txt = '';
                if (valStatus.includes('5')) {
                    txt = '<h2><b>' + _.i18n('warning.recalc.result') + '</b></h2><br/><br/>';
                }
                txt += _.i18n('recalc.result');
                Bootbox.confirm(txt, _.bind(function (result) {
                    if (result) {
                        this.recalcRun();
                    }
                }, this));
            } else {
                this.recalcRun();
            }
        },

        recalcRun: function () {
            $('.js-global-loader').show();
            this.model.refreshData()
                .done(_.bind(function () {
                    this.model.trigger('change', this.model);
                }, this))
                .fail(_.bind(function (error) {
                    console.error(error);
                }, this))
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        onShowTracsView: function () {
            var view = new PcrRunTracsView({
                model: this.model
            });
            view.show({
                title: _.i18n('run.tracs'),
                className: 'runShowTracsPopup'
            });
        },

        onExportResultManual: function () {
            var defer = $.Deferred();
            $('.js-global-loader').show();
            var json = {test: true};
            this.model.exportResult(json).done(_.bind(function (response) {
                $('.js-global-loader').hide();

                var view = new ExportResultStatView({
                    stats: response,
                    callback: this.exportCallback
                });
                view.show({
                    title: _.i18n('run.export.title') + ' : ' + this.model.get('name'),
                    className: 'actionPopupCss exportResultPopup'
                });
                view.listenTo(view, 'export', _.bind(this.exportResultCallback, this));
                defer.resolve();
            }, this)).fail(function () {
                $('.js-global-loader').hide();
            });
            return defer;
        },
        onVal2Run: function () {
            $('.js-global-loader').show();
            this.model.validation2()
                .always(function () {
                    $('.js-global-loader').hide();
                });
        },
        onReExportResult: function () {
            this.onExportResult(null, {reexport: true, automatic: true});
        },

        onExportResult: function (e, param) {
            $('.js-global-loader').show();
            if (!param) {
                param = {automatic: true};
            }
            this.model.exportResult(param).done(_.bind(function (response) {
                $('.js-global-loader').hide();
                var errors = [];
                if (response.success) {
                    _.each(response.exportResponses, function (exportResponse) {
                        if (exportResponse.response.success) {
                            if (!exportResponse.response.externalExport && exportResponse.response.fileName) {
                                var element = document.createElement('a');
                                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(exportResponse.response.resultArray));
                                element.setAttribute('download', exportResponse.response.fileName);
                                element.style.display = 'none';
                                document.body.appendChild(element);
                                element.click();
                                document.body.removeChild(element);
                            }
                        } else {
                            errors.push(exportResponse.configuration.action + ' : ' + exportResponse.response.error);
                        }
                    });
                    this.onRunRefresh();
                } else {
                    errors.push(response.error);
                }

                if (errors.length === 0) {
                    var tracings = _.pluck(response.exportResponses, 'tracing');
                    tracings = _.map(tracings, function (tracing) {
                        return '[' + tracing.action + '] : ' + tracing.comment;
                    });
                    if (tracings.length === 0) {
                        Bootbox.alert({
                            message: _.i18n('pcrRun.export.result.none'),
                            backdrop: true
                        });
                    } else {
                        Bootbox.alert({
                            message: tracings.join('<br/>'),
                            backdrop: true,
                            className: 'success'
                        });
                    }
                } else {
                    Bootbox.alert({
                        message: errors.join('<br/>'),
                        backdrop: true
                    });
                }
            }, this))
                .fail(function () {
                    $('.js-global-loader').hide();
                });
        },

        exportResultCallback: function (params) {
            this.onExportResult(null, params);
        },
        onPrintExportList: function (e) {
            var typeName = e.currentTarget.attributes['data-print-type'].value;

            if (typeName) {
                $('.js-global-loader').show();
                this.model.exportList(typeName)
                    .done(_.bind(function (response) {
                        $('.js-global-loader').hide();
                        if (response.success) {
                            _.each(response.exportResponses, function (exportResponse) {
                                var element = document.createElement('a');
                                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(exportResponse.resultArray));
                                element.setAttribute('download', exportResponse.fileName);
                                element.style.display = 'none';
                                document.body.appendChild(element);
                                element.click();
                                document.body.removeChild(element);
                            });
                        } else {
                            var message = '';
                            _.each(response.errors, function (error) {
                                message += error + '<br>';
                            });
                            Bootbox.alert(message);
                        }
                    }, this))
                    .fail(function () {
                        $('.js-global-loader').hide();
                    });
            }
        },
        onSelectFileToImport: function (model) {
            this.cyclerSelected = model && model.get('refCycler') && model.get('refCycler').secId ?
                model.get('refCycler').secId : null;
            this.respUserSelected = model && model.get('refRespUser') && model.get('refRespUser').secId ?
                model.get('refRespUser').secId : null;

            var PcrRunService = require('services/caccounts/pcrruns');
            PcrRunService.filesToImport().done(_.bind(function (files) {
                this.runRemoteFolderView = new RunRemoteFolderView({
                    files: files,
                    runModel: this.model,
                    type: 'result'
                });
                this.runRemoteFolderView.listenTo(this.runRemoteFolderView, 'run:import-xml', _.bind(this.uploadXml, this));
                this.runRemoteFolderView.show({
                    title: _.i18n('run.import.remote.folder')
                });
            }, this));
        },

        onRunRefresh: function () {
            $('.js-global-loader').show();
            this.model.fetch()
                .fail(_.bind(function (error) {
                    console.error(error);
                }, this))
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },
        onPrintRunSheet: function () {
            var PcrRunService = require('services/caccounts/pcrruns');
            PcrRunService.generateRunSheet(this.model.get('secId'));
        },

        onPrintPcrSetup: function () {
            var PcrRunService = require('services/caccounts/pcrruns');
            PcrRunService.generatePcrSetup(this.model.get('secId'));
        },
        onPrintPcrSetupMMX: function () {
            var PcrRunService = require('services/caccounts/pcrruns');
            PcrRunService.generatePcrSetupMMX(this.model.get('secId'));
        },
        onReloadCurves: function () {
            $('.js-global-loader').show();
            this.model.reloadCurves()
                .done(_.bind(function () {
                    Bootbox.dialog({
                        className: 'success',
                        message: _.i18n('pcrRun.reload.curves.success')
                    });
                    console.info('%cpcrRun reload curves done!"', 'color: #00f8ff');
                }, this))
                .fail(_.bind(function () {
                    Bootbox.alert(_.i18n('pcrRun.reload.curves.error'));
                    console.error('%cpcrRun reload curves failed!"', 'color: #00f8ff');
                }, this))
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        onReportSummaryClick: function () {
            this.model.generateSummary();
        }
    });
});

