define('entities/caccounts/classifiedValues',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/mbanares'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.ClassifiedValue = Backbone.RelationalModel.extend({
        service: 'services/caccounts/mbanas',
        fetch: function () {
            throw new Error('Not implemented');
        },
        defaults: {
            'untilValue': null,
            'mbAnaRes': null
        },
        idAttribute: 'id',
        relations: [{
            type: Backbone.HasOne,
            key: 'mbAnaRes',
            relatedModel: 'MbAnaRes',
            includeInJSON: ['secId', 'code', 'name']
        }],

        save: function () {
            throw new Error('Not implemented');
        }
    });

    app.ClassifiedValue.role = RolesMixin.MBANA;

    app.ClassifiedValueCollection = Backbone.Collection.extend({
        model: app.ClassifiedValue
    });
    
});

