define('commonGenericSelectItemGroupView',[
    'module',
    'backbone',
    'backbone.marionette',
    'underscore',
    'commonGenericSelectItemView'
], function (
    module,
    Backbone,
    Marionette,
    _,
    ItemView
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        tagName: 'optgroup',
        attributes: function () {
            return {label: this.model.get('name')};
        },
        initialize: function () {
            if (_.isArray(this.collection)) {
                this.collection = new Backbone.Collection(this.collection);
            }
        },
        childViewOptions: function (model) {
            var options = this.options.childViewOptions || {};
            options.model = model;
            return options;
        },
        childView: ItemView
    });
});
