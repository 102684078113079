define('entities/caccounts/pcrSetup/pcrWellLite',[
    'entities/ns',
    'backbone',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/assay'
], function (
    app,
    Backbone,
    RolesMixin
) {
    'use strict';

    app.PcrWellLite = Backbone.RelationalModel.extend({
        service: 'services/caccounts/pcrSetup/pcrWellLite',
        defaults: {
            'valid': false,
            'sample': {},
            'sampleType': undefined,
            'assay': undefined,
            'type': undefined,
            'refPrepWell': undefined
        },
        relations: [{
            type: Backbone.HasOne,
            key: 'assay',
            relatedModel: 'Assay'
        }, {
            type: Backbone.HasOne,
            key: 'refPrepWell',
            relatedModel: 'PrepWellLite'
        }],
        idAttribute: 'secId',
        getLine: function () {
            if (this.get('type')) {
                return undefined;
            }
            if (!this.get('pos')) {
                return '';
            }
            return this.get('pos').substring(0, 1);
        },
        getColumn: function () {
            if (this.get('type')) {
                return undefined;
            }
            if (!this.get('pos')) {
                return '';
            }
            return parseInt(this.get('pos').substring(1, 3), 10);
        }
    });

    app.PcrWellLite.role = RolesMixin.PCR_SETUP;

    app.PcrWellLiteCollection = Backbone.Collection.extend({
        model: app.PcrWellLite,
        comparator: function (a, b) {
            var aCol = Number.parseInt(a.get('pos').substring(1));
            var bCol = Number.parseInt(b.get('pos').substring(1));
            var aRow = a.get('pos').charCodeAt(0);
            var bRow = b.get('pos').charCodeAt(0);
            if (aCol === bCol) {
                if (aRow === bRow) {
                    return 0;
                }
                if (aRow < bRow) {
                    return -1;
                }
                return 1;
            }
            if (aCol < bCol) {
                return -1;
            }
            return 1;
        }
    });
});

