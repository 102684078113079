define('entities/caccounts/standardCurve/standardCurveAssayConfigurationResults',[
    'entities/ns',
    'backbone',
    'rolesMixin',
    'jquery',
    'settings',
    'backboneRelational',
    'entities/caccounts/standardCurve/standardCurveAssayConfigurationResultValues',
    'entities/caccounts/assayConfigurationResult',
    'entities/caccounts/standardCurve/standardCurves'
], function (
    app,
    Backbone,
    RolesMixin,
    $,
    Settings
) {
    'use strict';

    app.StandardCurveAssayConfigurationResult = Backbone.RelationalModel.extend({
        service: 'services/caccounts/standardCurve/standardCurveAssayConfigurationResults',
        defaults: {
            'values': [],
            assayConfigurationResult: null,
            concentration: null,
            sampleId: null,
            ct: null,
            commentary: null
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'values',
            relatedModel: 'StandardCurveAssayConfigurationResultValue',
            collectionType: 'StandardCurveAssayConfigurationResultValueCollection',
            reverseRelation: {
                key: 'standardCurveAssayConfigurationResult',
                includeInJSON: false
            }
        }, {
            type: Backbone.HasOne,
            key: 'assayConfigurationResult',
            relatedModel: 'AssayConfigurationResult'
        }, {
            type: Backbone.HasOne,
            key: 'standardCurve',
            relatedModel: 'StandardCurve'
        }],
        getImageUid: function () {
        },
        url: function () {
            return Settings.url('rest/v2/standardCurves/assayConfigurationResults/' + this.get('secId'));
        },
        loadCurve: function () {
            var pcrWells = new app.PcrWellCollection();
            this.get('values').each(function (value) {
                value.get('results').each(function (r) {
                    pcrWells.add(r.get('pcrWell'));
                });
            });

            var deferred = $.Deferred();
            pcrWells.fetch({method: 'POST'}).done(function () {
                var curves = new app.CurveCollection();
                pcrWells.each(function (pcrWell) {
                    curves.add(pcrWell.get('curves').models);
                });
                curves.fetch({raw: true}).done(function () {
                    deferred.resolve();
                });
            });
            return deferred;
        }
    });

    app.StandardCurveAssayConfigurationResult.role = RolesMixin.STANDARDCURVE;

    app.StandardCurveAssayConfigurationResultCollection = Backbone.Collection.extend({
        model: app.StandardCurveAssayConfigurationResult
    });
});

