define('entities/global/dynamicDefinitionField',[
    'entities/ns',
    'backbone',
    'rolesMixin',
    'backboneRelational',
    'entities/global/text'
], function (
    app,
    Backbone,
    RolesMixin
) {
    'use strict';

    app.DynamicDefinitionField = Backbone.RelationalModel.extend({
        service: 'services/global/dynamicDefinitionField',
        defaults: {
            'code': null,
            'name': null,
            'type': null,
            'text': null
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'text',
            relatedModel: 'Text'
        }],
        getImageUid: function () {
        }
    });

    app.DynamicDefinitionField.role = RolesMixin.SUPER_USER;

    app.DynamicDefinitionFieldCollection = Backbone.Collection.extend({
        model: app.DynamicDefinitionField
    });

});

