define('jqgridView',[
    'module',
    'jquery',
    'backbone.marionette',
    'underscore',
    'app',
    'settings',
    'bootbox',
    'customBootboxMessage',
    'itemPagesNameMixin',
    'jqGridFormatter',
    'fileExplorerController',
    'jqgridMbl'
], function (
    module,
    $,
    Marionette,
    _,
    App,
    Settings,
    Bootbox,
    CustomBootboxMessage,
    ItemPagesNameMixin,
    JqGridFormatter,
    FileExplorerController
) {
    'use strict';

    module.exports = Marionette.View.extend(_.extend({
        getTemplate: function () {
            var template = '<table class="jq-grid-list" id="<%= jqGridId %>" role="grid"></table>';
            if (this.options.pager) {
                template += '<div id="<%= jqGridPagerId %>" class="jq-grid-pager"></div>';
            }
            return _.template(template);
        },

        ui: function () {
            return {
                jqGrid: '#' + this.jqGridId.jqGrid,
                jqGridPager: '#' + this.jqGridId.jqGridPager
            };
        },

        attributes: {
            style: 'flex: 1; height: 100%;'
        },

        constructor: function () {
            this.jqGridId = {
                jqGrid: _.uniqueId('jq-grid-list'),
                jqGridPager: _.uniqueId('jq-grid-pager-list')
            };
            Marionette.View.prototype.constructor.apply(this, arguments);
        },

        serializeData: function () {
            return {
                jqGridId: this.jqGridId.jqGrid,
                jqGridPagerId: this.jqGridId.jqGridPager
            };
        },

        initialize: function (options) {
            this.isSuperAdmin = options && options.isSuperAdmin;
            this.exportErrors = [];
            if (this.onInitialize) {
                this.onInitialize();
            }
            this.data = options.data;
            this.listenTo(this, 'reloadGrid', this.reloadGrid);
        },

        getGrid: function () {
            return this.ui.jqGrid;
        },

        paginationObject: function () {
            return {
                first: 0,
                rowCount: 0,
                rows: this.options.data && this.options.data.rows ? this.options.data.rows : Settings.get('perPage'),
                currentPage: 1
            };
        },
        // Méthode pour restaurer les valeurs de filtre dans la barre de filtres
        restoreFilterToolbar: function () {
            var savedFilters = Settings.getFromMemory(this.findFilterName()) || {};
            if (!_.isEmpty(savedFilters)) {
                var filter = {};
                if (savedFilters.filters) {
                    // convert string to object
                    try {
                        filter = JSON.parse(savedFilters.filters);
                        // convert advanced filter to basic filter
                        if (filter.groupOp && filter.rules) {
                            var rules = filter.rules;
                            filter = {};
                            rules.forEach(function (rule) {
                                filter[rule.field] = rule.data;
                            });
                        }
                    } catch (e) {
                        console.error('restoreFilterToolbar: Error parsing filters', e);
                    }
                } else {
                    // remove "sidx", "sord", "page", "_search", "rows" from savedFilters
                    var keys = Object.keys(savedFilters);
                    keys.forEach(function (key) {
                        if (key !== 'sidx' && key !== 'sord' && key !== 'page' && key !== '_search' && key !== 'rows' && key !== 'nd') {
                            filter[key] = savedFilters[key];
                        }
                    });
                }
                var filterToolbarFields = Object.keys(filter);
                filterToolbarFields.forEach(_.bind(function (field) {
                    var value = filter[field];
                    var $input = this.$el.find('.ui-search-toolbar input[name="' + field + '"], .ui-search-toolbar select[name="' + field + '"]');
                    if ($input.length) {
                        $input.val(value).trigger('change'); // Met à jour le champ et déclenche un événement 'change'
                    }
                }, this));
            }
        },

        // Appliquer les filtres sauvegardés
        applySavedFilters: function () {
            var savedFilters = Settings.getFromMemory(this.findFilterName()) || {};
            if (!_.isEmpty(savedFilters)) {
                this.ui.jqGrid.jqGrid('setGridParam', {postData: savedFilters});
                this.restoreFilterToolbar(); // Restaure les champs de la barre de filtres
            }
        },
        onRender: function () {
            this.pagination = this.options.paginationObject ? _.extend({}, this.options.paginationObject()) : _.extend({}, this.paginationObject());
            this.displayGrid();
        },

        findFilterName: function () {
            return typeof this.options.filtersName === 'function' ? this.options.filtersName() : this.options.filtersName;
        },

        gridInitOptions: function (data) {
            this.sidx = this.sidx || '';
            this.sord = this.sord || 'asc';
            var postData = Settings.getFromMemory(this.findFilterName()) || {};
            this.sidx = postData.sidx || this.sidx;
            this.sord = postData.sord || this.sord;
            this.pagination.currentPage = postData.page || 1;

            var rowList = [10, 20, 30, 40, 50];
            if (this.pagination.rows) {
                rowList.push(this.pagination.rows);
                rowList = _.sortBy(rowList, function (num) {
                    return num;
                });
            }

            var opt = data && data.data ? {datatype: 'local', data: data.data} : {datatype: 'json', url: data.url, mtype: data.mtype ? data.mtype : 'POST'};

            return _.extend({
                regional: Settings.get('lang'),
                colModel: [],
                viewrecords: true,
                height: '100%',
                autowidth: true,
                responsive: true,
                multiselect: this.options.selectable,
                rowNum: this.pagination.rows,
                rowList: rowList,
                page: this.pagination.currentPage,
                multiboxonly: true,
                multiselectWidth: 20,
                jsonReader: {
                    page: function (obj) {
                        return obj.length === 0 ? 1 : undefined;
                    },
                    total: _.bind(function () {
                        return Math.ceil(data.rowCount / this.pagination.rows);
                    }, this),
                    records: function () {
                        return data.rowCount;
                    }
                },
                sortname: this.sidx,
                sortorder: this.sord,
                postData: postData || {rows: this.pagination.rows, first: this.pagination.first},
                pager: this.ui.jqGridPager,
                isSuperAdmin: this.isSuperAdmin,
                search: true,
                gridComplete: _.bind(this.gridComplete, this),
                loadComplete: _.bind(this.loadComplete, this),
                beforeSelectRow: function (rowid, e) {
                    return !!$(e.target).is(':checkbox');
                },
                onSortCol: _.bind(this.onSortCol, this),
                onCellSelect: _.bind(this.onCellSelect, this)
            }, opt);
        },

        gridComplete: function () {
            this.attachPopover();
            this.trigger('gridComplete', this);
        },

        loadComplete: function (data) {
            if (data.rows) {
                data = data.rows;
            }
            var currentFilters = this.ui.jqGrid.jqGrid('getGridParam', 'postData');
            this.setFilter(currentFilters);
            this.trigger('loadComplete', this, data);
            this.data = data;
        },

        onCellSelect: function (rowId, iCol, cellContent, e) {
            if ($(e.target).is(':checkbox')) {
                return;
            }
            if (e.target.attributes['data-navigate'] && e.type === 'click') {
                FileExplorerController.show(rowId);
                return;
            }
            var $cellContent = $(cellContent);
            if ($cellContent.hasClass('cell-copyForClipboard')) {
                App.trigger('clipboard', $cellContent.attr('data-copy-value'));
                return;
            }
            var colModel = this.ui.jqGrid.jqGrid('getGridParam', 'colModel');
            this.trigger('click', {gridView: this, rowId: rowId, colModel: colModel[iCol], cellContent: cellContent, event: e});
        },

        onSortCol: function (sidx, columnIndex, sord) {
            var colModel = this.ui.jqGrid.jqGrid('getGridParam', 'colModel');
            var col = colModel[columnIndex];
            if (col.displayGroup && col.displayGroup.name) {
                // get first column
                var firstColumn = colModel[columnIndex + 1];
                // check if show or hide
                var columns, i;
                if (firstColumn.hidden) {
                    // show column from firstColumn to lastColumn
                    columns = [];
                    for (i = columnIndex + 1; i <= columnIndex + col.displayGroup.size; i++) {
                        columns.push(colModel[i].name);
                    }
                    this.ui.jqGrid.jqGrid('showCol', columns);
                } else {
                    // hide column from firstColumn to lastColumn
                    columns = [];
                    for (i = columnIndex + 1; i <= columnIndex + col.displayGroup.size; i++) {
                        columns.push(colModel[i].name);
                    }
                    this.ui.jqGrid.jqGrid('hideCol', columns);
                }
                return 'stop';
            } else {
                // keep doing the sort by prototype                        
                this.sidx = sidx;
                this.sord = sord;
                this.updatePagination();
            }
        },

        findFilter: function () {
            var storedFilters = Settings.getFromMemory(this.findFilterName());
            if (!storedFilters) {
                storedFilters = {};
                Settings.setToMemory(this.findFilterName(), storedFilters);
            }
            return storedFilters;
        },

        displayGrid: function () {
            if (this.data.url) {
                var urlParams = {
                    rowCountUrl: this.data.rowCountUrl,
                    filters: this.findFilter()
                };
                this.rowCountUrl = this.data.rowCountUrl;

                this.$el.find('.jq-grid-table-page').append('<div class="alert-info-grid">' + _.i18n('jqgrid.emptyrecords') + '</div>');

                var CommonService = require('services/common');
                CommonService.getRowCount2(urlParams)
                    .done(_.bind(function (rowCount) {
                        this.gridOptionsObj = this.gridOptions(this.data);
                        this.onRowCountDone(this.data, rowCount);
                        this.$el.find('.alert-info-grid').hide();
                        this.triggerMethod('checkRowCount', rowCount);
                    }, this))
                    .fail(function (response) {
                        Bootbox.alert({title: response.statusText, message: response.responseText});
                    });
            } else {
                setTimeout(_.bind(function () {
                    this.gridOptionsObj = this.gridOptions(this.data);
                    this.onRowCountDone(this.data, 1);
                }, this), 1);
            }
        },

        gridOptions: function (data) {
            var options = this.options.gridOptions(data);
            options = _.defaults(options, this.gridInitOptions(data));
            if ($(this.el).parents('.modal-dialog.ui-draggable').length > 0) {
                options.autowidth = false;
            }
            return options;
        },

        onRowCountDone: function (data, rowCount) {
            data.rowCount = rowCount;
            this.pagination.rowCount = rowCount;
            this.colModel = this.gridOptionsObj.colModel;
            if (this.ui.jqGrid) {
                this.ui.jqGrid.jqGrid(this.gridOptionsObj);
            }
            if (this.options.extend) {
                _.each(this.options.extend, _.bind(function (ext) {
                    _.keys(ext).forEach(_.bind(function (key) {
                        if (this.ui.jqGrid) {
                            this.ui.jqGrid.jqGrid(key, ext[key]());
                        }
                    }, this));
                }, this));
            }

            var activeToolbar = _.any(this.colModel, function (colModel) {
                return colModel.search;
            });
            if (this.ui.jqGrid && activeToolbar) {
                this.ui.jqGrid.jqGrid('filterToolbar', {searchOnEnter: false, stringResult: false, searchOperators: false});
                // Appliquer les filtres sauvegardés après l'affichage de la grille
                this.applySavedFilters();
            }
        },

        setFilter: function (filters) {
            Settings.setToMemory(this.findFilterName(), filters);
        },

        attachPopover: function () {
            this.$el.find('[data-content]').popover({
                trigger: 'hover',
                placement: 'bottom',
                html: 'true',
                container: 'body'
            });
        },

        getCheckedRowsList: function () {
            var selectedRowId = this.ui.jqGrid.jqGrid('getGridParam', 'selarrrow');
            var results = [];
            if (_.isArray(selectedRowId)) {
                _.each(selectedRowId, _.bind(function (rowId) {
                    var currentCheckedRowData = this.ui.jqGrid.getRowData(rowId);
                    results.push(currentCheckedRowData);
                }, this));
            } else {
                var currentCheckedRowData = this.ui.jqGrid.getRowData(selectedRowId);
                results.push(currentCheckedRowData);
            }
            return results;
        },

        reloadGrid: function (newData) {
            if (newData) {
                this.ui.jqGrid.jqGrid('clearGridData');
                this.ui.jqGrid.jqGrid('setGridParam', {data: newData});
            }
            this.ui.jqGrid.trigger('reloadGrid', [{current: true}]);
        }
    }, JqGridFormatter));
});
