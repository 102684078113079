define('entities/global/caccounttypes',[
    'entities/ns',
    'jquery',
    'underscore',
    'backbone',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    $,
    _,
    Backbone,
    RolesMixin
) {
    'use strict';

    app.CAccountType = Backbone.RelationalModel.extend({
        service: 'services/global/caccounttypes',
        defaults: {
            caccount: {},
            type: undefined
        }
    });

    app.CAccountType.role = RolesMixin.SUPER_USER;

    app.CAccountTypeCollection = Backbone.Collection.extend({
        model: app.CAccountType
    });
});

