define('entities/caccounts/assayConfigurationResultOrdered',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/assayConfigurationResult'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.AssayConfigurationResultOrdered = Backbone.RelationalModel.extend({
        service: 'services/caccounts/assayConfigurationResultOrdered',
        defaults: {
            'sequence': 0,
            'type': null,
            'assayConfigurationResult': null
        },
        idAttribute: 'id',

        relations: [{
            type: Backbone.HasOne,
            key: 'assayConfigurationResult',
            relatedModel: 'AssayConfigurationResult'
        }],

        save: function () {
            throw new Error('Can\'t save this entity');
        },

        jsonObjectName: 'AssayConfigurationResultOrderedJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'AssayConfigurationResultOrdered'

    });

    app.AssayConfigurationResultOrdered.role = RolesMixin.ASSAY;

    app.AssayConfigurationResultOrderedCollection = Backbone.Collection.extend({
        model: app.AssayConfigurationResultOrdered,
        comparator: 'sequence'
    });
});

