define('tabsContentItem',[
    'module',
    'backbone.marionette',
    'underscore'
], function (
    module,
    Marionette,
    _
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<div class="js-content"></div>'),
        regions: {
            content: '.js-content'
        },
        className: 'tab-pane fade',
        attributes: function () {
            return {id: this.model.cid};
        },
        onRender: function () {
            if (this.model.get('content').options.wellModel) {
                this.model.get('content').options.wellModel.get('refAssay').fetch().done(_.bind(function () {
                    this.getRegion('content').show(this.model.get('content'));
                }, this));
            } else if (this.model.get('content').options.model) {
                this.model.get('content').options.model.get('refAssay').fetch().done(_.bind(function () {
                    this.getRegion('content').show(this.model.get('content'));
                }, this));
            } else {
                throw new Error('No model or wellModel in content options');
            }
        },
        serializeData: function () {
            return {};
        },
        setActive: function (active) {
            this.$el.toggleClass('active', active);
            this.$el.toggleClass('in', active);
        }
    });
});
