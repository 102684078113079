define('entities/caccounts/colorCompensationMatrices',[
    'entities/ns',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/pcrruns',
    'entities/caccounts/lmbcyclers',
    'entities/caccounts/colorCompensationMatriceValues'
], function (
    app,
    Backbone,
    Settings,
    $,
    _,
    RolesMixin
) {
    'use strict';

    app.ColorCompensationMatrice = Backbone.RelationalModel.extend({
        service: 'services/caccounts/colorCompensationMatrices',
        fetch: function () {
            this.url = Settings.url('rest/v2/colorCompensations/' + this.get('colorCompensation').get('secId') + '/matrices/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'current': false,
            'code': null,
            'pcrRun': null,
            'status': null,
            'statusDate': null,
            'values': [],
            'valuesRemoved': [],
            'lmbCycler': null,
            createdDate: new Date(),
            show: false
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'pcrRun',
            relatedModel: 'PcrRun',
            includeInJSON: ['secId', 'code', 'name']
        }, {
            type: Backbone.HasMany,
            key: 'values',
            relatedModel: 'ColorCompensationMatriceValue',
            collectionType: 'ColorCompensationMatriceValueCollection',
            reverseRelation: {
                key: 'colorCompensationMatrice',
                includeInJSON: false
            }
        }, {
            type: Backbone.HasMany,
            key: 'valuesRemoved',
            relatedModel: 'ColorCompensationMatriceValue',
            collectionType: 'ColorCompensationMatriceValueCollection'
        }, {
            type: Backbone.HasOne,
            key: 'lmbCycler',
            relatedModel: 'LmbCycler'
        }],
        postUrl: function () {
            return Settings.url('rest/v2/colorCompensations/' + this.get('colorCompensation').get('secId') + '/matrices/' + this.get('secId'));
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'ColorCompensationMatriceJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'ColorCompensationMatrice',

        reset: function (x, y) {
            var url = Settings.url('rest/v2/colorCompensations/' + this.get('colorCompensation').get('secId') + '/matrices/' + this.get('secId') + '/reset', {
                    x: x,
                    y: y
                }),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },
        maxValue: function (max) {
            var valueToRemove = this.get('values').filter(function (value) {
                return value.get('x') > max || value.get('y') > max;
            });
            this.get('values').remove(valueToRemove);
            this.get('valuesRemoved').add(valueToRemove);

            for (var x = 0; x <= max; x++) {
                for (var y = 0; y <= max; y++) {
                    var value = this.get('values').findWhere({x: x, y: y});
                    if (!value) {
                        value = this.get('valuesRemoved').findWhere({x: x, y: y});
                        if (!value) {
                            value = new app.ColorCompensationMatriceValue({
                                x: x,
                                y: y,
                                value: x === y ? 100 : 0,
                                manualCorrection: 0,
                                colorCompensationMatrice: this
                            });
                        }
                        this.get('values').add(value);
                    }
                }
            }
        }
    });

    app.ColorCompensationMatrice.role = RolesMixin.COLORCOMPENSATION;

    app.ColorCompensationMatriceCollection = Backbone.Collection.extend({
        model: app.ColorCompensationMatrice,
        comparator: function (item1, item2) {
            var str1 = item1.get('lmbCycler') ? item1.get('lmbCycler').get('code') : '';
            var str2 = item2.get('lmbCycler') ? item2.get('lmbCycler').get('code') : '';

            var ret = str1.localeCompare(str2);
            if (ret === 0) {
                return item2.get('createdDate') - item1.get('createdDate');
            }
            return ret;
        }
    });

});

