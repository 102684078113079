define('services/caccounts/pcrSetup/pcrSetup',[
    'entities/ns',
    'module',
    'jquery',
    'settings',
    'entities/caccounts/pcrSetup/pcrSetup'
], function (
    app,
    module,
    $,
    Settings
) {
    'use strict';

    module.exports = {
        find: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/pcrSetup/find');
            $.ajax({
                url: url,
                type: 'GET',
                success: function (resp) {
                    var model = new app.PcrSetup(resp);
                    model.reset().done(function () {
                        defer.resolve(model);
                    });
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        }
    };
});

