define('pcrWellResultRefValueItemView',[
    'module',
    'backbone.marionette',
    'underscore'
], function (
    module,
    Marionette,
    _
) {
    'use strict';

    module.exports = Marionette.View.extend({
        attributes: function () {
            var title = '';
            if (this.model.get('refAssayResult') && this.model.get('refAssayResult').get('target')) {
                title = this.model.get('refAssayResult').get('target').get('code') + ' - ' + (this.model.get('result') ? this.model.get('result').get('code') : '');
                if (!_.isEmpty(this.model.get('cts'))) {
                    title += ' - ' + this.model.get('cts').join(', ');
                    if (!_.isEmpty(this.model.get('quantifications'))) {
                        title += ' (' + this.model.get('quantifications').join(', ') + ')';
                    }
                }
            }
            return {
                title: title,
                'data-code': this.model.get('refAssayResult') && this.model.get('refAssayResult').get('target') && this.model.get('refAssayResult').get('target').get('code'),
                'data-id': this.model.get('refAssayResult') && this.model.get('refAssayResult').get('target') && this.model.get('refAssayResult').get('target').id
            };
        },
        template: _.template('<div class="scale">' +
            '<div class="zone"></div>' +
            '<div class="indicator mdi mdi-triangle-down"></div>' +
            '</div>'),
        className: 'scale-container',
        ui: {
            scale: '.scale',
            zone: '.zone',
            indicator: '.indicator'
        },
        calculatePosition: function (target) {
            var min = target.get('classifiedValues').at(0).get('untilValue');
            var max = target.get('classifiedValues').at(1).get('untilValue');
            var type = target.get('classifiedValueType') === 'CT' ? 'cts' : 'quantifications';
            var value = parseFloat(_.first(this.model.get(type), 10));
            if (value === undefined || isNaN(value)) {
                return -1;
            }
            var position = this.options.config.zoneMin + ((value - min) / (max - min) * (this.options.config.zoneMax - this.options.config.zoneMin));
            if (position < 0) position = -1;
            if (position > 100) position = 101;
            return position;
        },
        onRender: function () {
            var position = this.calculatePosition(this.model.get('refAssayResult').get('target'));
            var color = this.model.get('result').get('color');

            this.ui.zone.css({
                right: (100 - this.options.config.zoneMax) + '%',
                left: this.options.config.zoneMin + '%'
            });
            // Positionner l'indicateur
            var $indicator = this.ui.indicator;
            // Point dans l'échelle
            $indicator.css({
                color: color
            });
            if (position < 0) {
                $indicator.addClass('min');
            } else if (position > 100) {
                $indicator.addClass('max');
            } else {
                // Point dans l'échelle
                $indicator.css({
                    left: position + '%'
                });
            }
        }
    });
});
