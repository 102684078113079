/**
 * Created by OBL on 14/01/2016.
 */
define('runPcrController',[
    'module',
    'app',
    'underscore',
    'jquery',
    'runController',
    'runCreateEmptyPcrRunView',
    'runPcrCreateFromResultFileView',
    'runPcrPlateLayoutForCyclerExportView',
    'runPcrResultImportView'
], function (
    module,
    App,
    _,
    $,
    RunController,
    RunCreateEmptyPcrRunView,
    RunCreateFromResultFileView,
    RunPlateLayoutForCyclerExportView,
    RunPcrResultImportView
) {
    'use strict';

    module.exports = _.extend({}, RunController, {

        /**
         * Show the details of the model.
         * @param param - The parameter object.
         * @param {Object} param.model - The model whose details are to be shown.
         * @param {Object} param.service - The service object.
         * @param {Function} param.callBackOnClose - The callback function to be called after the CreateEditLayoutView is shown.
         * @throws {Error} If the model does not exist.
         */
        showDetails: function (param) {
            var SettingService = require('services/caccounts/setting');
            SettingService.findByCode('DEF_DEPT').done(function (defDept) {
                var createEditView = new RunCreateEmptyPcrRunView({
                    model: param.model,
                    defDept: defDept,
                    callBackOnClose: function (result) {
                        if (!param.callBackOnClose && result.length === 1) {
                            App.navigate('runs/pcr/' + result[0].secId, {trigger: true});
                        } else if (param.callBackOnClose) {
                            param.callBackOnClose(result);
                        } else {
                            App.navigate('runs/pcr', {trigger: true});
                        }
                    },
                    service: param.service
                });
                createEditView.show({
                    title: _.i18n('run.pcr.new'),
                    className: 'actionPopupCss900 actionPopupCss popupFitContent',
                    service: param.service
                });
            });
        },
        exportPlateLayoutForCycler: function (model) {
            var SettingService = require('services/caccounts/setting');
            SettingService.findByCode('EXPORT-PLATELAYOUT').done(_.bind(function (setting) {
                var view = new RunPlateLayoutForCyclerExportView({
                    model: model,
                    settingImport: setting
                });
                view.show({
                    title: _.i18n('run.platelayout.export')
                });
            }, this));
        },

        createFromResultFile: function () {
            var CyclerPublicService = require('services/caccounts/cyclerpublics');
            var PcrRunService = require('services/caccounts/pcrruns');
            var SettingService = require('services/caccounts/setting');

            var defers = [];
            defers.push(CyclerPublicService.findDefault());
            defers.push(SettingService.findByCode('IMPORT-RESULT'));
            $.when.apply($, defers).done(function (cycler, setting) {
                new RunCreateFromResultFileView({
                    defaultCycler: cycler ? CyclerPublicService.getModel(cycler) : null,
                    settingImport: setting,
                    service: PcrRunService,
                    callback: function () {
                        if (arguments.length === 1) {
                            App.navigate('runs/pcr/' + arguments[0][0].secId, {trigger: true});
                        }
                    }
                }).show({
                    title: _.i18n('run.pcr.new.from.result.file')
                });
            }, this);
        },

        importPlateLayout: function (model) {
            RunController.importPlateLayout(model, 'IMPORT-PLATELAYOUT-PCR');
        },
        importSampleList: function (model) {
            RunController.importSampleList(model, 'IMPORT-SAMPLELIST-PCR');
        },
        createFromPlateLayoutFile: function () {
            RunController.createFromPlateLayoutFile('IMPORT-PLATELAYOUT-PCR', require('services/caccounts/pcrruns'), 'runs/pcr');
        },
        createFromSampleListFile: function () {
            RunController.createFromSampleListFile('IMPORT-SAMPLELIST-PCR', require('services/caccounts/pcrruns'), 'runs/pcr');
        },

        importResult: function (model) {
            var SettingService = require('services/caccounts/setting');
            SettingService.findByCode('IMPORT-RESULT').done(_.bind(function (setting) {
                var view = new RunPcrResultImportView({
                    model: model,
                    settingImport: setting,
                    callback: function () {
                        $('.js-global-loader').show();
                        this.model.fetch().always(function () {
                            $('.js-global-loader').hide();
                        });
                    }
                });
                view.show({
                    title: _.i18n('run.importXml')
                });
            }, this));
        }
    });
});

