define('services/caccounts/samples',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'sampleCustomJqGridView',
    'entities/caccounts/samples'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic,
    SampleCustomJqGridView
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.Sample,
                collection: app.SampleCollection
            };
        },
        getName: function () {
            return 'sample';
        },
        getUrl: function () {
            return 'rest/v2/samples/';
        },
        getDynamicJson: function () {
            return 'SampleJson';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig.call(this);
            config.exportable = false;
            config.archivable = false;
            config.importable = false;
            config.creatable = false;
            config.duplicable = false;
            config.customLeft = new SampleCustomJqGridView();
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('sample.sampleId'),
                name: 'code',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 100
            }, {
                label: _.i18n('sample.lis'),
                name: 'refLIS',
                formatter: viewDynamic.codeNameFormatter,
                search: true,
                index: 'lisCode',
                width: 40
            }, {
                label: _.i18n('sample.sampleClass'),
                name: 'refSampleClass',
                formatter: viewDynamic.codeNameFormatter,
                search: true,
                index: 'sampleClassCode',
                width: 40
            }, {
                label: _.i18n('sample.verified'),
                name: 'verified',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'verified',
                width: 60,
                stype: 'select',
                searchoptions: {
                    value: ':;PROGRESSING:PROGRESSING;VALIDATED:VALIDATED;INVALIDATE:INVALIDATE'
                }
            }, {
                label: _.i18n('sample.aliquot'),
                name: 'parent',
                formatter: viewDynamic.booleanFormatter,
                search: true,
                index: 'aliquot',
                width: 20,
                stype: 'select',
                searchoptions: {
                    sopt: ['eq'],
                    value: ':;true:' + _.i18n('common.checked') + ';false:' + _.i18n('common.unchecked')
                }
            }, {
                label: _.i18n('sample.bioGroup'),
                name: 'bioGroup',
                formatter: viewDynamic.codeNameFormatter,
                search: true,
                index: 'bioGroupCode',
                width: 40
            }, {
                label: _.i18n('sample.specie'),
                name: 'specie',
                formatter: viewDynamic.codeNameFormatter,
                search: true,
                index: 'specieCode',
                width: 40
            }, {
                label: _.i18n('common.creatDate'),
                name: 'creatDate',
                formatter: viewDynamic.toDateTimeFormatString, // this formatter need DATEFMT from settings
                search: true,
                index: 'creatDate',
                sorttype: 'date',
                searchoptions: {
                    autocomplete: 'off',
                    dataInit: function (element) {
                        $(element).datepicker({
                            dateFormat: 'dd/mm/yy',
                            changeYear: true,
                            changeMonth: true,
                            showOn: 'focus',
                            prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
                            nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>'
                        });
                    }
                },
                width: 80
            }, {
                label: _.i18n('common.modDate'),
                name: 'modDate',
                formatter: viewDynamic.toDateTimeFormatString,
                search: true,
                index: 'modDate',
                sorttype: 'date',
                searchoptions: {
                    autocomplete: 'off',
                    dataInit: function (element) {
                        $(element).datepicker({
                            dateFormat: 'dd/mm/yy',
                            changeYear: true,
                            changeMonth: true,
                            showOn: 'focus',
                            prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
                            nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>'
                        });
                    }
                },
                width: 80
            }, {
                label: _.i18n('sample.lisAnaOpened'),
                name: 'lisAnas',
                formatter: viewDynamic.codeNameListFormatter,
                sortable: false,
                search: true,
                index: 'lisTargetCode',
                sort: false
            }, {
                label: _.i18n('sample.gAssays'),
                name: 'assayGroups',
                formatter: viewDynamic.codeNameListFormatter,
                sortable: false,
                search: true,
                index: 'assayGroupCode',
                sort: false
            }, {
                label: _.i18n('sample.comment'),
                name: ['lisComment', 'comment'],
                formatter: viewDynamic.concatFormatter,
                search: true,
                index: 'comment',
                sort: false
            });
            return columns;
        },
        getJqGridSidx: function () {
            return 'creatDate';
        },
        getJqGridSord: function () {
            return 'desc';
        },
        getCollection: function (datas) {
            if (!datas) {
                datas = [];
            }
            return new app.SampleCollection(datas);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name', 'refLIS.code']};
        },
        findBySample: function (sample) {
            var url = Settings.url(this.getUrl() + 'find/' + sample),
                defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'GET',
                success: _.bind(function (response) {
                    defer.resolve(this.getModel(response));
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },
        findBySampleFromPreProcessing: function (sample) {
            var url = Settings.url(this.getUrl() + 'findFromPreProcessing/' + sample),
                defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'GET',
                success: _.bind(function (response) {
                    defer.resolve(this.getModel(response));
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },
        findByStartSample: function (sample) {
            var url = Settings.url(this.getUrl() + 'findStart/' + sample),
                defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'GET',
                success: _.bind(function (response) {
                    defer.resolve(new app.SampleCollection(response));
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },
        recalcSampleClassByList: function (secIds) {
            var url = Settings.url(this.getUrl() + 'recalcSampleClass'),
                defer = $.Deferred();
            $.ajax({
                method: 'PUT',
                url: url,
                contentType: 'application/json',
                data: JSON.stringify(secIds),
                success: _.bind(function () {
                    defer.resolve();
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },
        refreshValidationByList: function (secIds) {
            var url = Settings.url(this.getUrl() + 'refreshValidation'),
                defer = $.Deferred();
            $.ajax({
                method: 'PUT',
                url: url,
                contentType: 'application/json',
                data: JSON.stringify(secIds),
                success: _.bind(function () {
                    defer.resolve();
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },
        valid: function (sample, secId) {
            var url = Settings.url(this.getUrl() + 'valid', {sample: sample, sampleSecId: secId}),
                defer = $.Deferred();
            $.ajax({
                method: 'GET',
                url: url,
                contentType: 'application/json',
                success: _.bind(function (response) {
                    defer.resolve(response);
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },
        showDetails: function (param) {
            if (!param) {
                throw new Error('param is required');
            }
            if (!param.service) {
                param.service = this;
            }
            require(['samplesController'], _.bind(function (SamplesController) {
                param.controller = SamplesController;
                Dynamic.showDetails(param);
            }, this));
        }
    }, Dynamic);
});

