define('services/caccounts/prepruns',[
    'entities/ns',
    'settings',
    'module',
    'dynamic',
    'underscore',
    'wellUtils',
    'prepRunCustomJqGridView',
    'renderer',
    'template!prepRunStatusTpl',
    'app',
    'entities/caccounts/prepruns'
], function (
    app,
    Settings,
    module,
    Dynamic,
    _,
    WellUtils,
    PrepRunCustomJqGridView,
    Renderer,
    PrepRunStatusTpl,
    App
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.PrepRun,
                collection: app.PrepRunCollection
            };
        },
        getUrl: function () {
            return 'rest/v2/runs/prep/';
        },
        getName: function () {
            return 'preprun';
        },
        getDynamicJson: function () {
            return 'PrepRunJson';
        },
        getRoute: function () {
            return '#runs/prep';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig.call(this);
            config.exportable = false;
            config.importable = false;
            config.customLeft = new PrepRunCustomJqGridView({pcrRun: this});
            config.duplicable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());

            var icons = WellUtils.getPrepWellAssaysListViewIcons();
            columns.push({
                label: '',
                name: 'plateSize',
                hidden: true
            }, {
                label: '',
                name: 'wellCount',
                hidden: true
            }, {
                label: '',
                name: 'displayArchived',
                index: 'displayArchived',
                search: true,
                hidden: true
            }, {
                label: _.i18n('runList.period'),
                name: 'periodFormatted',
                formatter: viewDynamic.defaultFormatter,
                index: 'periodFormatted',
                search: true,
                searchoptions: {clearSearch: false},
                fixed: true,
                width: 70
            }, {
                label: _.i18n('runList.group'),
                name: 'group',
                formatter: viewDynamic.defaultFormatter,
                index: 'group',
                search: true,
                searchoptions: {clearSearch: false},
                fixed: true,
                width: 35
            }, {
                label: _.i18n('user'),
                name: 'refRespUser',
                formatter: viewDynamic.userFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'RespUserPid.nickName',
                sortable: false,
                fixed: true,
                width: 40
            }, {
                label: _.i18n('plateSize'),
                name: 'plateSize',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'plateSize',
                fixed: true,
                sortable: false,
                width: 35
            }, {
                label: _.i18n('runList.name'),
                name: 'code',
                classes: 'css-run-listView-accentuation',
                formatter: _.bind(this.runNameFormatter, this),
                search: true,
                searchoptions: {clearSearch: false},
                index: 'code',
                fixed: true,
                width: 160
            }, {
                label: _.i18n('runList.status'),
                name: 'status',
                formatter: _.bind(this.statusFormatter, this),
                index: 'status',
                search: true,
                searchoptions: {clearSearch: false},
                fixed: true,
                width: 60
            }, {
                label: icons.waiting,
                name: 'assayWaiting',
                sortable: false,
                search: true,
                searchoptions: {clearSearch: false},
                formatter: viewDynamic.assayWaitingFormatter,
                index: 'assayWaiting',
                fixed: true,
                width: 150
            }, {
                label: icons.running,
                name: 'assayRunning',
                sortable: false,
                search: true,
                searchoptions: {clearSearch: false},
                formatter: viewDynamic.assayRunningFormatter,
                index: 'assayRunning',
                fixed: true,
                width: 150
            }, {
                label: icons.done,
                name: 'assayDone',
                sortable: false,
                search: true,
                searchoptions: {clearSearch: false},
                formatter: viewDynamic.assayDoneFormatter,
                index: 'assayDone',
                fixed: true,
                width: 150
            }, {
                label: _.i18n('run.assays'),
                name: 'assays',
                sortable: false,
                search: true,
                searchoptions: {clearSearch: false},
                formatter: viewDynamic.codeNameListFormatter,
                index: 'assay',
                fixed: true,
                width: 100
            }, {
                label: _.i18n('runList.dwPoolPlate'),
                name: 'toBePooled',
                formatter: viewDynamic.booleanFormatterNoCheckBox,
                search: true,
                fixed: true,
                width: 35
            }, {
                label: _.i18n('runList.extrmethod'),
                name: 'extractionDetails.refExtractionMethod.code',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'extractionMethodCode',
                fixed: true,
                sortable: false,
                width: 60
            }, {
                label: _.i18n('runList.extractor'),
                name: 'extractionDetails.refLmbExtractor.code',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'lmbExtractorCode',
                fixed: true,
                sortable: false,
                width: 60
            }, {
                label: _.i18n('runList.EPlateId'),
                name: 'plateIdEL',
                formatter: _.bind(this.plateIdFormatter, this),
                index: 'plateIdEL',
                search: true,
                searchoptions: {clearSearch: false},
                fixed: true,
                width: 90
            }, {
                label: _.i18n('common.comment'),
                name: 'comment',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                searchoptions: {clearSearch: false},
                index: 'comment'
            });
            return columns;
        },
        getJqGridSidx: function () {
            return 'id';
        },
        getJqGridSord: function () {
            return 'desc';
        },
        getJqGridSelectRow: function (model) {
            App.navigate('runs/prep/' + model.get('secId'), {trigger: true});
        },
        generateRunSheet: function (secIds) {
            if (!_.isArray(secIds)) {
                secIds = [secIds];
            }
            var url = Settings.url('rest/v2/runs/prep/report/PREP_RUN_SHEET', {secIds: secIds});
            App.downloadFile(url);
        },
        generateRunExtractSetup: function (secIds) {
            if (!_.isArray(secIds)) {
                secIds = [secIds];
            }
            var url = Settings.url('rest/v2/runs/prep/report/PREP_RUN_EXTRACT_SETUP', {secIds: secIds});
            App.downloadFile(url);
        },

        runNameFormatter: function (cellValue, options) {
            return '<span class="cell-default" style="font-weight: bold; " data-row-id="' +
                options.rowId + '">' + _.escape(cellValue) + '</span>';
        },
        statusFormatter: function (cellValue, options) {
            var status = cellValue ? parseInt(cellValue, 10) : ' ';
            var iconColor = [];
            var statusIcon = '';
            var renderer = new Renderer({
                template: PrepRunStatusTpl,
                templateData:
                    {current: status}
            });
            renderer.render();
            var content = renderer.$el.html();

            statusIcon += '<div class="cell-default popover-status" style="display: flex;" data-row-id="' + options.rowId + '" data-content="' + _.escape(content) + '">';
            statusIcon += '<div class="jqgrid-statusNb">' + status + '</div>';

            for (var i = 1; i <= 5; i++) {
                iconColor[i] = '';
                if (i === status || i < status || status === 5) {
                    iconColor[i] = 'statusIcon-upTo';
                }
                statusIcon += '<div class="jqgrid-statusIcon' + i + ' ' + iconColor[i] + '"></div>';
            }
            statusIcon += '</div>';
            return statusIcon;
        },
        plateIdFormatter: function (cellValue, options) {
            var idMaxLength = 5;
            var value = '', sub = 0;

            if (cellValue) {
                if (cellValue.length > idMaxLength) {
                    sub = cellValue.length - idMaxLength;
                    value = '...';
                }
                value += cellValue.substr(sub);
            }
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + value + '</span>';
        },

        getAll: function (params) {
            var url = Settings.url('rest/v2/runs/prep/', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (runs) {
                    var runsList = [];
                    _.each(runs, function (run) {
                        runsList.push(run.secId);
                    });
                    defer.resolve(runsList);
                }
            });

            return defer.promise();
        },

        importXmlFile: function (fileInfo, lmbCycler, kitProt) {
            var defer = $.Deferred(),
                formdata = new FormData(),
                param = {};

            if (lmbCycler) {
                param.lmbCyclerSecId = lmbCycler.get('secId');
            }
            if (kitProt) {
                param.kitProtSecId = kitProt.get('secId');
            }

            var url = Settings.url('rest/v2/runs/prep/', param);

            formdata.append('file', fileInfo);

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: function (resp) {
                    defer.resolve(resp);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        filesToImport: function () {
            var url = Settings.url('rest/import/result/files'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },

        plateLayoutToImport: function () {
            var url = Settings.url('rest/import/plateLayoutPrep/files'),
                defer = $.Deferred();
            $.ajax({
                method: 'get',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },

        deleteFileRemote: function (fileName) {
            var url = Settings.url('rest/import/result/file', {fileName: fileName}),
                defer = $.Deferred();

            $.ajax({
                method: 'delete',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (error) {
                    defer.reject(error);
                }
            });

            return defer.promise();
        },

        getFieldsToDisplay: function () {
            return {
                'fieldsWidth': 650, 'fields': [
                    {property: 'assayWaiting', type: 'array[customAssayObject]'}
                ]
            };
            // 'name' must be showed by _getAutocompleteOptions(valueProperty)
        },

        createRunFromPlateLayout: function (model) {
            var defer = $.Deferred(),
                formdata = new FormData(),
                param = {
                    fileFormatSecId: model.get('fileFormat').get('secId')
                };

            var url = Settings.url('rest/v2/runs/prep/fromPlateLayout', param);

            formdata.append('file', model.get('files')[0]);

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: function (resp) {
                    defer.resolve(resp);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },
        createRunFromSampleList: function (model) {
            var defer = $.Deferred(),
                formdata = new FormData();

            var param = {
                fileFormatSecId: model.get('fileFormat').get('secId'),
                startPos: model.get('wellSelection').get('start'),
                plateFillDirection: model.get('wellSelection').get('direction')
            };

            var url = Settings.url('rest/v2/runs/prep/fromSampleList', param);

            formdata.append('file', model.get('files')[0]);

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: function (resp) {
                    defer.resolve(resp);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        getPrepRuns: function (pcrRun) {
            var defer = $.Deferred();
            var url = Settings.url(this.getUrl() + 'findByPcrRun/' + pcrRun.get('secId'));
            $.ajax({
                url: url,
                type: 'GET',
                success: function (response) {
                    defer.resolve(this.getCollection(response));
                },
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        }
    }, Dynamic);
});

