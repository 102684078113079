define('services/caccounts/routingaction_state',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/routingaction_state'
], function (
    app,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.RoutingActionState,
                collection: app.RoutingActionStateCollection
            };
        },
        getName: function () {
            return 'routingActionState';
        },
        getDynamicJson: function () {
            return 'RoutingAction_StateJson';
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 500, 'fields': ['name']};
        },
        getCreateEditFieldIgnore: function () {
            var ignored = Dynamic.getCreateEditFieldIgnore.call(this);
            ignored.push('canBeIgnored');
            return ignored;
        },
        getCreateEditLine: function () {
            return [
                {
                    'field': 'state',
                    'param': {
                        'type': 'LABEL',
                        'display': {'class': 'col-w-40'}
                    }
                },
                {'field': 'rackId', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-21'}}},
                {
                    'field': 'ignored', 'param': {
                        'type': 'BOOLEAN', 'display': {
                            'class': 'col-w-8',
                            'condition': {
                                type: 'EQUAL',
                                key: 'canBeIgnored',
                                value: true
                            }
                        }
                    }
                },
                {'field': 'showPopup', 'param': {'type': 'BOOLEAN', 'display': {'class': 'col-w-8'}}}
            ];
        },
        getConfigCreateEditLine: function () {
            var config = Dynamic.getConfigCreateEditLine();
            config.editButton = false;
            return config;
        }
    }, Dynamic);
});

