define('editWellTargetCollectionView',[
    'module',
    'backbone',
    'backbone.marionette',
    'underscore',
    'jquery',
    'editWellTargetView',
    'template!editWellTargetCollectionView'
], function (
    module,
    Backbone,
    Marionette,
    _,
    $,
    EditWellTargetView,
    Tpl
) {
    'use strict';


    module.exports = Marionette.CollectionView.extend({
        template: Tpl,

        childView: EditWellTargetView,

        collectionFieldsToValidate: [
            {name: 'cts', type: 'required'}
        ],

        className: 'col-xs-12 p-l-0 p-r-0 d-f column',

        attributes: {
            style: 'row-gap: 5px;'
        },

        validateFields: function () {
            return this.validate();
        },

        validate: function () {
            var noError = true, that = this;
            _.each(this.collectionFieldsToValidate, function (field) {
                var fieldElement = that.$el.find('*[data-field-name="' + field.name + '"]');
                _.each(fieldElement, function (fe) {
                    if (($(fe).val() === '' || $(fe).val() === null) &&
                        $(fe).parent().parent().parent().find('.js-mbAnaRes .autocomplete[data-field-name="result"]').val() === 'P') {
                        $(fe).attr('placeholder', _.i18n('warning.mandatoryFields'));
                        $(fe).addClass('invalid-field-value');
                        noError = false;
                    }
                });
            });
            return noError;
        }
    });
});
