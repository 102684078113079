define('entities/admin/dye',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.Dye = Backbone.RelationalModel.extend({
        service: 'services/admin/dye',
        fetch: function () {
            this.url = Settings.url('rest/public/dyes/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            channel: undefined,
            name: undefined,
            shortName: undefined,
            excitation: undefined,
            emission: undefined,
            comment: undefined,
            color: undefined
        },
        idAttribute: 'secId',

        postUrl: function () {
            return Settings.url('rest/public/dyes');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'DyeJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'Dye',
        getCurveName: function () {
            return this.get('channel') && this.get('name') ? this.get('channel') + '_' + this.get('name') : (!this.get('channel') ? this.get('name') : (!this.get('name') ? this.get('channel') : ''));
        }
    });

    app.Dye.role = RolesMixin.SUPER_USER;

    app.DyeCollection = Backbone.Collection.extend({
        model: app.Dye,
        comparator: 'channel'
    });
});

