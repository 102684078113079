define('services/caccounts/standardCurve/standardCurveSamples',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/standardCurve/standardCurveSamples'
], function (
    app,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.StandardCurveSample,
                collection: app.StandardCurveSampleCollection
            };
        },
        getName: function () {
            return 'standardcurvesample';
        },
        getDynamicJson: function () {
            return 'StandardCurveSampleJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('standardCurveSample.sample'),
                name: 'sample',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'sample',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('standardCurveSample.dilution'),
                name: 'dilution',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'dilution'
            });
            return columns;
        },

        getCreateEditLine: function () {
            return [
                {'field': 'sample', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-45'}}},
                {
                    'field': 'dilution', 'param': {
                        'type': 'NUMBER',
                        'display': {'class': 'col-w-20'},
                        'readOnly': true
                    }
                },
                {'field': 'mandatory', 'param': {'type': 'BOOLEAN', 'display': {'class': 'col-w-3 p-r-5'}}}
            ];
        }
    }, Dynamic);
});

