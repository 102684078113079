/**
 * Created by OLD on 30/10/2015.
 */
define('wellRouter',[
    'underscore',
    'jquery',
    'require',
    'module',
    'marionetteAppRouter',
    'app',
    'wellUtils',
    'cssUtils',
    'itemPagesNameMixin',
    'sidebarShowController'
], function (
    _,
    $,
    require,
    module,
    AppRouter,
    App,
    WellUtils,
    CssUtils,
    ItemPagesNameMixin,
    SidebarShowController
) {
    'use strict';

    var Controller = {
        checkContent: function () {
            var defer = $.Deferred();
            require([
                'contentShowController'
            ], function (ContentShowController) {
                ContentShowController.showContent().done(function () {
                    defer.resolve();
                });
            });
            return defer.promise();
        },

        showFilter: function () {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyDefaultTheme();
            this.checkContent().done(function () {
                require([
                    'wellController'
                ], function (Controller) {
                    Controller.show(
                        App.getView().getRegion('content'));
                    SidebarShowController.setActiveNavItem(ItemPagesNameMixin.PCR_WELL);
                });
            });
        }
    };
    
    var PcrWellRouter = AppRouter.extend({
        appRoutes: {
            'well/pcr': 'showFilter'
        },
        controller: Controller
    });
    
    module.exports = new PcrWellRouter();
});
