define('entities/global/routes',[
    'entities/ns',
    'jquery',
    'underscore',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    $,
    _,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.SystemRoute = Backbone.RelationalModel.extend({
        service: 'services/global/routes',
        fetch: function () {
            this.url = Settings.url('rest/global/routes/');

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {},

        url: function () {
            return Settings.url('rest/global/routes/');
        },

        idAttribute: 'filename'
    });

    app.SystemRoute.role = RolesMixin.SUPER_USER;
});

